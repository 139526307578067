export default class TextUtils {
    static equals(arg0: string, arg1: string): boolean {
        if (arg0==null)
            return false;
        return arg0.localeCompare(arg1)===0;
    }
    
    static isEmpty(text: string) {
        return (!text || text==='');
    }
}