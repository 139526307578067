import StringUtils from "../../Utils/StringUtils";
import XmlNode from "./XmlNode";

export default class XmlNodeList extends Array<XmlNode> {

    //private m_nodes: Array<XmlNode>
    /**
     *
     */
    constructor(...nodes) {
        super(...nodes);
        // if (nodes!=null)
        //     super(nodes);
        // else
        //     super();
        Object.setPrototypeOf(this, new.target.prototype);
        // if (nodes==null || nodes.length==0)
        //     this.m_nodes=new Array<XmlNode>();
        // else
        //     this.m_nodes=Array.from(nodes);
    }

    public count(): number {
        return this.length;
    }

    public get(Index: number): XmlNode {
        return this[Index];
    }
    public addNode(node: XmlNode): void {
        this.push(node);
    }

    public exist(nodeName: string, attrName: string, attrValue: string): boolean {
        let bFound: boolean=false;
        this.forEach(node=> {
            if (StringUtils.areEquals(node.getName(),nodeName) && node.attrExists(attrName) && StringUtils.areEquals(node.getAttrValue(attrName),attrValue))
                bFound=true;
        });
        return bFound;
    }
    public clear(): void {
        while (this.length>0)
            this.pop();
    }
}