import Hashtable from "../../Collections/HashMap/Hashtable";
import QueryField from "./QueryField";
import { eJoinType, QueryTable } from "./QueryTable";
import SqlType from "../../Utils/SqlType";
import StringBuilder from "../../Utils/StringBuilder";
import StringUtils from "../../Utils/StringUtils";
import TextUtils from "../../Utils/TextUtils";
import IMessageHolder from "../../Interfaces/IMessageHolder";
import { Exception } from "../../Exceptions/Exception";
import IllegalArgumentException from "../../Exceptions/IllegalArgumentException";
import { XoneMessageKeys } from "../../Exceptions/XoneMessageKeys";
import { brotliDecompress } from "zlib";
import Vector from "../../Collections/Vector";

class ParserStatus {
    /* Se ha obtenido el keyword inicial que permite identificar la sentencia */
    static PARSER_KEYWORD = 0;

    /* Se ha terminado el parsing */
    static PARSER_COMPLETE = 1;

    /* Estados para parsear una sentencia DELETE */
    static PARSER_DELETE_EXPECTING_FROM = 2;
    static PARSER_DELETE_EXPECTING_TABLE = 3;
    static PARSER_DELETE_EXPECTING_WHERE = 4;
    static PARSER_DELETE_WHERE = 5;

    /* Estados para parsear una sentencia INSERT */
    static PARSER_INSERT_EXPECTING_INTO = 6;
    static PARSER_INSERT_EXPECTING_TABLE = 7;
    static PARSER_INSERT_EXPECTING_FIELDS_BEGIN = 8;
    static PARSER_INSERT_EXPECTING_FIELD_NAME = 9;
    static PARSER_INSERT_EXPECTING_FIELD_SEPARATOR = 10;
    static PARSER_INSERT_EXPECTING_VALUES = 11;
    static PARSER_INSERT_EXPECTING_VALUES_BEGIN = 12;
    static PARSER_INSERT_EXPECTING_VALUE = 13;
    static PARSER_INSERT_EXPECTING_VALUE_SEPARATOR = 14;

    /* Estados para parsear una sentencia UPDATE */
    static PARSER_UPDATE_EXPECTING_TABLE = 15;
    static PARSER_UPDATE_EXPECTING_SET = 16;
    static PARSER_UPDATE_EXPECTING_FIELD_NAME = 17;
    static PARSER_UPDATE_EXPECTING_EQUAL_SIGN = 18;
    static PARSER_UPDATE_EXPECTING_FIELD_VALUE = 19;
    static PARSER_UPDATE_EXPECTING_SEPARATOR = 20;
    static PARSER_UPDATE_EXPECTING_WHERE = 21;
    static PARSER_UPDATE_WHERE = 22;
}

export class SqlParser {
    private static NULL_VALUE = "NULL";

    /**
     * Nombre del campo ROWID utilizado en esta sentencia
     */
    private m_strRowIdFieldName: string;

    /**
     * Tipo del SQL que tiene asignado este parser
     */
    private m_sqlType: number;
    /**
     * Sentencia WHERE que tiene este SQL
     */
    private m_strWhere: string;
    /**
     * Lista de campos de esta sentencia
     */
    private m_lstFields: Hashtable<string, string>;
    /**
     * Lista de los tipos campos de esta sentencia Si por defecto es string no
     * vamos a gastar espacio
     */
    private m_lstFieldsType: Hashtable<string, number>;
    /**
     * Nombre de la tabla
     */
    private m_strTable: string;
    /**
     * Aqu� se almacena el ROWID una vez parseado el SQL
     */
    private m_strRowId: string;
    /**
     * Posici�n por la que va el parser para obtener el siguiente token
     */
    private m_nParserIndex: number;
    /**
     * Token obtenido tras la ultima llamada a GetNextToken
     */
    private m_strCurrentToken: StringBuilder;
    /**
     * Cadena que se est� parseando
     */
    private m_strSentence: string;
    /**
     * Estado en que est� el parser actualmente
     */
    private m_status: number;
    /**
     * TRUE si el query es una uni�n (solo para SELECT)
     */
    private m_bIsUnionQuery: boolean;
    /**
     * Esta lista contiene los campos de la sentencia SELECT en el mismo orden
     * en que se pusieron
     */
    private m_lstSelectFields: Vector<QueryField>;
    /**
     * Contiene la parte de la sentencia que corresponde al GROUP BY
     */
    private m_strGroupBy: string;
    /**
     * Contiene la parte de la sentencia que corresponde al ORDER BY
     */
    private m_strOrderBy: string;
    /**
     * Contiene la parte de la sentencia que corresponde al HAVING
     */
    private m_strHaving: string;
    /**
     * TRUE si hay que usar prefijo para reconstruir la sentencia SQL SELECT
     */
    private m_bUsePrefix: boolean;
    /**
     * Lista de queries que componen un SELECT UINON ALL
     */
    private m_lstUnionQueries: Array<SqlParser>;
    /**
     * TRUE si el SELECT lleva DISTINCT en su declaraci�n
     */
    private m_bDistinct: boolean;
    /**
     * Esta lista contiene las tablas de la sentencia SELECT en el mismo orden
     * en que se pusieron
     */
    private m_tableFrom: QueryTable;
    /**
     * Palabras reservadas de SQL
     */
    private m_strReservedWords = [
        "insert", "update", "delete", "into", "values", "set", "where",
        "from", "having", "group", "by", "order"];

    public static EMPTY = "";
    //
    // M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
    // Por defecto en el Blackberry las consultas ser�n traducidas... personalizar para futuras bases de datos
    private m_bTranslateJoins = false;
    /**
     * M11051201: Mecanismo para soporte multilenguaje en los componentes y
     * dem�s cosas. Aqu� almacenamos la referencia al gestor de mensajes para
     * facilitar la localizaci�n.
     */
    private m_messages: IMessageHolder;
    /**
     * F12110902: Modificaciones al parser de SQL para gestionar uniones. TRUE
     * si queremos que las sentencias SQL que forman un UNION se encierren entre
     * par�ntesis. Para sqlite por defecto ser� FALSE.
     */
    private m_bSurroundUnions = false;

    /**
     * Creates a new instance of SqlParser
     */
    constructor(Source: string | SqlParser, Messages?: IMessageHolder) {
        if (Source instanceof SqlParser)
            this.SqlParserFromParser(Source as SqlParser);
        else {
            this.m_strRowIdFieldName = Source;
            this.m_sqlType = SqlType.SQLTYPE_UNKNOWN;
            this.m_lstFields = new Hashtable<string, string>();
            // A12042602: Permitir que se puedan almacenar los tipos de datos de una sentencia SQL.
            this.m_lstFieldsType = new Hashtable<string, number>();
            // Esta lista es para cuando se parsea un SELECT
            this.m_lstSelectFields = new Vector<QueryField>();
            ////m_lstSelectFields = new Vector();
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
            this.m_messages = Messages ? Messages : null;
        }
    }

    /**
     * Construye un parser a partir de otro
     *
     * @param Source Sentencia parseada original a partir de la que se compone
     *               esta.
     */
    public SqlParserFromParser(Source: SqlParser) {
        // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
        this.m_messages = Source.getMessageHolder();

        this.m_strRowIdFieldName = Source.GetRowIdFieldName();
        this.m_sqlType = Source.GetSqlType();
        this.m_lstFields = new Hashtable<string, string>();
        // A12042602: Permitir que se puedan almacenar los tipos de datos de una sentencia SQL.
        this.m_lstFieldsType = new Hashtable<string, number>();
        this.m_strTable = Source.GetTableName();
        this.m_strWhere = Source.GetWhereSentence();
        // Copiar los campos
        Source.GetFields().entrySet().forEach(entry => this.m_lstFields.put(entry[0], entry[1]));

        // Enumeration<String> enm = setHash.keys();
        // while (enm.hasMoreElements()) {// Recorrer la lista
        //     String strKey = enm.nextElement();
        //     String strVal = setHash.get(strKey);
        //     this.m_lstFields.put(strKey, strVal);
        // }// Leer datos y copiar
        this.m_strSentence = Source.GetSentence();
        this.m_lstSelectFields = new Vector<QueryField>();
    }

    /**
     * @return Devuelve el nombre del campo ROWID de este parser.
     */
    public GetRowIdFieldName() {
        return this.m_strRowIdFieldName;
    }

    /**
     * Asigna el nombre del campo ROWID de este parser
     *
     * @param value Nuevo valor para el nombre del campo ROWID.
     */
    public SetRowIdFieldName(value: string) {
        this.m_strRowIdFieldName = value;
    }

    /**
     * @return Devuelve la sentencia WHERE de este parser.
     */
    public GetWhereSentence() {
        return this.m_strWhere?.replace("*", ""); // amiyares 18/06/2021: el parser falla y agrega en la condición un * que explota la llamada a la API, comentar con Luis a ver si podemos quitar parseos locales
    }

    /**
     * M11051201: Mecanismo para soporte multilenguaje en los componentes y
     * dem�s cosas. Devuelve el holder de mensajes de este parser...
     */
    public getMessageHolder() {
        return this.m_messages;
    }

    /**
     * Asigna la sentencia WHERE de este parser
     *
     * @param value Nuevo valor para la sentencia WHERE del parser.
     */
    public SetWhereSentence(value: Object) {
        this.m_strWhere = value.toString();
    }

    /**
     * @return Devuelve el ROWID de este parser.
     */
    public GetRowId(): string {
        let strRID: string = null;
        let n: number, k: number;
        //
        // Si ya lo tiene, lo devuelve...
        if (!TextUtils.isEmpty(this.m_strRowId)) {
            return this.m_strRowId;
        }
        //
        // Si no, habr� que buscarlo...
        if (this.m_lstFields.containsKey(this.m_strRowIdFieldName)) {
            strRID = this.m_lstFields.get(this.m_strRowIdFieldName);
        }

        if (TextUtils.isEmpty(strRID)) {// Buscarlo en el WHERE
            strRID = this.GetWhereSentence();

            // ADD TAG Juan Carlos. Chequeos extra.
            // Tiene WHERE
            if (!TextUtils.isEmpty(strRID)) {
                n = strRID.indexOf("'");
                k = strRID.indexOf("'", n + 1);
                strRID = strRID.substring(n + 1, k); /* substring OK */
            }// Tiene WHERE
        }// Buscarlo en el WHERE
        else {
            strRID = StringUtils.removeChars(strRID, "'");
        }
        //
        // Sea lo que sea, lo tiene...
        if (!TextUtils.isEmpty(strRID)) {
            this.m_strRowId = strRID;
        }
        //
        // Devolverlo
        return this.m_strRowId;
    }

    /**
     * Expone la lista de campos de la sentencia parseada
     *
     * @return Devuelve la lista de campos de una sentencia INSERT o UPDATE.
     */
    public GetFields(): Hashtable<string, string> {
        return this.m_lstFields;
    }

    /**
     * @return Devuelve el tipo de operaci�n que tiene este parser.
     */
    public GetSqlType(): number {
        return this.m_sqlType;
    }

    /**
     * Asigna el tipo de operaci�n que tiene este parser
     *
     * @param value Nuevo valor para el tipo de sentencia SQL.
     */
    public SetSqlType(value: number): void {
        this.m_sqlType = value;
    }

    /**
     * Expone el nombre de la tabla de esta sentencia
     *
     * @return Devuelve el nombre de la tabla de una sentencia de modificaci�n
     * (INSERT, UPDATE o DELETE)
     */
    public GetTableName(): string {
        return this.m_strTable;
    }

    /**
     * Asigna el nombre de la tabla de esta sentencia de modificaci�n.
     *
     * @param value Nuevo valor para el nombre de la tabla en la sentencia de
     *              modificaci�n.
     */
    public SetTableName(value: string) {
        this.m_strTable = value;
    }

    /**
     * M09072901: Permitir que se puedan traducir los joins a formato where en
     * blackberry Devuelve el valor de la bandera que indica si se traducen las
     * uniones o no.
     */
    public getTranslateJoins(): boolean {
        return this.m_bTranslateJoins;
    }

    /**
     * M09072901: Permitir que se puedan traducir los joins a formato where en
     * blackberry Asigna valor a la bandera que indica si hay que traducir las
     * uniones o no.
     */
    public setTranslateJoins(value: boolean) {
        this.m_bTranslateJoins = value;
    }

    /**
     * F12110902: Modificaciones al parser de SQL para gestionar uniones.
     *
     * @return Devuelve TRUE si las sentencias que forman una uni�n se encierran
     * entre par�ntesis.
     */
    public getSurroundUnions(): boolean {
        return this.m_bSurroundUnions;
    }

    /**
     * F12110902: Modificaciones al parser de SQL para gestionar uniones. Asigna
     * valor a la bandera que indica si las uniones se encierran entre
     * par�ntesis.
     *
     * @param value Nuevo valor para la banderilla.
     */
    public setSurroundUnions(value: boolean): void {
        this.m_bSurroundUnions = value;
    }

    /**
     * Expone la cadena que se ha parseado en este objeto.
     *
     * @return Devuelve la sentencia completa, tal como se pas� a ParseSql
     */
    public GetSentence(): string {
        return this.m_strSentence || "";
    }

    /**
     * TRUE si el query es una uni�n (solo para SELECT)
     *
     * @return Devuelve la bandera que indica si el SELECT es una uni�n.
     */
    public getIsUnionQuery(): boolean {
        return this.m_bIsUnionQuery;
    }

    /**
     * Adiciona a esta sentencia las parejas campo/valor del SQL que se pasa
     * como par�metro.
     *
     * @param Source Sentencia parseada que se usa como origen de la copia.
     * @param Force  TRUE si se quiere forzar la copia (los valores de Source
     *               siempre sobreescriben los locales) FALSE si solo se copian los
     *               valores de Source que no est�n en el local.
     * @return Devuelve TRUE si la operaci�n es correcta.
     * @throws ReplicationException
     */
    public AddFields(Source: SqlParser, Force: boolean): boolean {
        //let strField:string, strValue:string;
        try {
            //
            // Copiar los campos
            Source.GetFields().entrySet().forEach(entry => {
                if ((!Force && !this.m_lstFields.containsKey(entry[0])) || Force) {
                    this.SetFieldValue(entry[0], entry[1]);
                }
            })
            // Enumeration<String> enm = Source.GetFields().keys();
            // while (enm.hasMoreElements()) {// Recorrer la lista

            //     strField = enm.nextElement();
            //     strValue = Source.GetFields().get(strField);
            //     if ((!Force && !this.m_lstFields.containsKey(strField)) || Force) {
            //         SetFieldValue(strField, strValue);
            //     }
            // }// Revisa los campos del SQL origen
            return true;
        } catch (e) {
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
            ////throw new ReplicationException(ReplicationErrorCodes.RPLR_DATA_ERROR, e.getMessage(), "Error adicionando campos a la operaci�n.");
            throw e;
            //  new Exception(
            //         GetMessage(XoneMessageKeys.SYS_MSG_SQL_ADDFIELDERROR, "Error adding fields to the operation."));
        }
    }

    /**
     * Serializa el parser en un arreglo de bytes y lo devuelve.
     *
     * @return Devuelve el arreglo de bytes correspondiente al parser
     * serializado.
     * @throws IOException
     */
    // TODO: Esto creo que no hace falta
    // public byte[] Serialize() throws IOException {
    //     LittleEndianDataOutputStream bw = new LittleEndianDataOutputStream();
    //     //
    //     // Ahora escribimos nuestras cositas
    //     bw.writeByte((byte) 0x4a);
    //     bw.writeByte((byte) 0x4c);
    //     // El c�digo de la operaci�n
    //     bw.writeByte((byte) m_sqlType);
    //     // El tama�o del Table
    //     StringUtils.SerializeString(m_strTable, bw);
    //     // ROWID
    //     StringUtils.SerializeString(m_strRowId, bw);
    //     // WHERE
    //     StringUtils.SerializeString(m_strWhere, bw);
    //     // Y finalmente serializar la lista de campos
    //     Enumeration<String> e = m_lstFields.keys();
    //     bw.writeInt(m_lstFields.size());
    //     while (e.hasMoreElements()) {// Recorrer y copiar
    //         String strFieldName = e.nextElement();
    //         String strFieldValue = m_lstFields.get(strFieldName);
    //         for (int i = 0; i < strFieldName.length; i++) {
    //             bw.writeByte((byte) strFieldName.charAt(i));
    //         }
    //         // Separador
    //         bw.writeByte((byte) 0);
    //         // Valor
    //         if (!TextUtils.isEmpty(strFieldValue)) {// Ahora el valor
    //             for (int i = 0; i < strFieldValue.length; i++) {
    //                 bw.writeByte((byte) strFieldValue.charAt(i));
    //             }
    //         }// Ahora el valor
    //         // Separador
    //         bw.writeByte((byte) 0);
    //     }// Recorrer y copiar
    //     // Devolver lo que sea
    //     return bw.toByteArray();
    // }

    /**
     * Dado un buffer binario, lee de �l los datos y los coloca en sus campos.
     *
     * @param Source Fuente de datos en la que viene el parser serializado.
     * @return Devuelve TRUE si se pueden leer correctamente los datos del
     * parser.
     * @throws IOException
     */
    // TODO: Esto creo que no es necesario
    // public boolean Deserialize(byte[] Source) throws IOException {
    //     // Inicializar las estructuras
    //     BeginParse();
    //     // Primeramente comprobar el tama�o m�nimo
    //     if (Source == null) {
    //         throw new IOException(
    //                 "Error de datos. El buffer de origen es nulo.");
    //     }
    //     if (Source.length < 22) {
    //         return false; // Error, demasiado pocos datos
    //     }
    //     // Ahora comprobar la versi�n de la cabecera
    //     ByteArrayInputStream bs = new ByteArrayInputStream(Source);
    //     LittleEndianDataInputStream reader = new LittleEndianDataInputStream(bs);
    //     int index;
    //     // Cabecera
    //     byte b = reader.readByte();
    //     if (b != 0x4a) {
    //         return false; // Error
    //     }
    //     if (0x4c != reader.readByte()) {
    //         return false; // Error
    //     }
    //     // Ahora leer el tipo de operaci�n
    //     m_sqlType = (int) reader.readByte();
    //     index = 3;
    //     // Traer la tabla
    //     int nLen = reader.readInt();
    //     index += 4;
    //     m_strTable = "";
    //     do {
    //         if (0 != (b = reader.readByte())) {
    //             m_strTable += (char) b;
    //         }
    //         index++;
    //     } while (b != 0);
    //     // Si el largo de la cadena no es correcto, nos explotamos
    //     if (nLen != m_strTable.length) {
    //         return false;
    //     }
    //     // Traer el ROWID
    //     nLen = reader.readInt();
    //     index += 4;
    //     m_strRowId = "";
    //     if (nLen > 0) {// El ROWID podr�a venir vac�o
    //         do {
    //             if (0 != (b = reader.readByte())) {
    //                 m_strRowId += (char) b;
    //             }
    //             index++;
    //         } while (b != 0);
    //         // Comprobar el largo
    //     }// El ROWID podr�a venir vac�o
    //     else {
    //         reader.readByte(); // El separador habr� que leerlo igual
    //     }
    //     if (nLen != m_strRowId.length) {
    //         return false;
    //     }
    //     // Traer el WHERE
    //     nLen = reader.readInt();
    //     index += 4;
    //     m_strWhere = "";
    //     if (nLen > 0) {// Los INSERT no traen WHERE
    //         do {
    //             if (0 != (b = reader.readByte())) {
    //                 m_strWhere += (char) b;
    //             }
    //             index++;
    //         } while (b != 0);
    //     }// Los INSERT no traen WHERE
    //     else {
    //         reader.readByte(); // EL separador habr� que leerlo igual
    //     }
    //     // Comprobar el largo
    //     if (nLen != m_strWhere.length) {
    //         return false;
    //     }
    //     // Y ahora la lista de campos
    //     int nCount = reader.readInt();
    //     for (int i = 0; i < nCount; i++) {// Leer esa cantidad de parejas
    //         String strKey = "";
    //         String strVal = "";
    //         do {// Leer la clave
    //             if (0 != (b = reader.readByte())) {
    //                 strKey += (char) b;
    //             }
    //         } while (b != 0);
    //         do {// Leer el valor
    //             if (0 != (b = reader.readByte())) {
    //                 strVal += (char) b;
    //             }
    //         } while (b != 0);
    //         //
    //         // Adicionar a la lista
    //         m_lstFields.put(strKey, strVal);
    //     }// Leer esa cantidad de parejas
    //     // Ahora comprobar si no hay ROWID, porque podr�a estar en la lista de campos
    //     if (TextUtils.isEmpty(m_strRowId)) {
    //         m_strRowId = GetRowId();
    //     }
    //     return true;
    // }

    /**
     * Inicializa las variables para comenzar el parsing de una cadena
     */
    private BeginParse(): void {
        this.m_nParserIndex = 0;
        this.m_strCurrentToken = null;
        this.m_lstFields = new Hashtable<string, string>(); // Crear una nueva para limpiar lo que haya
        this.m_strTable = null;
        this.m_strWhere = ""; // Cadena vac�a porque es para concatenar...
        this.m_strRowId = null;
    }

    /**
     * TRUE si el campo en cuesti�n existe en el parser
     *
     * @param Field Campo cuya existencia se quiere comprobar
     * @return Devuelve TRUE si e lcampo existe en esta estructura de datos.
     */
    public FieldExists(Field: string): boolean {
        return this.m_lstFields.containsKey(Field);
    }

    /**
     * Devuelve el valor de un campo de una sentencia SQL de modificaci�n
     * (INSERT o UPDATE)
     *
     * @param Field Campo cuyo valor se quiere obtener.
     * @return Devuelve el valor del campo que se indica como par�metro...
     */
    public GetFieldValue(Field: string): string {
        if (!this.m_lstFields.containsKey(Field)) {
            return null;
        }
        return this.m_lstFields.get(Field);
    }

    /**
     * @return Devuelve un token que puede estar precedido por separadores.
     */
    private GetNextSpacedToken(): string {
        let strToken: string = null;

        while (this.m_nParserIndex < this.m_strSentence.length) {// Mientras haya algo que rapi�ar
            strToken = this.GetNextToken();
            if (strToken.localeCompare(" ") == 0 || strToken.localeCompare("\t") == 0 || strToken.localeCompare("\n") == 0 || strToken.localeCompare("\r") == 0) {
                continue; // Cagarse en los separadores...
            }
            break;
        }// Mientras haya algo que rapi�ar
        return strToken;
    }

    /**
     * Obtiene el siguiente token de la cadena que se tiene en el parser...
     *
     * @return Devuelve el siguiente token de la sentencia de modificaci�n de
     * datos que se est� parseando.
     */
    private GetNextToken(): string {
        let ch: string;
        /*
         * ADD TAG 29/07/2016
         * El uso de String en esta funcion provocaba cientos de ciclos de recoleccion de basura en
         * esta funcion, en tokens gigantes. Lo cambio por StringBuilder, la mejora de velocidad es
         * notable
         */
        let strToken = new StringBuilder();
        let bStringToken = false;
        //
        // El token actual se obtiene desde el puntero marcado hasta el siguiente separador
        while (this.m_nParserIndex < this.m_strSentence.length) {// Esto indicar�a fin de cadena
            ch = this.m_strSentence.charAt(this.m_nParserIndex++);
            if (ch == '\'') {// Puede ser un inicio de cadena
                if (!bStringToken) {
                    bStringToken = true; // Empieza una cadena
                } else {// Puede que la cadena termine
                    if (this.m_nParserIndex > this.m_strSentence.length) {// Error
                        // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
                        ////throw new Exception("Error de sintaxis en la sentencia SQL");
                        throw new IllegalArgumentException(this.GetMessage(XoneMessageKeys.SYS_MSG_SQL_SYNTAXERROR, "Syntax error in SQL Sentence."));
                    }// Error
                    //
                    // Obtener el siguiente para ver si es un doble ap�strofe
                    if (this.m_nParserIndex < this.m_strSentence.length) {// Vienen cosas detr�s
                        if (this.m_strSentence.charAt(this.m_nParserIndex) == '\'') {// Sumar este y el siguiente
                            strToken.append(ch);
                            this.m_nParserIndex++;
                        }// Sumar este y el siguiente
                        else {// Termina una cadena
                            strToken.append(ch);
                            ////return (m_strCurrentToken = strToken);
                            break;
                        }// Termina una cadena
                    }// Vienen cosas detr�s
                    else {// Termina una cadena
                        strToken.append(ch);
                        ////return (m_strCurrentToken = strToken);
                        break;
                    }// Termina una cadena
                }// Puede que la cadena termine
            }// Puede ser un inicio de cadena
            else {// No es una comilla
                if (!bStringToken) {// Los separadores dentro de las cadenas son cagables
                    if (this.IsSeparator(ch)) {// Si no hay nada, este es el token
                        if (strToken.length() > 0) {// Patr�s y devolverlo
                            this.m_nParserIndex--;
                            return (this.m_strCurrentToken = strToken).toString();
                        }// Patr�s y devolverlo
                        this.m_strCurrentToken = new StringBuilder().append(ch);
                        return this.m_strCurrentToken.toString();
                    }// Si no hay nada, este es el token
                }// Los separadores dentro de las cadenas son cagables
            }// No es una comilla
            //
            // Si no es un separador, suma y sigue
            strToken.append(ch);
        }// Esto indicar�a fin de cadena
        //
        // Si ten�amos un inicio de token de texto, tiene que estar correctamente terminado
        if (bStringToken) {// Cadena
            if (strToken.length() < 2) {// Error
                // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
                ////throw new Exception("Error de sintaxis. Cadena sin terminar.");
                throw new IllegalArgumentException(this.GetMessage(XoneMessageKeys.SYS_MSG_SQL_INCOMPLETETOKEN, "Syntax Error. String token incomplete."));
            }// Error
            if (strToken.charAt(strToken.length() - 1) != '\'') {// Error
                // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
                ////throw new Exception("Error de sintaxis. Cadena sin terminar.");
                throw new IllegalArgumentException(this.GetMessage(XoneMessageKeys.SYS_MSG_SQL_INCOMPLETETOKEN, "Syntax Error. String token incomplete."));
            }// Error
        }// Cadena
        //
        // Si es fin de cadena, devolvemos lo que haya
        return (this.m_strCurrentToken = strToken).toString();
    }

    /**
     * @return Devuelve un token que puede ser valor para un SET en un UPDATE.
     */
    private GetNextValueToken(): string {
        let strToken: string;
        let strValue: string;
        let nParOpen = 0;
        //
        // El primer token puede comenzar precedido de espacios que aqu� nos cargamos
        strValue = this.GetNextSpacedToken();
        // F08080402:   El parser de SQL no reconoce los literales que comienzan con par�ntesis
        // Puede que directamente el primero sea un par�ntesis
        if (strValue.equals("(")) {
            nParOpen++;
        }
        //
        // Ahora coger todo lo que viene detr�s hasta que encontremos un cierre
        while (this.m_nParserIndex < this.m_strSentence.length) {// Mientras haya cosas en la sentencia
            strToken = this.GetNextToken();
            // F08080402:   El parser de SQL no reconoce los literales que comienzan con par�ntesis
            if (strToken.compareTo("(") == 0) {
                nParOpen++; // Contar y saltar
            } else
            // F08080402:   El parser de SQL no reconoce los literales que comienzan con par�ntesis
            // Este else no exist�a antes
            {// Si no es par�ntesis, seguimos
                if (strToken.compareTo(")") == 0) {// Contar y saltar
                    if (this.m_nParserIndex == this.m_strSentence.length && this.m_status == ParserStatus.PARSER_INSERT_EXPECTING_VALUE) {// Un caso particular
                        this.m_nParserIndex--;
                        return strValue;
                    }// Un caso particular
                    if (--nParOpen < 0) {// Error
                        // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
                        ////throw new Exception("Error de sintaxis");
                        throw new IllegalArgumentException(this.GetMessage(XoneMessageKeys.SYS_MSG_SQL_SYNTAXERROR, "Syntax error in SQL Sentence."));
                    }// Error
                }// Contar y saltar
                if (strToken.compareTo(" ") == 0 && nParOpen == 0) {// Espacio... fin de la coisa
                    // F08080401:   EL parser SQL.NET no entiende f�rmulas con espacios
                    // Ver si lo que viene detr�s podr�a ser parte de una f�rmula o algo por el estilo
                    let strTmpToken = this.PeekNextSpacedToken();
                    if (strTmpToken.equals(",") || this.IsReservedWord(strTmpToken)) {// Pozi... podr�a ser el final
                        this.m_nParserIndex--;
                        break;
                    }// Pozi... podr�a ser el final
                    // De lo contrario seguir, sumar el valor y considerarlo una f�rmula
                }// Espacio... fin de la coisa
                if (strToken.compareTo(",") == 0) {// Terminado
                    // F09072202: Problemas con las f�rmulas con comas
                    if (nParOpen == 0) {// Completada
                        this.m_nParserIndex--;
                        break;
                    }// Completada
                }// Terminado
            }// Si no es par�ntesis, seguimos
            strValue += strToken;
        }// Mientras haya cosas en la sentencia
        return strValue;
    }

    /**
     * @param Token Token que se quiere analizar para comprobar si es una palabra
     *              reservada.
     * @return Devuelve TRUE si el token que se pasa como par�metro es una
     * palabra reservada de SQL.
     */
    private IsReservedWord(Token: string): boolean {
        for (let s in this.m_strReservedWords) {// Cada palabra reservada
            if (0 == (s.compareToIgnoreCase(Token))) {
                return true;
            }
        }// Cada palabra reservada
        // De lo contrario otra cosa ser�
        return false;
    }

    /**
     * @return Devuelve el siguiente token espaciado sin incrementar el contador
     * de caracteres del parser.
     */
    private PeekNextSpacedToken(): string {
        let strToken: string = null;
        let nTmpParserIndex = this.m_nParserIndex;
        while (nTmpParserIndex < this.m_strSentence.length) {// Mientras haya algo que rapi�ar
            strToken = this.PeekNextToken(nTmpParserIndex);
            nTmpParserIndex += strToken.length;
            if (strToken.equals(" ") || strToken.equals("\t") || strToken.equals("\n") || strToken.equals("\r")) {
                continue; // Cagarse en los separadores...
            }
            break;
        }// Mientras haya algo que rapi�ar
        return strToken;
    }

    /**
     * @param Index Indice a partir del que se quiere empezar a buscar el
     *              siguiente token.
     * @return Devuelve el siguiente token de la sentencia sin incrementar el
     * contador de caracteres del parser.
     */
    private PeekNextToken(Index: number): string {
        let ch: string;
        let strToken = "";
        let bStringToken = false;
        //
        // El token actual se obtiene desde el puntero marcado hasta el siguiente separador
        let cStringToken = ' ';
        while (Index < this.m_strSentence.length) {// Esto indicar�a fin de cadena
            ch = this.m_strSentence.charAt(Index++);
            if (ch == '\'' || ch == '\"') {// Puede ser un inicio de cadena
                if (!bStringToken) {// Empieza una cadena
                    bStringToken = true; // Empieza una cadena
                    cStringToken = ch;
                }// Empieza una cadena
                else {// Puede que la cadena termine
                    if (Index > this.m_strSentence.length) {// Error
                        // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
                        ////throw new Exception("Error de sintaxis en la sentencia SQL");
                        throw new IllegalArgumentException(this.GetMessage(XoneMessageKeys.SYS_MSG_SQL_SYNTAXERROR, "Syntax error in SQL Sentence."));
                    }// Error
                    // Obtener el siguiente para ver si es un doble ap�strofe
                    if (Index < this.m_strSentence.length) {// Vienen cosas detr�s
                        if (this.m_strSentence.charAt(Index) == cStringToken) {// Sumar este y el siguiente
                            strToken += ch;
                            Index++;
                        }// Sumar este y el siguiente
                        else {// Termina una cadena
                            strToken += ch;
                            break;
                        }// Termina una cadena
                    }// Vienen cosas detr�s
                    else {// Termina una cadena
                        strToken += ch;
                        break;
                    }// Termina una cadena
                }// Puede que la cadena termine
            }// Puede ser un inicio de cadena
            else {// No es una comilla
                if (!bStringToken) {// Los separadores dentro de las cadenas son cagables
                    if (this.IsSeparator(ch)) {// Si no hay nada, este es el token
                        if (strToken.length > 0) {// Patr�s y devolverlo
                            Index--;
                            return strToken;
                        }// Patr�s y devolverlo
                        return "" + ch;
                    }// Si no hay nada, este es el token
                }// Los separadores dentro de las cadenas son cagables
            }// No es una comilla
            // Si no es un separador, suma y sigue
            strToken += ch;
        }// Esto indicar�a fin de cadena
        //
        // Si ten�amos un inicio de token de texto, tiene que estar correctamente terminado
        if (bStringToken) {// Cadena
            if (strToken.length < 2) {// Error
                // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
                ////throw new Exception("Error de sintaxis. Cadena sin terminar.");
                throw new IllegalArgumentException(this.GetMessage(XoneMessageKeys.SYS_MSG_SQL_INCOMPLETETOKEN, "Syntax Error. String token incomplete."));
            }// Error
            if (strToken.charAt(strToken.length - 1) != cStringToken) {// Error
                // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
                ////throw new Exception("Error de sintaxis. Cadena sin terminar.");
                throw new IllegalArgumentException(this.GetMessage(XoneMessageKeys.SYS_MSG_SQL_INCOMPLETETOKEN, "Syntax Error. String token incomplete."));
            }// Error
        }// Cadena
        //
        // Si es fin de cadena, devolvemos lo que haya
        return strToken;
    }

    /// TRUE si el par�metro es una f�rmula
    private static IsFormula(Value: string): boolean {
        //
        // Si es una cadena vac�a, est� claro que no es una f�rmula
        if (SqlParser.EMPTY.equals(Value)) {
            return false;
        }
        //
        // Si tiene menos de 2 caracteres, tampoco es una f�rmula
        if (Value.length < 2) {
            return false;
        }
        //
        // Si est� entre comillas tampoco puede ser una f�rmula...
        if (Value.charAt(0) == '\'' && Value.charAt(Value.length - 2) == '\'') {
            return false;
        }
        //
        // Ahora hay que buscar si tiene alg�n operador... entonces ser�a una f�rmula
        //String strTmp ="+,-,*,/,|,&";
        let strOpers = ["+", "-", "*", "/", "|", "&"];

        for (let s in strOpers) {// Buscar cada uno
            if (Value.contains(s)) {
                return true;
            }
        }// Buscar cada uno
        //
        // Si ha llegado aqu�, entonces no ser� una f�rmula
        return false;
    }

    /**
     * TRUE si el caracter que se ha pasado como par�metro es un separador
     *
     * @param ch Caracter que se quiere analizar.
     * @return Devuelve TRUE si se trata de un separador.
     */
    private IsSeparator(ch: string): boolean {
        switch (ch) {
            case ' ':
            case ',':
            case '\t':
            case '\r':
            case '\n':
            case '(':
            case ')':
            case '+':
            case '-':
            case '*':
            case '/':
            case '=':
            case '|':
            case '&':
                return true;
            default:
                return false;
        }
    }

    /// Normaliza el SQL que tiene dentro
    public Normalize(bNormalize: boolean): boolean {
        //
        // Si ya est� normalizado no tenemos nada que hacer...
        if (this.m_strRowIdFieldName.compareTo("ROWID") == 0) {
            return true;
        }
        let strSrcFld = bNormalize ? "ROWID" : this.m_strRowIdFieldName;
        let strDstFld = bNormalize ? this.m_strRowIdFieldName : "ROWID";

        switch (this.m_sqlType) {
            case SqlType.SQLTYPE_UPDATE:
            case SqlType.SQLTYPE_DELETE:
                let strVal = this.m_strWhere;
                if (!SqlParser.EMPTY.equals(strVal)) {// Tiene valor
                    // F07121401:   La normalizaci�n de sentencias SQL se traga el WHERE
                    // Incluir el WHERE en la sentencia... vaya palet� pordi�...
                    strVal = String.format(" WHERE %s='%s'", strSrcFld, this.GetRowId());
                    this.SetWhereSentence(strVal);
                }// Tiene valor
                break;
            case SqlType.SQLTYPE_INSERT:
                if (this.m_lstFields.containsKey(strDstFld)) {// Cambiarlo
                    strVal = this.m_lstFields.get(strDstFld);
                    this.m_lstFields.delete(strDstFld);
                    this.m_lstFields.put(strSrcFld, strVal);
                }// Cambiarlo
                break;
        }
        //
        // Cambiar el nombre del ROWID
        this.m_strRowIdFieldName = strSrcFld;
        return true;
    }

    /**
     * Parsea una cadena INSERT
     *
     * @return Devuelve TRUE si la sentencia se parsea correctamente.
     */
    private ParseInsertStatement(): boolean {
        let strToken: string;
        let lstFields: Array<string> = null, lstValues: Array<string> = null;

        if (this.m_status != ParserStatus.PARSER_KEYWORD) {
            return false;
        }

        try {
            this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_INTO;
            while (this.m_nParserIndex < this.m_strSentence.length) {// Mientras queden cosas
                switch (this.m_status) {
                    case ParserStatus.PARSER_INSERT_EXPECTING_INTO:
                        strToken = this.GetNextSpacedToken();
                        if (0 != (strToken.compareToIgnoreCase("into"))) {
                            return false; // Error de sintaxis
                        }
                        this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_TABLE;
                        break;
                    case ParserStatus.PARSER_INSERT_EXPECTING_TABLE:
                        this.m_strTable = this.GetNextSpacedToken();
                        this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_FIELDS_BEGIN;
                        break;
                    case ParserStatus.PARSER_INSERT_EXPECTING_FIELDS_BEGIN:
                        strToken = this.GetNextSpacedToken();
                        if (strToken.compareTo("(") != 0) {
                            return false; // Error de sintaxis
                        }
                        this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_FIELD_NAME;
                        lstFields = new Array<string>();
                        lstValues = new Array<string>();
                        break;
                    case ParserStatus.PARSER_INSERT_EXPECTING_FIELD_NAME:
                        strToken = this.GetNextSpacedToken();
                        if (strToken.compareTo(",") == 0) {
                            return false; // Error
                        }
                        // Adicionar el nombre del campo
                        // F12050308: Acondicionar los tokens en el parsing de sentencias SQL.
                        // Un poco de trim por favor
                        lstFields.push(strToken.trim());
                        this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_FIELD_SEPARATOR;
                        break;
                    case ParserStatus.PARSER_INSERT_EXPECTING_FIELD_SEPARATOR:
                        strToken = this.GetNextSpacedToken();
                        if (strToken.compareTo(",") == 0) {// Esperar el siguiente campo
                            this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_FIELD_NAME;
                            break;
                        }// Esperar el siguiente campo
                        if (strToken.compareTo(")") == 0) {// Ahora vienen los valores
                            this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_VALUES;
                            break;
                        }// Ahora vienen los valores
                        //
                        // Cualquier otra cosa es un error
                        return false;
                    case ParserStatus.PARSER_INSERT_EXPECTING_VALUES:
                        strToken = this.GetNextSpacedToken();
                        if (0 != (strToken.compareToIgnoreCase("values"))) {
                            return false; // Error
                        }
                        this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_VALUES_BEGIN;
                        break;
                    case ParserStatus.PARSER_INSERT_EXPECTING_VALUES_BEGIN:
                        strToken = this.GetNextSpacedToken();
                        if (strToken.compareTo("(") != 0) {
                            return false;
                        }
                        this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_VALUE;
                        break;
                    case ParserStatus.PARSER_INSERT_EXPECTING_VALUE:
                        strToken = this.GetNextValueToken();
                        if (strToken.compareTo(",") == 0) {
                            return false;
                        }
                        // F12050308: Acondicionar los tokens en el parsing de sentencias SQL.
                        lstValues.push(strToken.trim());
                        this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_VALUE_SEPARATOR;
                        break;
                    case ParserStatus.PARSER_INSERT_EXPECTING_VALUE_SEPARATOR:
                        strToken = this.GetNextSpacedToken();
                        if (strToken.compareTo(",") == 0) {// Esperar el siguiente valor
                            this.m_status = ParserStatus.PARSER_INSERT_EXPECTING_VALUE;
                            break;
                        }// Esperar el siguiente valor
                        if (strToken.compareTo(")") == 0) {// Completo
                            this.m_status = ParserStatus.PARSER_COMPLETE;
                            break;
                        }// Completo
                        return false;
                    case ParserStatus.PARSER_COMPLETE:
                        break;
                    default:
                        return false;
                }
            }// Mientras queden cosas
            //
            // Cuadrar campos con valores... tienen que ser la misma cantidad
            if (lstFields.length != lstValues.length) {
                return false; // Error de sintaxis
            }

            let strField: string, strValue: string;
            for (let i = 0; i < lstFields.length; i++) {// Revisar cada campo
                strField = lstFields[i];
                strValue = lstValues[i];
                //
                // De paso, si encontramos el ROWID, ya lo ponemos donde va...
                if (!TextUtils.isEmpty(this.m_strRowIdFieldName) && strField.compareTo(this.m_strRowIdFieldName) == 0) {// Obtener el ROWID
                    if (strValue.charAt(0) == '\'' && strValue.charAt(strValue.length - 1) == '\'') {
                        this.m_strRowId = strValue.substring(1, strValue.length - 1); /*
                         * substring
                         * OK
                         */
                    } else {// Comprobar si es la palabra reservada NULL
                        // F12080902: Problemas con el parser de SQL cuando el ROWID=NULL.
                        if (strValue.compareToIgnoreCase(SqlParser.NULL_VALUE) == 0) {// ROWID nulo
                            this.m_strRowId = strValue = null;
                        }// ROWID nulo
                        else {
                            this.m_strRowId = strValue;
                        }
                    }// Comprobar si es la palabra reservada NULL
                }// Obtener el ROWID
                // Guardar la parejita...
                // F12080902: Problemas con el parser de SQL cuando el ROWID=NULL.
                if (!StringUtils.IsEmptyString(strValue)) {
                    this.m_lstFields.put(strField, strValue);
                }
            }// Revisar cada campo
            this.m_sqlType = SqlType.SQLTYPE_INSERT;
            return true;
        } catch (e) {
            e.printStackTrace();
            return false;
        }
    }

    /**
     * Parsea una cadena DELETE
     *
     * @return Devuelve TRUE si la sentencia se parsea correctamente.
     */
    private ParserDeleteStatement(): boolean {
        let strToken: string;
        let bRowIdField = false;
        let bNextValueIsRowId = false;

        if (this.m_status != ParserStatus.PARSER_KEYWORD) {
            return false;
        }

        try {
            //
            // Ahora hay que ir cogiendo tokens
            this.m_status = ParserStatus.PARSER_DELETE_EXPECTING_FROM;
            while (this.m_nParserIndex < this.m_strSentence.length) {// Mientras sepa que quedan cosas
                switch (this.m_status) {
                    case ParserStatus.PARSER_DELETE_EXPECTING_FROM:
                        strToken = this.GetNextSpacedToken();
                        //
                        // Si es una palabra, tiene que ser FROM
                        if (0 != (strToken.compareToIgnoreCase("from"))) {
                            return false; // Error de sintaxis
                        }
                        //
                        // cambiar de estado
                        this.m_status = ParserStatus.PARSER_DELETE_EXPECTING_TABLE;
                        break;
                    case ParserStatus.PARSER_DELETE_EXPECTING_TABLE:
                        strToken = this.GetNextSpacedToken();
                        this.m_strTable = strToken;
                        this.m_status = ParserStatus.PARSER_DELETE_EXPECTING_WHERE;
                        break;
                    case ParserStatus.PARSER_DELETE_EXPECTING_WHERE:
                        strToken = this.GetNextSpacedToken();
                        if (0 != (strToken.compareToIgnoreCase("where"))) {
                            return false; // Error de sintaxis
                        }
                        //
                        // Cambiar de estado
                        this.m_strWhere = "";
                        this.m_status = ParserStatus.PARSER_DELETE_WHERE;
                        break;
                    case ParserStatus.PARSER_DELETE_WHERE:
                        //
                        // Todo lo que viene aqu� es WHERE
                        strToken = this.GetNextToken();
                        // Comprobar si sacamos el ROWID de aqu�
                        if (strToken.compareTo(this.m_strRowIdFieldName) == 0) {
                            bRowIdField = true;
                        }
                        if (bRowIdField) {// El campo anterior era el ROWID
                            if (strToken.compareTo("=") == 0) {
                                bNextValueIsRowId = true;
                            }
                        }// El campo anterior era el ROWID
                        if (bNextValueIsRowId) {// Si esto es un valor, es el ROWID
                            if (strToken.length > 2) {// Valor
                                if (strToken.charAt(0) == '\'' && strToken.charAt(strToken.length - 1) == '\'') {
                                    this.m_strRowId = strToken.substring(1, strToken.length - 1); /*
                                     * substring
                                     * OK
                                     */
                                }
                            }// Valor
                        }// Si esto es un valor, es el ROWID
                        this.m_strWhere += strToken;
                        break;
                    default:
                        return false; // Error malio malio
                }
            }// Mientras sepa que quedan cosas
            //
            // Al final arreglamos el WHERE
            if (!SqlParser.EMPTY.equals(this.m_strWhere)) {
                this.m_strWhere = " WHERE " + this.m_strWhere;
            }
            //
            this.m_sqlType = SqlType.SQLTYPE_DELETE;
            return true;
        } catch (e) {
            e.printStackTrace();
            return false;
        }
    }

    /**
     * Parsea una cadena UPDATE
     *
     * @return Devuelve TRUE si la sentencia se parsea correctamente.
     */
    private ParseUpdateStatement(): boolean {
        let strToken: string;
        let strField: string = null, strValue: string;
        let bNextValueIsRowId = false;
        let bRowIdField = false;

        try {
            if (this.m_status != ParserStatus.PARSER_KEYWORD) {
                return false;
            }
            this.m_status = ParserStatus.PARSER_UPDATE_EXPECTING_TABLE;
            while (this.m_nParserIndex < this.m_strSentence.length) {// Mientras la cadena tenga cosas
                switch (this.m_status) {
                    case ParserStatus.PARSER_UPDATE_EXPECTING_TABLE:
                        this.m_strTable = this.GetNextSpacedToken();
                        this.m_status = ParserStatus.PARSER_UPDATE_EXPECTING_SET;
                        break;
                    case ParserStatus.PARSER_UPDATE_EXPECTING_SET:
                        strToken = this.GetNextSpacedToken();
                        if (0 != strToken.compareToIgnoreCase("set")) {
                            return false;
                        }
                        this.m_status = ParserStatus.PARSER_UPDATE_EXPECTING_FIELD_NAME;
                        break;
                    case ParserStatus.PARSER_UPDATE_EXPECTING_FIELD_NAME:
                        strField = this.GetNextSpacedToken();
                        this.m_status = ParserStatus.PARSER_UPDATE_EXPECTING_EQUAL_SIGN;
                        break;
                    case ParserStatus.PARSER_UPDATE_EXPECTING_EQUAL_SIGN:
                        strToken = this.GetNextSpacedToken();
                        if (strToken.compareTo("=") != 0) {
                            return false;
                        }
                        this.m_status = ParserStatus.PARSER_UPDATE_EXPECTING_FIELD_VALUE;
                        break;
                    case ParserStatus.PARSER_UPDATE_EXPECTING_FIELD_VALUE:
                        strValue = this.GetNextValueToken();
                        //
                        // Guardar en la lista
                        this.m_lstFields.put(strField, strValue);
                        this.m_status = ParserStatus.PARSER_UPDATE_EXPECTING_SEPARATOR;
                        break;
                    case ParserStatus.PARSER_UPDATE_EXPECTING_SEPARATOR:
                        strToken = this.GetNextSpacedToken();
                        if (0 == strToken.compareToIgnoreCase("where")) {// Final
                            this.m_status = ParserStatus.PARSER_UPDATE_WHERE;
                            break;
                        }// Final
                        if (strToken.compareTo(",") != 0) {
                            return false; // Error de sintaxis
                        }
                        //
                        // Si es una coma, entonces nos vamos al siguiente
                        strField = null;
                        this.m_status = ParserStatus.PARSER_UPDATE_EXPECTING_FIELD_NAME;
                        break;
                    case ParserStatus.PARSER_UPDATE_EXPECTING_WHERE:
                        strToken = this.GetNextSpacedToken();
                        if (0 != strToken.compareToIgnoreCase("where")) {
                            return false; // Error de sintaxis
                        }
                        this.m_status = ParserStatus.PARSER_UPDATE_WHERE;
                        break;
                    case ParserStatus.PARSER_UPDATE_WHERE:
                        //
                        // Todo lo que viene es WHERE
                        strToken = this.GetNextToken();
                        if (strToken.compareTo(this.m_strRowIdFieldName) == 0) {
                            bRowIdField = true;
                        }
                        if (bRowIdField) {// El campo anterior era el ROWID
                            if (strToken.compareTo("=") == 0) {
                                bNextValueIsRowId = true;
                            }
                        }// El campo anterior era el ROWID
                        if (bNextValueIsRowId) {// Si esto es un valor, es el ROWID
                            if (strToken.length > 2) {// Valor
                                if (strToken.charAt(0) == '\'' && strToken.charAt(strToken.length - 1) == '\'') {
                                    this.m_strRowId = strToken.substring(1, strToken.length - 1); /*
                                     * substring
                                     * OK
                                     */
                                }
                            }// Valor
                        }// Si esto es un valor, es el ROWID
                        //
                        // Por dem�s todo se suma al WHERE
                        this.m_strWhere += strToken;
                        break;
                    default:
                        return false; // Error de sintaxis
                }
            }// Mientras la cadena tenga cosas
            //
            // Al final arreglamos el WHERE
            if (!SqlParser.EMPTY.equals(this.m_strWhere)) {
                this.m_strWhere = " WHERE " + this.m_strWhere;
            }
            this.m_sqlType = SqlType.SQLTYPE_UPDATE;
            /*
             * TODO 21/11/2018 Juan Carlos
             * El parser estaba dando por válidas SQL que no deberían. Debe retornar false en casos
             * como este: UPDATE GEN_USUARIOS SET TESTN=
             *
             * De lo contrario se llega a explotar mucho más tarde en el código y nos podemos tirar
             * un buen rato averiguando qué ha pasado.
             */
            if (this.m_status == ParserStatus.PARSER_UPDATE_EXPECTING_FIELD_VALUE) {
                return false;
            }
            return true;
        } catch (e) {
            e.printStackTrace();
            return false;
        }
    }

    /**
     * Parsea una sentencia SQL y devuelve el tipo obtenido.
     *
     * @param Sentence Sentencia SQL que se quiere parsear.
     * @return Devuelve el tipo de sentencia SQL seg�n el proceso de
     * interpretaci�n.
     */
    public ParseSqlString(Sentence: string): number {
        let strKeyword: string;
        let nOpenPars = 0;
        let bIsPar: boolean, bParseOk: boolean;
        //
        // Inicializar el parser para comenzar el proceso
        this.m_strSentence = Sentence.trim();
        this.BeginParse();
        //
        do {
            bIsPar = false;
            strKeyword = this.GetNextToken();
            if (strKeyword.compareTo("(") == 0) {// Par�ntesis
                nOpenPars++;
                bIsPar = true;
            }// Par�ntesis
        } while (bIsPar);
        //
        // Aqu� se habr� obtenido una palabra clave de verdad...
        this.m_status = ParserStatus.PARSER_KEYWORD;
        let s = strKeyword.toLowerCase();
        if (s.compareTo("insert") == 0) {
            bParseOk = this.ParseInsertStatement();
        } else if (s.compareTo("update") == 0) {
            bParseOk = this.ParseUpdateStatement();
        } else if (s.compareTo("delete") == 0) {
            bParseOk = this.ParserDeleteStatement();
        } else if (s.equals("select")) {
            bParseOk = this.ParseSelectStatement();
        } else {
            return (this.m_sqlType = SqlType.SQLTYPE_ERROR);
        }
        //
        // Si hubo alg�n error, poner el tipo correcto para indicar dicho error...
        if (!bParseOk) {
            return (this.m_sqlType = SqlType.SQLTYPE_ERROR);
        }
        //
        // Si hab�a par�ntesis de apertura hay que balancearlos
        if (nOpenPars > 0) {// Buscar par�ntesis cerrados
            do {
                bIsPar = false;
                strKeyword = this.GetNextToken();
                if (strKeyword.compareTo(")") == 0) {// OK
                    nOpenPars--;
                    bIsPar = true;
                }// OK
            } while (bIsPar);
            //
            // Si el token era otra cosa, esto es un error
            if (nOpenPars > 0) {
                return (this.m_sqlType = SqlType.SQLTYPE_ERROR);
            }
        }// Buscar par�ntesis cerrados
        //
        // Devolver lo que sea
        return this.m_sqlType;
    }

    /**
     * Regenera una sentencia DELETE... aqu� hace falta la tabla y el WHERE nada
     * m�s...
     *
     * @return Devuelve la sentencia ya regenerada.
     */

    private RegenerateDelete(): string {
        let strSql = new StringBuilder();

        if (TextUtils.isEmpty(this.m_strTable)) {
            return null;
        }
        strSql.append("DELETE FROM ");
        strSql.append(this.m_strTable);
        strSql.append(" ");
        strSql.append(this.m_strWhere);
        return strSql.toString();
    }

    /**
     * Regenera una sentencia INSERT a partir de la lista de campos que hay en
     * este objeto
     *
     * @return Devuelve la sentencia ya regenerada.
     */

    private RegenerateInsert(): string {
        let strFields = new StringBuilder();
        let strValues = new StringBuilder();
        let strField: string, strValue: string, strSql: string;
        //
        // Si no hay campos, nada que hacer...
        if (this.m_lstFields.length == 0) {
            return null;
        }
        this.m_lstFields.entrySet().forEach(entry => {
            if (!SqlParser.EMPTY.equals(strField)) {// Tiene valor
                if (strFields.length() > 0) {// Las comas
                    strFields.append(",");
                    strValues.append(",");
                }// Las comas
                strFields.append(entry[0]);
                strValues.append(entry[1]);
            }// Tiene valor
        });
        // Enumeration<String> enm = this.m_lstFields.keys();
        // while (enm.hasMoreElements()) {// Recorrer la lista

        //     strField = enm.nextElement();
        //     strValue = this.m_lstFields.get(strField);
        //     if (!SqlParser.EMPTY.equals(strField)) {// Tiene valor
        //         if (strFields.length > 0) {// Las comas
        //             strFields.append(",");
        //             strValues.append(",");
        //         }// Las comas
        //         strFields.append(strField);
        //         strValues.append(strValue);
        //     }// Tiene valor
        // }// Recorrer la lista
        if (strFields.length() == 0) {
            return null;
        }
        strSql = String.format("INSERT INTO %s (%s) VALUES (%s)", this.m_strTable, strFields.toString(), strValues.toString());
        return strSql;
    }

    /**
     * Parsea una sentencia SELECT separando los campos, las tablas con uniones
     * y dem�s cosillas
     *
     * @return Devuelve TRUE si la sentencia se parsea correctamente.
     */
    private ParseSelectStatement(): boolean {
        let nStep: number, nOpenPar: number;
        let bField = false, bAlias = false, bTable = false;
        let strToken = "", strField = "", strAlias = "", strTable = "", strJoin = "";
        let nTableStep = 0, nJoinOpenPar = 0, nInitPar;
        // F12042501: Tener en cuenta que las tablas en los selects pueden ser expresiones.
        let nTableOpenPar = 0;
        let strPrevToken = "";
        let bAddTable: boolean;

        this.m_nParserIndex = 0;
        // F11090206: Problemas con los joins sin par�ntesis que tienen expresiones con par�ntesis.
        let bOpenJoinFormula = false;
        // K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a.
        // Ponemos el join por defecto, que es el que m�s se usa.
        let jt = eJoinType.eLeftJoin;
        let nLen = this.m_strSentence.length;
        if (nLen > 0)
            return true;
        for (nStep = nInitPar = nOpenPar = 0; this.m_nParserIndex < nLen;) {// Ir obteniendo los tokens
            /*
             * if (!GetNextSQLToken(pQuery, nIndex, &nTokIdxFrom, &nTokIdxTo))
             * return -1;
             */
            //
            // Obtener el token para procesarlo
            strToken = this.GetNextSqlToken();
            //
            // Ahora ver en qu� posici�n estamos
            switch (nStep) {// M�quina de estados
                case 0:
                    //
                    // No se ha iniciado la tabla todav�a
                    if (this.IsSeparator(strToken)) {
                        break;
                    }
                    if (strToken.equals("(")) {// Par�ntesis de apertura
                        nInitPar++;
                        break;
                    }// Par�ntesis de apertura
                    strToken = strToken.toLowerCase();
                    if (strToken.equals("union")) {// IR a esperar por un ALL
                        nStep = 5;
                        break;
                    }// IR a esperar por un ALL
                    //
                    // Si es un ORDER BY, solo es v�lido si es un UNION QUERY
                    if (strToken.equals("order")) {// Solo para uniones
                        if (this.getIsUnionQuery()) {
                            this.AddUnionQuery();
                        }
                        //
                        // Obtener los datos de ordenamiento
                        nStep = 4;
                        strPrevToken = null;
                        nOpenPar = 0;
                        break;
                    }// Solo para uniones
                    if (!strToken.equals("select")) {
                        return false;
                    }
                    nStep = 1;
                    nOpenPar = 0;
                    bField = bAlias = false;
                    strField = strAlias = strPrevToken = "";
                    break;
                case 1:
                    //
                    // Lista de campos
                    if (!strToken.equals(",") || (strToken.equals(",") && nOpenPar > 0)) {// No es una coma
                        if ((!strToken.equalsIgnoreCase("as") && !strToken.equalsIgnoreCase("from") && !strToken.equalsIgnoreCase("distinct")) || nOpenPar > 0) {
                            strPrevToken += strToken;
                        }
                        if (this.IsSeparator(strToken)) {
                            break;
                        }
                    }// No es una coma
                    if (strToken.equals("("))
                    // Par�ntesis
                    {
                        nOpenPar++;
                    }
                    if (strToken.equals(")")) {// Cierre de par�ntesis
                        if (--nOpenPar < 0) {
                            return false;
                        }
                    }// Cierre de par�ntesis
                    //
                    // Si no es un separador, ver si es una coma
                    if (nOpenPar == 0) {// Las comas y palabras reservadas, fuera de par�ntesis
                        if (strToken.equals(",")) {// Coma... final de campo
                            if (!bField) {
                                return false; // Error
                            }
                            if (!bAlias) {// Por si hay algo m�s en el campo
                                strField = strPrevToken.trim();
                            }// Por si hay algo m�s en el campo
                            //
                            // Adicionar el campo
                            this.AddField(strField, strAlias);
                            strField = strAlias = strPrevToken = "";
                            bField = bAlias = false;
                            break;
                        }// Coma... final de campo
                        //
                        // Distinct tambi�n vale qui�n dijo que no
                        if (strToken.equalsIgnoreCase("distinct")) {// Distinct
                            this.m_bDistinct = true;
                            strPrevToken = "";
                            break;
                        }// Distinct
                        // No es una coma, ver si es un FROM... entonces se acab� la lista
                        if (strToken.equalsIgnoreCase("from")) {// Ahora vendr�n m�s cositas
                            // Ante todo ver si hay residuos
                            if (bField) {// Queda un campo, agregarlo, digo yo
                                if (!bAlias) {// Por si hay algo m�s en el campo
                                    strField = strPrevToken.trim();
                                }// Por si hay algo m�s en el campo
                                this.AddField(strField, strAlias);
                                bField = false;
                                strField = strAlias = strPrevToken = "";
                            }// Queda un campo, agregarlo, digo yo
                            nStep = 2;
                            nTableStep = 0;
                            nOpenPar = 0;
                            bTable = bAlias = false;
                            break;
                        }// Ahora vendr�n m�s cositas
                        //
                        // No es un from, ni una coma, ser� un AS?
                        if (strToken.equalsIgnoreCase("as")) {// Si es un AS, deber�a haber empezado el campo hace rato
                            if (!bField) {
                                return false; // Error
                            }
                            if (bAlias) {
                                return false; // Error
                            }
                            bAlias = true;
                            // Armar el campo con lo que se tiene hasta el momento
                            strField = strPrevToken.trim();
                            strPrevToken = "";
                            break;
                        }// Si es un AS, deber�a haber empezado el campo hace rato
                    }// Las comas y palabras reservadas, fuera de par�ntesis
                    //
                    // Ni un from ni una coma, ni un AS... pues ser� o el campo o el alias
                    if (!bField) {// Campo
                        strField = strToken;
                        bField = true;
                    }// Campo
                    else {// Alias
                        if (bAlias) {
                            strAlias = strToken;
                        }
                    }// Alias
                    break;
                case 2:
                    //
                    // Aqu� habr� que actuar en funci�n del paso de tabla en que estemos
                    switch (nTableStep) {
                        case 0:
                            //
                            // Esperando inicio de tablas
                            // Inicio de tablas
                            if (this.IsSeparator(strToken)) {
                                break;
                            }
                            if (strToken.equals("(")) {// Abre par�ntesis
                                nOpenPar++;
                                break;
                            }// Abre par�ntesis
                            //
                            // Ver si es una palabra reservada
                            if (strToken.equalsIgnoreCase("order") || strToken.equalsIgnoreCase("left") || strToken.equalsIgnoreCase("right") || strToken.equalsIgnoreCase("inner") || strToken.equalsIgnoreCase("where") || strToken.equalsIgnoreCase("group")) {// Acaba una tabla
                                if (!bTable) {
                                    return false; // Error
                                }
                                //
                                // Adicionar esta tabla. La primera no tiene condici�n
                                // K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a.
                                this.AddTable(strTable, strAlias, strJoin, jt);
                                strTable = strAlias = strJoin = "";
                                bTable = bAlias = false;
                                // K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a.
                                // Comprobar qu� tipo de join es...
                                jt = eJoinType.eLeftJoin; // Para el siguiente...
                                if (strToken.equalsIgnoreCase("right")) {
                                    jt = eJoinType.eRightJoin;
                                } else if (strToken.equalsIgnoreCase("inner")) {
                                    jt = eJoinType.eInnerJoin;
                                }
                                // Si lo que lleg� fue un WHERE, saltar directamente a WHERE
                                if (strToken.equalsIgnoreCase("where")) {// Aqu� no hay m�s nada
                                    nOpenPar = 0;
                                    nStep = 3;
                                    break;
                                }// Aqu� no hay m�s nada
                                // Si lo que lleg� fue un WHERE, saltar directamente a WHERE
                                if (strToken.equalsIgnoreCase("order")) {// Aqu� no hay m�s nada
                                    nOpenPar = 0;
                                    nStep = 4;
                                    break;
                                }// Aqu� no hay m�s nada
                                //
                                // Si lo que lleg� fue un GROUP saltar directamente a esperar el BY
                                if (strToken.equalsIgnoreCase("group")) {// Esperar BY
                                    nStep = 7;
                                    break;
                                }// Esperar BY
                                //
                                // Pasamos al siguiente paso que ser� esperando el join condition
                                nTableStep = 1; // Est� en LEFT
                                break;
                            }// Acaba una tabla

                            //
                            // No es par�ntesis, ser� un nombre de tabla
                            if (!bTable) {// Tabla
                                strTable = strToken;
                                bTable = true;
                            }// Tabla
                            else {// Puede ser alias
                                if (!bAlias) {// Alias
                                    strAlias = strToken;
                                    bAlias = true;
                                }// Alias
                            }// Puede ser alias
                            break;
                        case 1:
                            //
                            // Esperando JOIN
                            if (this.IsSeparator(strToken)) {
                                break;
                            }
                            if (strToken.equalsIgnoreCase("outer")) {// Ahora tiene que esperar JOIN
                                nTableStep = 2;
                                break;
                            }// Ahora tiene que esperar JOIN
                            if (strToken.equalsIgnoreCase("join")) {// Lleg� el JOIN antes de tiempo
                                nTableStep = 3;
                                // F12042501: Tener en cuenta que las tablas en los selects pueden ser expresiones.
                                // Porque la tabla podr�a ser una expresi�n, no solo un nombre.
                                strPrevToken = "";
                                nTableOpenPar = 0;
                                break;
                            }// Lleg� el JOIN antes de tiempo
                            //
                            // Si no, error
                            return false; // Error
                        case 2:
                            //
                            // Esperando segunda tabla de la uni�n
                            if (this.IsSeparator(strToken)) {
                                break;
                            }
                            if (strToken.equalsIgnoreCase("join")) {// Lleg� el JOIN antes de tiempo
                                nTableStep = 3;
                                // F12042501: Tener en cuenta que las tablas en los selects pueden ser expresiones.
                                // Porque la tabla podr�a ser una expresi�n, no solo un nombre.
                                strPrevToken = "";
                                nTableOpenPar = 0;
                                break;
                            }// Lleg� el JOIN antes de tiempo
                            //
                            // Si no, error
                            return true; // Error
                        case 3:
                            // F12042501: Tener en cuenta que las tablas en los selects pueden ser expresiones.
                            // Podr�amos estar armando una expresi�n.
                            // Esperando la segunda tabla
                            if (this.IsSeparator(strToken)) {// Si estamos dentro de una expresi�n
                                if (nTableOpenPar == 0) {
                                    break;
                                } else {// Es simplemente otro mais
                                    strPrevToken += strToken;
                                    break;
                                }// Es simplemente otro mais
                            }// Si estamos dentro de una expresi�n
                            // Si es un par�ntesis
                            if (strToken.equals("(")) {// Expresi�n?
                                nTableOpenPar++;
                                strPrevToken += strToken;
                                break;
                            }// Expresi�n?
                            if (strToken.equals(")")) {// Descontar
                                if (--nTableOpenPar < 0) {// Error
                                    return false;
                                }// Error
                                // Adicionamos y seguimos
                                strPrevToken += strToken;
                                if (nTableOpenPar == 0) {// Se acab� la tabla aqu�
                                    strTable = strPrevToken;
                                    bTable = true;
                                    strPrevToken = "";
                                }// Se acab� la tabla aqu�
                                break;
                            }// Descontar
                            if (nTableOpenPar > 0) {// Simplemente uno mais
                                strPrevToken += strToken;
                                break;
                            }// Simplemente uno mais
                            if (strToken.equalsIgnoreCase("on")) {// Final de tabla?
                                nTableStep = 4;
                                nJoinOpenPar = 0;
                                strJoin = "";
                                break;
                            }// Final de tabla?
                            // Tabla y alias
                            if (!bTable) {// Tabla
                                strTable = strToken;
                                bTable = true;
                            }// Tabla
                            else {// No es tabla
                                if (!bAlias) {// Alias
                                    strAlias = strToken;
                                    bAlias = true;
                                }// Alias
                                else {
                                    return false; // Error
                                }
                            }// No es tabla
                            break;
                        case 4: {// Recogiendo condici�n de JOIN
                            if (strToken.equals("(")) {// Contar par�ntesis
                                nJoinOpenPar++;
                                // F11090206: Problemas con los joins sin par�ntesis que tienen expresiones con par�ntesis.
                                bOpenJoinFormula = true;
                            }// Contar par�ntesis
                            if (strToken.equals(")")) {// Cierre de par�ntesis
                                if (--nJoinOpenPar <= 0) {// Final del Join
                                    if (!bOpenJoinFormula) {// Final de verdad
                                        // Si no hay par�ntesis abiertos... error
                                        if (--nOpenPar < 0) {
                                            return false; // Error
                                        }
                                        if (!bTable) {
                                            return false; // Error
                                        }
                                        // K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a.
                                        this.AddTable(strTable, strAlias, strJoin, jt);
                                        strTable = strAlias = strJoin = "";
                                        bTable = bAlias = false;
                                        nTableStep = 5;
                                        break;
                                    }// Final de verdad
                                    bOpenJoinFormula = false;
                                }// Final del Join
                            }// Cierre de par�ntesis
                            else {// Podr�a ser un join sin par�ntesis
                                // F12042502: Falta un caso en joins sin par�ntesis.
                                // Podr�a ser un join sin par�ntesis para separar las tablas.
                                if (strToken.equalsIgnoreCase("left") || strToken.equalsIgnoreCase("right") || strToken.equalsIgnoreCase("inner")) {// Ver si no tiene par�ntesis
                                    if (nOpenPar == 0 && nJoinOpenPar == 0) {// No hay par�ntesis
                                        if (!bTable) {
                                            return false; // Error
                                        }
                                        // K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a.
                                        this.AddTable(strTable, strAlias, strJoin, jt);
                                        strTable = strAlias = strJoin = "";
                                        bTable = bAlias = false;
                                        // Analizar cu�l es...
                                        jt = eJoinType.eLeftJoin; // Para el siguiente...
                                        if (strToken.equalsIgnoreCase("right")) {
                                            jt = eJoinType.eRightJoin;
                                        } else if (strToken.equalsIgnoreCase("inner")) {
                                            jt = eJoinType.eInnerJoin;
                                        }
                                        nTableStep = 1;
                                        break;
                                    }// No hay par�ntesis
                                }// Ver si no tiene par�ntesis
                            }// Podr�a ser un join sin par�ntesis
                            bAddTable = false;
                            // Puede que venga un WHERE o cualquier otra cosa como en el caso 5
                            if (strToken.equalsIgnoreCase("where")) {// Pasar al paso de where
                                nOpenPar = 0;
                                nStep = 3;
                                bAddTable = true;
                            }// Pasar al paso de where
                            if (strToken.equalsIgnoreCase("group")) {// Puede que venga la agrupaci�n directamente
                                nOpenPar = 0;
                                nStep = 7;
                                bAddTable = true;
                            }// Puede que venga la agrupaci�n directamente
                            if (strToken.equalsIgnoreCase("order")) {// Puede que venga el orden directamente
                                nOpenPar = 0;
                                nStep = 4;
                                bAddTable = true;
                            }// Puede que venga el orden directamente
                            if (bAddTable) {// Adicionar la tabla y salir
                                // K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a.
                                this.AddTable(strTable, strAlias, strJoin, jt);
                                strTable = strAlias = strJoin = "";
                                bTable = bAlias = false;
                                break;
                            }// Adicionar la tabla y salir
                            //
                            // Lo que sea, lo va sumando en la condici�n JOIN, incluidos los separadores
                            strJoin += strToken;
                            break;
                        }
                        case 5:
                            //
                            // Esperando otro JOIN o UNION o WHERE u ORDER
                            if (this.IsSeparator(strToken)) {
                                break;
                            }
                            if (strToken.equals(")")) {// Par�ntesis final
                                if (nOpenPar == 0) {// No hay ning�n par�ntesis de JOIN abierto
                                    if (--nInitPar < 0) {
                                        return false; // Error
                                    }
                                }// No hay ning�n par�ntesis de JOIN abierto
                                else {// Descontar un par�ntesis.
                                    strJoin = "";
                                    nOpenPar--;
                                    break;
                                }// Descontar un par�ntesis.
                            }// Par�ntesis final
                            if (strToken.equalsIgnoreCase("left") || strToken.equalsIgnoreCase("right") || strToken.equalsIgnoreCase("inner")) {// Pasar al siguiente
                                nTableStep = 1;
                                break;
                            }// Pasar al siguiente
                            //
                            // Ver si viene WHERE
                            if (strToken.equalsIgnoreCase("where")) {// Vendr� un WHERE
                                nOpenPar = 0;
                                nStep = 3;
                                break;
                            }// Vendr� un WHERE
                            // F11120702: No se reconoce GROUP en una parte de ParseSelectStatement.
                            // Aqu� tambi�n poder�a de venir un GROUP BY
                            if (strToken.equalsIgnoreCase("group")) {// Puede que venga la agrupaci�n directamente
                                nOpenPar = 0;
                                nStep = 7;
                                break;
                            }// Puede que venga la agrupaci�n directamente
                            // Ver si es una uni�n
                            if (strToken.equalsIgnoreCase("union")) {// Si hay par�ntesis abiertos error
                                if (nOpenPar > 0) {
                                    return false;
                                }
                                //
                                // Cambiar de estado y esperar ALL
                                nStep = 5;
                                break;
                            }// Si hay par�ntesis abiertos error
                            //
                            // Puede ser que llegue un ORDER BY en este estado...
                            if (strToken.equalsIgnoreCase("order")) {// No puede haber par�ntesis abiertos tampoco
                                if (nOpenPar > 0) {
                                    return false;
                                }
                                //
                                // Cambiar el estado y esperar BY
                                nStep = 4;
                                break;
                            }// No puede haber par�ntesis abiertos tampoco
                            break;
                    }
                    break;
                case 3:
                    //
                    // Esperando un WHERE
                    if (strToken.equalsIgnoreCase("order")) {// Ordenamiento
                        nStep = 4;
                        this.m_strWhere = strPrevToken.trim();
                        break;
                    }// Ordenamiento
                    //
                    // Si llega GROUP, pasar a esperar BY
                    if (strToken.equalsIgnoreCase("group")) {// Agrupaci�n
                        //
                        // Si hay par�ntesis desbalanceados, error de sintaxis
                        if (nOpenPar > 0)
                        // Error
                        {
                            return false;
                        }
                        nStep = 7;
                        this.m_strWhere = strPrevToken.trim();
                        break;
                    }// Agrupaci�n
                    // F12110902: Modificaciones al parser de SQL para gestionar uniones.
                    // Aqu� podr�a venir una uni�n siempre que el where no est� entre par�ntesis
                    if (strToken.equalsIgnoreCase("union")) {// UNION
                        // Si hay par�ntesis abiertos, m�lido
                        if (nOpenPar > 0) {
                            return false;
                        }
                        this.m_strWhere = strPrevToken.trim();
                        nStep = 5;
                        break;
                    }// UNION
                    if (strToken.equals("("))
                    // Abrir par�ntesis
                    {
                        nOpenPar++;
                    }
                    //
                    // Si es un par�ntesis pudiera ser simplemente un cierre
                    if (strToken.equals(")")) {// Cierra par�ntesis
                        if (nOpenPar == 0) {// Si no hay par�ntesis abierto al inicio
                            if (--nInitPar < 0) {
                                return false;
                            }
                            //
                            // Un par�ntesis cerrado puede ser indicio de fin de query... prepararse
                            // por si las moscas
                            this.m_strWhere = strPrevToken.trim();
                            nStep = 0;
                            break;
                        }// Si no hay par�ntesis abierto al inicio
                        if (--nOpenPar == 0) {// Par�ntesis final de un WHERE
                            //
                            // Final de un WHERE entre par�ntesis, simplemente preparar la cosa
                            // porque igual el query termina aqu� m�s adelante.... no cambiar de estado
                            strPrevToken += strToken;
                            this.m_strWhere = strPrevToken.trim();
                            break;
                        }// Par�ntesis final de un WHERE
                    }// Cierra par�ntesis
                    //
                    // Lo que sea, irlo sumando para tenerlo como WHERE
                    strPrevToken += strToken;
                    break;
                case 4:
                    //
                    // Esperando el BY del ORDER BY
                    //
                    if (this.IsSeparator(strToken)) {
                        break;
                    }
                    if (strToken.equalsIgnoreCase("by")) {// Lleb� el BY, pasar a leer el ORDER
                        strPrevToken = "";
                        nStep = 9;
                        nOpenPar = 0;
                        break;
                    }// Lleb� el BY, pasar a leer el ORDER
                    //
                    // Error
                    return false;
                case 5:
                    //
                    // Esperando el ALL
                    if (this.IsSeparator(strToken)) {
                        break;
                    }
                    if (strToken.equalsIgnoreCase("all")) {// OK
                        nStep = 6;
                        break;
                    }// OK
                    // F12110902: Modificaciones al parser de SQL para gestionar uniones.
                    // No tiene por qu� ser union all...
                    if (strToken.equalsIgnoreCase("select") || strToken.equals("(")) {// Asumir que nos hemos saltado un ALL
                        this.AddUnionQuery();
                        nOpenPar = 0;
                        nStep = 1;
                        // Limpiar tuti
                        strPrevToken = strField = strAlias = strJoin = strTable = "";
                        break;
                    }// Asumir que nos hemos saltado un ALL
                    // Error
                    return false;
                case 6:
                    //
                    // Apreparar este SELECT como una uni�n y copiar todos los datos
                    // de este hacia uno en lista.
                    this.AddUnionQuery();
                    nStep = nOpenPar = 0;
                    // F12110902: Modificaciones al parser de SQL para gestionar uniones.
                    // Limpiar tuti
                    strPrevToken = strField = strAlias = strJoin = strTable = "";
                    break;
                case 7:
                    //
                    // Esperando el BY del GROUP
                    if (this.IsSeparator(strToken)) {
                        break;
                    }
                    if (strToken.equalsIgnoreCase("by")) {// OK
                        strPrevToken = "";
                        nOpenPar = 0;
                        nStep = 8;
                        break;
                    }// OK
                    //
                    // Error
                    return false;
                case 8:
                    //
                    // Si llega HAVING saltar el estado de espera de las condiciones de HAVING
                    if (strToken.equalsIgnoreCase("having")) {// Completar lo que haya hasta aqu�
                        if (nOpenPar > 0) {
                            return false;
                        }
                        nStep = 10;
                        this.m_strGroupBy = strPrevToken.trim();
                        nOpenPar = 0;
                        strPrevToken = "";
                        break;
                    }// Completar lo que haya hasta aqu�
                    //
                    // En este estado, todo lo que llegue ser� para la agrupaci�n
                    if (strToken.equalsIgnoreCase("order")) {// Puede venir ordenamiento
                        if (nOpenPar > 0) {
                            return false;
                        }
                        nStep = 4;
                        this.m_strGroupBy = strPrevToken.trim();
                        break;
                    }// Puede venir ordenamiento
                    //
                    if (strToken.equals("(")) {
                        nOpenPar++;
                    }
                    //
                    // Si cierra par�ntesis puede ser final de agrupaci�n
                    if (strToken.equals(")")) {// Ver qu� es lo que cierra
                        if (nOpenPar == 0) {// Si no hay par�ntesis abierto al inicio
                            if (--nInitPar < 0) {
                                return false;
                            }
                            //
                            // Un par�ntesis cerrado puede ser indicio de fin de query... prepararse
                            // por si las moscas
                            this.m_strGroupBy = strPrevToken.trim();
                            nStep = 0;
                            break;
                        }// Si no hay par�ntesis abierto al inicio
                        if (--nOpenPar == 0) {// Par�ntesis final de un GROUP BY
                            //
                            // Final de un GROUP BY entre par�ntesis, simplemente preparar la cosa
                            // porque igual el query termina aqu� m�s adelante.... no cambiar de estado
                            strPrevToken += strToken;
                            this.m_strGroupBy = strPrevToken.trim();
                            break;
                        }// Par�ntesis final de un GROUP BY
                    }// Ver qu� es lo que cierra
                    //
                    // Agregar para tener la expresi�n completa aqu�
                    strPrevToken += strToken;
                    break;
                case 9:
                    //
                    // Leyendo los valores que componen un ORDER BY
                    //
                    // En este estado, todo lo que llegue ser� para el ORDER BY
                    if (strToken.equalsIgnoreCase("having")) {// Puede venir ordenamiento
                        if (nOpenPar > 0) {
                            return false;
                        }
                        nStep = 10;
                        this.m_strOrderBy = strPrevToken.trim();
                        nOpenPar = 0;
                        strPrevToken = "";
                        break;
                    }// Puede venir ordenamiento
                    if (strToken.equals("("))
                    // Contarlo
                    {
                        nOpenPar++;
                    }
                    if (strToken.equals(")")) {// Cerrar par�ntesis
                        if (nOpenPar == 0) {// Si no hay par�ntesis abierto al inicio
                            if (--nInitPar < 0) {
                                return false;
                            }
                            //
                            // Un par�ntesis cerrado puede ser indicio de fin de query... prepararse
                            // por si las moscas
                            this.m_strOrderBy = strPrevToken.trim();
                            nStep = 0;
                            break;
                        }// Si no hay par�ntesis abierto al inicio
                        if (--nOpenPar == 0) {// Par�ntesis final de un ORDER BY
                            //
                            // Final de un ORDER BY entre par�ntesis, simplemente preparar la cosa
                            // porque igual el query termina aqu� m�s adelante.... no cambiar de estado
                            strPrevToken += strToken;
                            this.m_strOrderBy = strPrevToken.trim();
                            break;
                        }// Par�ntesis final de un ORDER BY
                    }// Cerrar par�ntesis
                    //
                    // Agregar para tener la expresi�n completa aqu�
                    strPrevToken += strToken;
                    break;
                case 10:
                    //
                    // Para componer la expresi�n del HAVING, parecido al WHERE
                    //
                    //
                    if (strToken.equals("(")) {
                        nOpenPar++;
                    }
                    //
                    // Si cierra par�ntesis puede ser final de agrupaci�n
                    if (strToken.equals(")")) {// Ver qu� es lo que cierra
                        if (nOpenPar == 0) {// Si no hay par�ntesis abierto al inicio
                            if (--nInitPar < 0) {
                                return false;
                            }
                            //
                            // Un par�ntesis cerrado puede ser indicio de fin de query... prepararse
                            // por si las moscas
                            this.m_strHaving = strPrevToken.trim();
                            nStep = 0;
                            break;
                        }// Si no hay par�ntesis abierto al inicio
                        if (--nOpenPar == 0) {// Par�ntesis final de un GROUP BY
                            //
                            // Final de un GROUP BY entre par�ntesis, simplemente preparar la cosa
                            // porque igual el query termina aqu� m�s adelante.... no cambiar de estado
                            strPrevToken += strToken;
                            this.m_strHaving = strPrevToken.trim();
                            break;
                        }// Par�ntesis final de un GROUP BY
                    }// Ver qu� es lo que cierra
                    //
                    // Puede venir ORDER BY
                    if (strToken.equalsIgnoreCase("order")) {// ORDER BY
                        if (nOpenPar > 0) {
                            return false;
                        }
                        this.m_strHaving = strPrevToken.trim();
                        strPrevToken = "";
                        nStep = 4;
                        break;
                    }// ORDER BY
                    //
                    // Agregar para tener la expresi�n completa aqu�
                    strPrevToken += strToken;
                    break;
            }// M�quina de estados
            /////nIndex = nTokIdxTo + 1;
        }// Ir obteniendo los tokens
        //
        // Aqu� ver los residuos :-P
        if (bTable) {// Queda una tabla por agregar
            // K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a.
            this.AddTable(strTable, strAlias, strJoin, jt);
        }// Queda una tabla por agregar
        //
        // Si queda un WHERE a medias
        if (nStep == 3 && strPrevToken.length > 0) {
            this.m_strWhere = strPrevToken;
        }
        // Ajustar las cadenas
        if (!TextUtils.isEmpty(this.m_strWhere)) {
            this.m_strWhere = this.m_strWhere.trim();
        }
        // Lo mismo con el GROUP BY
        if (nStep == 8 && strPrevToken.length > 0) {
            this.m_strGroupBy = strPrevToken;
        }
        // Ajustar un poco
        if (!TextUtils.isEmpty(this.m_strGroupBy)) {
            this.m_strGroupBy = this.m_strGroupBy.trim();
        }
        // Lo mismo para el ORDER BY
        if (nStep == 9 && strPrevToken.length > 0) {
            this.m_strOrderBy = strPrevToken;
        }
        // Ajustar un poco
        if (!TextUtils.isEmpty(this.m_strOrderBy)) {
            this.m_strOrderBy = this.m_strOrderBy.trim();
        }
        // Lo mismo para el HAVING
        if (nStep == 10 && strPrevToken.length > 0) {
            this.m_strHaving = strPrevToken;
        }
        // Ajustar un poco
        if (!TextUtils.isEmpty(this.m_strHaving)) {
            this.m_strHaving = this.m_strHaving.trim();
        }
        //
        // Si esta es una uni�n, poner tambi�n este query en la uni�n y dejarlo vac�o
        // de forma que solo queden queries en la lista de uni�n
        if (this.m_bIsUnionQuery && this.m_lstSelectFields.length > 0) {
            this.AddUnionQuery();
        }
        //
        // Supuestamente deber�a tenerlo todo aqu�..
        this.m_sqlType = SqlType.SQLTYPE_SELECT;
        return true;
    }

    /**
     * Adiciona un query ya parseado a una uni�n. Los queries parseados se
     * adicionan a una lista por su orden
     */
    private AddUnionQuery(): boolean {
        // Si no existe la lista la creamos
        if (this.m_lstUnionQueries == null) {
            this.m_lstUnionQueries = new Array<SqlParser>();
        }
        // Crear un query nuevo, adicionarle los datos de este y ponerlo en lista
        let newQuery = new SqlParser(this);
        // F12110902: Modificaciones al parser de SQL para gestionar uniones.
        // Si estamos parseando un select, esto ser� un select
        newQuery.SetSqlType(SqlType.SQLTYPE_SELECT);
        if (!newQuery.CopyData(this)) {
            return false;
        }
        this.m_lstUnionQueries.push(newQuery);
        this.Clear(false);
        this.m_bIsUnionQuery = true;
        return true;
    }

    /**
     * @param Token Elemento que se quiere comprobar.
     * @return Devuelve TRUE si el par�metro que se pasa es un separador en una
     * sentencia SELECT.
     */
    // private boolean IsSeparator(String Token) {
    //     if (Token.equals("\r") || Token.equals("\n") || Token.equals("\t") || Token.equals(" ") || Token.equals(",")) {
    //         return true;
    //     }
    //     return false;
    // }

    /**
     * @return Devuelve el siguiente token de una sentencia SELECT
     */
    private GetNextSqlToken(): string {
        let bTextStart = false;
        let i = 0, nTokIdxTo = 0, nLen = this.m_strSentence.length;
        let strToken: string = null;

        for (i = this.m_nParserIndex; i < nLen;) {// Ir revisando
            switch (this.m_strSentence.charAt(i)) {
                case '=':
                case '+':
                case '-':
                case '*':
                case '/':
                    if (!bTextStart) {// Solo si no est� en un texto
                        if (i > nLen) {
                            nTokIdxTo = i - 1; // El operador indica fin del anterior
                        } else {
                            nTokIdxTo = i; // El operador es en s� un token
                        }
                        if (nTokIdxTo == this.m_nParserIndex) {
                            nTokIdxTo++;
                        }
                        // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                        strToken = this.m_strSentence.substring(this.m_nParserIndex, nTokIdxTo/*
                         * -
                         * m_nParserIndex
                         */);
                        this.m_nParserIndex = nTokIdxTo;
                        return strToken;
                    }// Solo si no est� en un texto
                    else {
                        i++;
                    }
                    break;
                case '\'':
                    if (!bTextStart) {// Ver si es que empieza una cadena entrecomillada
                        // F11041402: Problemas en el parser de sentencias SELECT con expresiones complejas.
                        // Si tiene texto antes devolverlo
                        if (i > this.m_nParserIndex) {// Tiene texto
                            nTokIdxTo = i;
                            strToken = this.m_strSentence.substring(this.m_nParserIndex, nTokIdxTo/*
                             * -
                             * m_nParserIndex
                             */);
                            this.m_nParserIndex = nTokIdxTo;
                            return strToken;
                        }// Tiene texto
                        //return null;
                        bTextStart = true;
                    }// Ver si es que empieza una cadena entrecomillada
                    else {// Hab�a un texto iniciado, terminar aqui
                        if (i > nLen) {
                            nTokIdxTo = i;
                        } else {
                            nTokIdxTo = i + 1;
                        }
                        // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                        strToken = this.m_strSentence.substring(this.m_nParserIndex, nTokIdxTo/*
                         * -
                         * m_nParserIndex
                         */);
                        this.m_nParserIndex = nTokIdxTo;
                        return strToken;
                    }// Hab�a un texto iniciado, terminar aqui
                    if (!bTextStart) {// Sefin�
                        //
                        // Si ya hay un token, termina aqu�
                        if (i > this.m_nParserIndex) {
                            nTokIdxTo = i - 1;
                        } else {
                            nTokIdxTo = i;
                        }
                        // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                        strToken = this.m_strSentence.substring(this.m_nParserIndex, nTokIdxTo/*
                         * -
                         * m_nParserIndex
                         */);
                        this.m_nParserIndex = i;
                        return strToken;
                    }// Sefin�
                    i++;
                    break;
                case '\r':
                case '\n':
                case '\t':
                case ' ':
                case ',':
                case '(':
                case ')':
                    //
                    // Separadores
                    if (!bTextStart) {// Sefin�
                        //
                        // Si ya hay un token, termina aqu�
                        if (i > nLen) {
                            nTokIdxTo = i - 1;
                        } else {
                            nTokIdxTo = i;
                        }
                        if (nTokIdxTo == this.m_nParserIndex) {
                            nTokIdxTo++;
                        }
                        // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                        strToken = this.m_strSentence.substring(this.m_nParserIndex, nTokIdxTo/*
                         * -
                         * m_nParserIndex
                         */);
                        this.m_nParserIndex = nTokIdxTo;
                        return strToken;
                    }// Sefin�
                    i++;
                    break;
                //
                // Cuenta como uno m�s
                default:
                    i++;
                    break;
            }
        }// Ir revisando
        // Si estamos al final, entonces devolver lo que quede
        if (this.m_nParserIndex < i) {
            // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
            strToken = this.m_strSentence.substring(this.m_nParserIndex, nLen/* m_nParserIndex */);
            this.m_nParserIndex = i;
        }
        return strToken;
    }

    /**
     * Regenera una sentencia UPDATE a partir de la lista de campos que hay en
     * este objeto
     *
     * @return Devuelve la cadena con la sentencia regenerada.
     */

    private RegenerateUpdate(): string {
        let strValues = "";
        let strSql: string;
        //
        // Si no hay campos, nada que hacer...
        if (this.m_lstFields.length == 0) {
            return null;
        }
        this.m_lstFields.entrySet().forEach(element => {
            if (!SqlParser.EMPTY.equals(element[0])) {// Tiene valor
                if (strValues.length > 0)
                // Las comas
                {
                    strValues += ",";
                }
                strValues += String.format("%s=%s", element[0], element[1]);
            }// Tiene valor
        });
        // Enumeration<String> enm = this.m_lstFields.keys();
        // while (enm.hasMoreElements()) {// Recorrer la lista

        //     strField = enm.nextElement();
        //     strValue = this.m_lstFields.get(strField);
        //     if (!SqlParser.EMPTY.equals(strField)) {// Tiene valor
        //         if (strValues.length > 0)
        //         // Las comas
        //         {
        //             strValues += ",";
        //         }
        //         strValues += String.format("%s=%s", strField, strValue);
        //     }// Tiene valor
        // }// Recorrer la lista
        if (strValues.length == 0) {
            return null;
        }
        strSql = String.format("UPDATE %s SET %s %s", this.m_strTable, strValues, this.m_strWhere);
        return strSql;
    }

    /// Regenera el SQL y lo devuelve

    public RegenerateSql(): string {
        //
        // En funci�n del tipo que sea, tendremos que generar diferentes tipos
        // de sentencia SQL...
        switch (this.m_sqlType) {
            case SqlType.SQLTYPE_INSERT:
                return this.RegenerateInsert();
            case SqlType.SQLTYPE_DELETE:
                return this.RegenerateDelete();
            case SqlType.SQLTYPE_UPDATE:
                return this.RegenerateUpdate();
            case SqlType.SQLTYPE_SELECT:
                return this.RegenerateSelect();
        }
        return null;
    }

    /**
     * Regenera una sentencia SELECT a partir de los datos parseados
     *
     * @return Devuelve la cadena con la sentencia SELECT regenerada a partir de
     * esta estructura de datos.
     */

    private RegenerateSelect(): string {
        let strQuery: string;
        let strTmp: string;

        if (this.m_bIsUnionQuery) {
            return this.RegenerateUnionQuery();
        }
        //
        // De lo contrario genera como siempre
        if (this.m_tableFrom == null) {
            return null;
        }
        //
        // Obtener el query preliminar
        // M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
        // Indicarle a la tabla si tiene que traducir los joins o no...
        this.m_tableFrom.setTranslateJoins(this.m_bTranslateJoins);

        strQuery = "SELECT " + (this.m_bDistinct ? "DISTINCT " : "") + this.GetFieldList() + " FROM " + this.m_tableFrom.getTableName();
        if (this.m_tableFrom.getSecondTable() == null) {// Adicionar el alias
            strTmp = " " + this.m_tableFrom.getAlias();
            strQuery += strTmp;
        }// Adicionar el alias
        //
        // Quitar lo que sobra
        strQuery = strQuery.trim();
        for (; strQuery.endsWith("\t"); strQuery = strQuery.substring(0, strQuery.length - 1)) {
        }
        for (; strQuery.endsWith("\r\n"); strQuery = strQuery.substring(0, strQuery.length - 2)) {
        }
        //
        // Si hay WHERE, ponerlo despu�s de lo anterior
        if (!TextUtils.isEmpty(this.m_strWhere)) {// Colocarlo al finalillo
            strTmp = " WHERE (" + this.m_strWhere + ")";
            strQuery += strTmp;
        }// Colocarlo al finalillo
        // M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
        // Si tenemos que traducir los joins entonces habr� que agregar sus condiciones aqu�
        if (this.m_bTranslateJoins) {// Pedirle la cadena de condiciones a la tabla
            strTmp = this.m_tableFrom.getTranslatedJoinConditions();
            if (!TextUtils.isEmpty(strTmp)) {// Si ya tiene WHERE ponerlo
                if (TextUtils.isEmpty(this.m_strWhere)) {
                    strTmp = " WHERE (" + strTmp + ")";
                } else {
                    strTmp = (" AND (" + strTmp + ")");
                }
            }// Si ya tiene WHERE ponerlo
            strQuery += strTmp;
        }// Pedirle la cadena de condiciones a la tabla
        //
        // Si hay GROUP BY, ponerlo despu�s del WHERE
        if (!TextUtils.isEmpty(this.m_strGroupBy)) {// Agregar el pedazo que falta
            strTmp = " GROUP BY " + this.m_strGroupBy;
            strQuery += strTmp;
        }// Agregar el pedazo que falta
        //
        // Si tiene HAVING
        if (!TextUtils.isEmpty(this.m_strHaving)) {// Agregar el HAVING
            strTmp = " HAVING " + this.m_strHaving;
            strQuery += strTmp;
        }// Agregar el HAVING
        //
        // Si hay ORDER BY se pone aqu�
        if (!TextUtils.isEmpty(this.m_strOrderBy)) {// Agregar el ordenamiento
            strTmp = " ORDER BY " + this.m_strOrderBy;
            strQuery += strTmp;
        }// Agregar el ordenamiento
        // Si tiene LIMIT, ponerlo
        return strQuery;
    }

    /**
     * @return Devuelve una cadena formateada con los campos que contiene la
     * lista parseada.
     */
    private GetFieldList(): string {
        let str = new StringBuilder();
        this.m_lstSelectFields.forEach(fld => {
            !str.isEmpty() && str.append(',');
            str.append(fld.getName());
            !TextUtils.isEmpty(fld.getAlias()) && str.append(" AS " + fld.getAlias());
        });
        // Luis: Esto es lo mismo que arriba pero compactado en javascript
        // String str = "", strTmp;
        // this.int i = 0, nCount = this.m_lstSelectFields.size();

        // for (this.int j = 0; j < this.m_lstSelectFields.size(); j++) {// Recorrer la lista de campos
        //     QueryField fld = this.m_lstSelectFields.elementAt(j);
        //     str += fld.getName();
        //     if (!TextUtils.isEmpty(fld.getAlias())) {// Adicionar cositas
        //         strTmp = " AS " + fld.getAlias();
        //         str += strTmp;
        //     }// Adicionar cositas
        //     if (i < nCount - 1) {
        //         str += ",";
        //     }
        //     //////if (0 ==((++n) % 5) && n < nCount - 1)
        //     // Adicionar unos cambios de l�nea
        //     //////str += "\r\n\t\t";
        //     // Hala... contemos :-P
        //     i++;
        // }// Recorrer la lista de campos
        // Completo
        return str.length() === 0 ? '*' : str.toString();
    }

    /**
     * Regenera una sentencia SELECT de uni�n a partir de la lista de queries
     * que hay en este parser
     *
     * @return Devuelve la sentencia SELECT regenerada.
     */
    private RegenerateUnionQuery(): string {
        let strQuery = new StringBuilder();
        this.m_lstUnionQueries.forEach(query => {
            strQuery.isEmpty() && strQuery.append(" \r\nUNION ALL\r\n ");
            this.m_bSurroundUnions && strQuery.append("(");
            strQuery.append(query.RegenerateSql());
            this.m_bSurroundUnions && strQuery.append(")");
        });
        !TextUtils.isEmpty(this.m_strOrderBy) && strQuery.append("\r\nORDER BY " + this.m_strOrderBy);
        // Luis: Esto es lo mismo que arriba pero compactado en javascript
        // String strQuery = "", strTmp, strNewQuery;

        // for (this.int i = 0; i < this.m_lstUnionQueries.size(); i++) {// Cada query habr� que regenerarlo
        //     SqlParser query = this.m_lstUnionQueries.elementAt(i);
        //     strNewQuery = query.RegenerateSql();
        //     if (!TextUtils.isEmpty(strQuery))
        //     // Adicionar el UNION ALL
        //     {
        //         strQuery += " \r\nUNION ALL\r\n ";
        //     }
        //     // F12110902: Modificaciones al parser de SQL para gestionar uniones.
        //     // Tendremos par�ntesis... o no...
        //     strTmp = this.m_bSurroundUnions ? "(" : Utils.EMPTY_STRING;
        //     strTmp += strNewQuery;
        //     strTmp += this.m_bSurroundUnions ? ")" : Utils.EMPTY_STRING;
        //     strQuery += strTmp;
        // }// Cada query habr� que regenerarlo
        //
        // Si al final tiene ORDER BY, ponerlo aqu�
        // if (!TextUtils.isEmpty(this.m_strOrderBy)) {// Componer el ordenamiento
        //     strTmp = "\r\nORDER BY " + this.m_strOrderBy;
        //     strQuery += strTmp;
        // }// Componer el ordenamiento

        return strQuery.toString();
    }

    /**
     * Asigna valor a un campo de este parser.
     *
     * @param FieldName  Nombre del campo cuyo valor se quiere modificar.
     * @param FieldValue Valor que se quiere asignar al campo. Ojo con los separadores
     *                   y delimitadores que tienen que venir incluidos en el valor.
     */
    public SetFieldValue(FieldName: string, FieldValue: string) {
        if (this.m_lstFields == null) {
            this.m_lstFields = new Hashtable<string, string>();
        }
        this.m_lstFields.put(FieldName, FieldValue);
    }

    /**
     * A12042602: Permitir que se puedan almacenar los tipos de datos de una
     * sentencia SQL.
     *
     * @param FieldName Nombre del campo cuyo tipo se quiere asignar
     * @param FieldType Tipo de dato.
     */
    public SetFieldType(FieldName: string, FieldType: number) {
        if (this.m_lstFieldsType == null) {
            this.m_lstFieldsType = new Hashtable<string, number>();
        }
        this.m_lstFieldsType.put(FieldName, FieldType);
    }

    /**
     * A12042602: Permitir que se puedan almacenar los tipos de datos de una
     * sentencia SQL.
     *
     * @param FieldName Nombre del campo cuyo tipo se quiere obtener.
     * @return
     */
    public GetFieldType(FieldName: string): number {
        if (this.m_lstFieldsType == null) {
            return 0;
        }
        if (!this.m_lstFieldsType.containsKey(FieldName)) {
            return 0;
        }
        return this.m_lstFieldsType.get(FieldName);
    }

    /**
     * Elimina los campos de esta sentencia que est�n contenidos en el que se
     * pasa como par�metro.
     *
     * @param Source      Parser cuyos campos se analizan para buscar coincidencias.
     * @param PriorityMap Ignorado. Reservado para versiones futuras.
     * @return Devuelve la cantidad de campos que quedan despu�s de eliminar los
     * que coinciden.
     * @throws ReplicationException
     */
    public StripSqlFields(Source: SqlParser, PriorityMap: number): number {
        // let strField="", strValue="", strValueTo="";
        // let bFormulaTo=false, bFormulaFrom=false, bStrip=false;
        let nRemaining = Source.GetFields().length;

        try {
            this.m_lstFields.entrySet().forEach(element => {
                if (SqlParser.IsFormula(element[0]) && SqlParser.IsFormula(this.GetFieldValue(element[0]))) {// Ponerle el mismo valor que el original
                    if (this.m_sqlType == SqlType.SQLTYPE_INSERT) {
                        this.SetFieldValue(element[0], element[1]);
                    } else {
                        this.m_lstFields.delete(element[0]);
                    }
                    nRemaining--;
                }// Ponerle el mismo valor que el original
            });
            // Luis: Esto es lo mismo que arriba pero compactado en javascript
            // Enumeration<String> enm = this.m_lstFields.keys();
            // while (enm.hasMoreElements()) {// Recorrer la lista

            //     strField = enm.nextElement();
            //     strValue = m_lstFields.get(strField);
            //     if (m_lstFields.containsKey(strField)) {// Ahora ver si es una formulilla
            //         bStrip = true;
            //         strValueTo = GetFieldValue(strField);
            //         bFormulaFrom = IsFormula(strValue);
            //         bFormulaTo = IsFormula(strValueTo);
            //         /*
            //          * Si tanto el valor de origen como el de destino son
            //          * f�rmulas entonces las dos prevalecer�n porque se
            //          * aplicar�n de forma acumulativa. En realidad este
            //          * comportamiento no debe ser tomado exactamente as�, pero
            //          * por el momento debe resolver los problemas
            //          */
            //         if (bFormulaFrom && bFormulaTo) {
            //             bStrip = false;
            //         }
            //         //
            //         if (bStrip) {// Ponerle el mismo valor que el original
            //             if (m_sqlType == SqlType.SQLTYPE_INSERT) {
            //                 SetFieldValue(strField, strValue);
            //             } else {
            //                 m_lstFields.remove(strField);
            //             }
            //             nRemaining--;
            //         }// Ponerle el mismo valor que el original
            //     }// Ahora ver si es una formulilla
            // }// Revisa cada campo del SQL origen
            //
            // Puede darse el caso especial que se trate de un INSERT y que solo le quede el ROWID
            // En ese caso habr� que quitarle uno
            if ((this.m_sqlType == SqlType.SQLTYPE_INSERT && Source.GetSqlType() == SqlType.SQLTYPE_UPDATE) && (this.GetRowId().compareTo(Source.GetRowId()) == 0)) {
                nRemaining--;
            }

            return nRemaining;
        } catch (e) {
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y dem�s cosas.
            ////throw new ReplicationException(ReplicationErrorCodes.RPLR_DATA_ERROR, e.getMessage(), "Error eliminando campos de la operaci�n.");
            throw e;
            // new ReplicationException(
            //         ReplicationErrorCodes.RPLR_DATA_ERROR,
            //         e.getMessage(),
            //         GetMessage(XoneMessageKeys.SYS_MSG_SQL_STRIPFIELDERROR, "Error stripping operation fields."));
        }
    }

    /**
     * Copia datos de una sentencia parseada hacia esta sentencia.
     *
     * @param Source Sentencia desde la que se quieren copiar los datos.
     * @return Devuelve TRUE si los datos se copian correctamente.
     */
    public CopyData(Source: SqlParser) {
        let table: QueryTable;

        this.Clear();
        Source.getQueryFields().forEach(field => {
            this.AddField(field.getName(), field.getAlias());
        });
        // // Comprobar si hay que copiar
        // for (int i = 0; i < Source.getQueryFields().size(); i++) {// Copia cada campo
        //     QueryField field = Source.getQueryFields().elementAt(i);
        //     // Copiar
        //     this.AddField(field.getName(), field.getAlias());
        // }// Copia cada campo
        // Ahora copia cada tabla con sus correspondientes tablas encadenadas y cosas as�
        table = Source.getTableFrom();
        /*
         * TODO ADD TAG Juan Carlos
         * Esto puede venir a null, por ejemplo desde una de las sobrecargas de EmbedFilters(), y
         * puede significar que se le ha olvidado a alguien poner el FROM. Me gustar�a lanzar una
         * excepci�n detallada, pero aqu� las cosas s�lo retornan booleano...
         */
        if (table == null) {
            return false;
        }
        this.CopyTable(table);
        // Indicar si es DISTINCT o no
        this.m_bDistinct = Source.getDistinct();
        // Copiar otras cosas que hagan falta para este query, como WHERES, GROUPS, ORDERS y HAVINGS
        // que tambi�n forman parte del query
        this.m_strWhere = Source.GetWhereSentence();
        this.m_strGroupBy = Source.getGroupBySentence();
        this.m_strOrderBy = Source.getOrderBySentence();
        this.m_strHaving = Source.getHavingSentence();
        //////m_strTop = Source.TopSentence;
        //////m_strLimit = Source.LimitSentence;
        return true;
    }

    /**
     * TRUE si se trata de un SELECT que tiene DISTINCT
     *
     * @return Devuelve la bandera que indica si la sentencia SELECT tiene
     * DISTINCT o no.
     */
    public getDistinct(): boolean {
        return this.m_bDistinct;
    }

    /**
     * Expone la sentencia ORDER BY de un SELECT.
     *
     * @return Devuelve la sentencia ORDER BY de esta sentencia SQL.
     */
    public getOrderBySentence(): string {
        return this.m_strOrderBy || null;
    }

    /**
     * Asigna valor a la sentencia ORDER BY de esta sentencia SQL.
     *
     * @param value Nuevo valor de la sentencia ORDER BY de este SELECT.
     */
    public setOrderBySentence(value: string): void {
        this.m_strOrderBy = value;
    }

    /**
     * Expone la sentencia GROUP BY de esta sentencia SQL.
     *
     * @return Devuelve el valor de la sentencia GROUP BY de este SQL.
     */
    public getGroupBySentence(): string {
        return this.m_strGroupBy;
    }

    /**
     * Asigna valor a la sentencia GROUP BY de esta sentencia SQL.
     *
     * @param value Nuevo valor de la sentencia GROUP BY de este SQL.
     */
    public setGroupBySentence(value: string): void {
        this.m_strGroupBy = value;
    }

    /**
     * Copia los datos de una tabla parseada hacia esta sentencia. Si hay tablas
     * enlazadas tambi�n las copia.
     *
     * @param Table Tabla o cadena de ellas que se quiere copiar hacia esta
     *              sentencia
     * @return Devuelve TRUE si la copia de los datos de la tabla es correcta.
     */
    private CopyTable(Table: QueryTable): boolean {
        let next: QueryTable;
        // Copiar primero la segunda...
        if (null != (next = Table.getSecondTable())) {
            this.CopyTable(next);
        }
        // Ahora copiar esta
        // K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a.
        // Copiar con el tipo...
        this.AddTable(Table.getActualName(), Table.getAlias(), Table.getJoinCondition(), Table.getJoinType());
        return true;
    }

    /**
     * Adiciona una tabla procedente de una sentencia SELECT que se est�
     * parseando.
     * <p>
     * K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a. Un
     * par�metro para indicar qu� tipo de join es...
     *
     * @param TableName     Nombre de la tabla en la base de datos.
     * @param TableAlias    Alias de la tabla en la sentencia SELECT.
     * @param JoinCondition Condici�n de uni�n entre esta tabla y la que le precede.
     */
    private AddTable(TableName: string, TableAlias: string, JoinCondition: string, JoinType: eJoinType) {
        let tbl: QueryTable;
        let strName = TableName;
        //
        // Si lo primero que trae es un prefijo, quit�rselo
        // A12042501: Si la tabla tiene el prefijo como parte del parsing tiene que mantenerlo.
        // El prefijo tiene que ir en plan tabla a tabla...
        let bPrefix = false;
        if (strName.startsWith("##PREF##")) {// Sacar el valor de UsePrefix de aqu�
            // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
            strName = strName.substring(8, strName.length);
            this.m_bUsePrefix = bPrefix = true;
        }// Sacar el valor de UsePrefix de aqu�
        // Agregar la tabla a la lista
        tbl = new QueryTable(strName.trim(), TableAlias.trim());
        tbl.setSecondTable(this.m_tableFrom);
        // K11090501: El parser de SQL no almacena el tipo de JOIN. Deber�a.
        // Pasar el join...
        tbl.setJoinCondition(JoinCondition.trim(), JoinType);
        // A12042501: Si la tabla tiene el prefijo como parte del parsing tiene que mantenerlo.
        // El prefijo tabla a tabla solo si lo lleva
        tbl.setUsePrefix(bPrefix);
        this.m_tableFrom = tbl;
    }

    /**
     * Expone la lista de tablas de la consulta
     *
     * @return Devuelve una tabla de SELECT con la lista o tabla �nica
     */
    public getTableFrom(): QueryTable {
        return this.m_tableFrom;
    }

    /**
     * TRUE si hay que incluir el prefijo a la hora de reconstruir las
     * sentencias SQL
     *
     * @return Devuelve la bandera que indica si hay que usar prefijo para
     * reconstruir la sentencia SELECT.
     */
    public getUsePrefix(): boolean {
        return this.m_bUsePrefix;
    }

    /**
     * Asigna valor a la bandera que indica si hay que usar prefijo para
     * recomponer la sentencia SQL.
     *
     * @param value TRUE si hay que incluir el prefijo al reconstruir la sentencia
     *              SQL.
     */
    public setUsePrefix(value: boolean): void {
        this.m_bUsePrefix = value;
    }

    /**
     * Sentencia HAVING del SELECT si se trata de un GROUP BY filtrado despu�s
     *
     * @return Devuelve la sentencia HAVING del SELECT.
     */
    public getHavingSentence(): string {
        return this.m_strHaving;
    }

    /**
     * Asigna valor a la sentencia HAVING de esta sentencia SELECT.
     *
     * @param value Nuevo valor de la sentencia HAVING.
     */
    public setHavingSentence(value: string) {
        this.m_strHaving = value;
    }

    /**
     * Limpia las estructuras de datos usadas para parsear sentencias SELECT.
     *
     * @param ClearUnions TRUE si se quiere limpiar tambi�n la lista de uniones.
     */
    private Clear(ClearUnions: boolean = true): void {
        if (this.m_tableFrom != null) {// Eliminar las tablas
            this.m_tableFrom = null;
        }// Eliminar las tablas
        if (this.m_lstSelectFields != null) {
            this.m_lstSelectFields = new Vector<QueryField>();
        }
        // Si hay queries de uni�n eliminarlos
        if (ClearUnions) {// Limpiar las uniones tambi�n
            if (this.m_lstUnionQueries != null) {
                this.m_lstUnionQueries = new Array<SqlParser>();
            }
        }// Limpiar las uniones tambi�n
        //
        this.m_bDistinct = false;
        this.m_strWhere = null;
        this.m_strGroupBy = null;
        this.m_strOrderBy = null;
        this.m_strHaving = null;
    }

    /**
     * Lista de queries que componen una uni�n
     *
     * @return Devuelve una lista con los queries que forman una uni�n.
     */
    public getUnionQueries(): Array<SqlParser> {
        return this.m_lstUnionQueries;
    }

    /**
     * Expone la lista de campos de una sentencia SELECT
     *
     * @return Devuelve la lista de campos de la sentencia SELECT.
     */
    public getQueryFields(): Vector<QueryField> {
        return this.m_lstSelectFields;
    }

    /**
     * Expone la lista de campos de una sentencia SELECT
     *
     * @return Devuelve la lista de campos de la sentencia SELECT.
     */
    public clearQueryFields(): void {
        this.m_lstSelectFields = new Vector<QueryField>();
    }

    /**
     * Adiciona un campo de sentencia SELECT a la lista de campos durante el
     * parseo de una sentencia SELECT o durante la copia de un query original.
     *
     * @param FieldName  Nombre del campo en la base de datos.
     * @param FieldAlias Alias del campo en la sentencia SELECT.
     * @return Devuelve el nuevo campo reci�n adicionado o el que ya exista, si
     * no es nuevo.
     */
    public AddField(FieldName: string, FieldAlias: string): QueryField {
        let field: QueryField;

        for (let i = 0; i < this.m_lstSelectFields.length; i++) {// Si ya existe, mu mal mu mal
            let f = this.m_lstSelectFields[i];
            if (f.getName().equals(FieldName) && f.getAlias().equals(FieldAlias)) {
                return f;
            }
        }// Si ya existe, mu mal mu mal
        // Si no existe, lo creamos
        field = new QueryField(FieldName, FieldAlias);
        this.m_lstSelectFields.push(field);
        return field;
    }

    /**
     * M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas. Devuelve
     * un mensaje dada su clave.
     *
     * @param Key     Clave del mensaje.
     * @param Default Mensaje por defecto por si no existe esta clave o no hay
     *                holder.
     */
    private GetMessage(Key: string, Default: string): string {
        if (this.m_messages == null) {
            return Default;
        }
        return this.m_messages.GetMessage(Key, Default);
    }

    private m_keys: string[];

    public getKeys(): string[] {
        return this.m_keys;
    }

    public addkey(value: string): void {
        if (this.m_keys == null)
            this.m_keys = [];
        this.m_keys.push(value);
    }

    public clearKeys(): void {
        this.m_keys = [];
    }
    /**
     * 20/02/2014 Luis: Si el SQL contiene fórmulas o funciones debemos ejecutar directamente el SQL
     * 09012014 Helper para devolver los campos en formato de android
     *
     * @param fields
     * @return
     */
    // TODO: Esta es la estructura de Android, que no esta mal, pero hayq eu implementarla
    //     public static ContentValues convertFieldsToContentValues(Hashtable<String, String> fields) {
    //         if (fields == null) {
    //             return null;
    //         }
    //         ContentValues values = new ContentValues();
    //         Set<String> sAllKeys = fields.keySet();
    //         for (String sKey : sAllKeys) {
    //             String sValue = fields.get(sKey);
    // //            if (sValue == null || NULL_VALUE.equals(sValue)) {
    //             // 04/04/2019 Juan Carlos: El valor null puede venir en minúsculas
    //             if (sValue == null || NULL_VALUE.equalsIgnoreCase(sValue)) {
    //                 values.putNull(sKey);
    //                 continue;
    //             }
    //             if (checkIfNotFunctionValue(sValue)) {
    //                 sValue = Utils.cleanStringValue(sValue).replace("''", "'");
    //                 values.put(sKey, sValue);
    //                 continue;
    //             }
    //             return null;
    //         }
    //         return values;
    //     }

    // TODO ADD TAG: LUIS En debug desde ? En release desde 20/02/2014 Si el SQL contiene formulas o funciones debemos ejecutar directamente el SQL
    public static checkIfNotFunctionValue(value: string): boolean {
        if (value.startsWith("'") && value.endsWith("'")) {
            return true;
        }
        try {
            Number.parseFloat(value);
            return true;
        } catch (e) {
            return false;
        }
    }

    /*
     * Helper para quitar el where
     */
    public static getWhereOnlyFields(where: string): string {
        if (TextUtils.isEmpty(where)) {
            return "";
        }

        let clean = where.trim();
        if (clean.substring(0, 6).compareToIgnoreCase("WHERE ") == 0) {
            return clean.substring(6).trim();
        }
        return where;
    }
}