import { XoneDataObject } from "../core/XoneDataObject";

export default class Vector<T> extends Array<T> {
    public removeElement(objLru: any) {
        let index=this.indexOf(objLru);
        (index>=0) && this.remove(index);
    }

    public addElement(value: any) {
        this.push(value);
    }
    
}