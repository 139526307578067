import { type } from "os";

export default class Calendar {
    setTime(d: Date) {
        this.m_date=d;
    }
    
    public static getInstance() {
		return new Calendar();
	}
    
    public static ALL_STYLES = 0;
    public static AM = 0;
    public static AM_PM = 9;
    public static APRIL = 3;
    public static AUGUST = 7;
    public static DATE = 5;
    public static DAY_OF_MONTH = 5;
    public static DAY_OF_WEEK = 7;
    public static DAY_OF_WEEK_IN_MONTH = 8;
    public static DAY_OF_YEAR = 6;
    public static DECEMBER = 11;
    public static DST_OFFSET = 16;
    public static ERA = 0;
    public static FEBRUARY = 1;
    public static FIELD_COUNT = 17;
    public static FRIDAY = 6;
    public static HOUR = 10;
    public static HOUR_OF_DAY = 11;
    public static JANUARY = 0;
    public static JULY = 6;
    public static JUNE = 5;
    public static LONG = 2;
    public static LONG_FORMAT = 2;
    public static LONG_STANDALONE = 32770;
    public static MARCH = 2;
    public static MAY = 4;
    public static MILLISECOND = 14;
    public static MINUTE = 12;
    public static MONDAY = 2;
    public static MONTH = 2;
    public static NARROW_FORMAT = 4;
    public static NARROW_STANDALONE = 32772;
    public static NOVEMBER = 10;
    public static OCTOBER = 9;
    public static PM = 1;
    public static SATURDAY = 7;
    public static SECOND = 13;
    public static SEPTEMBER = 8;
    public static SHORT = 1;
    public static SHORT_FORMAT = 1;
    public static SHORT_STANDALONE = 32769;
    public static SUNDAY = 1;
    public static THURSDAY = 5;
    public static TUESDAY = 3;
    public static UNDECIMBER = 12;
    public static WEDNESDAY = 4;
    public static WEEK_OF_MONTH = 4;
    public static WEEK_OF_YEAR = 3;
    public static YEAR = 1;
    public static ZONE_OFFSET = 15;

    private m_date: Date;

    /**
     *
     */
    constructor(date?: Date | number) {
        if (!date)
            date=new Date();
        if (typeof date == 'number') {
            let tmp=new Date();
            tmp.setTime(date);
            date=tmp;
        }
        this.m_date=date;
        
    }

    public get(Value: number): number {
        switch (Value) {
            case Calendar.DAY_OF_MONTH:
                return this.m_date.getDate();
            case Calendar.MONTH:
                return this.m_date.getMonth()+1;
            case Calendar.YEAR:
                return this.m_date.getFullYear();
        
            default:
                return 0;
        }
    }

    public getTime():Date {
        return this.m_date;
    }

    public toString(): string {
        return this.m_date.toDateString();
    }
}