const apiThunderforest = "2514d9d400ef48b8996963c689736d76";

export const getMapLayers = (L) => {
	return {
		//
		// Google
		streets: {
			type: "google",
			layer: L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
				maxZoom: 20,
				subdomains: ["mt0", "mt1", "mt2", "mt3"],
			}),
		},
		hybrid: {
			type: "google",
			layer: L.tileLayer("http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}", {
				maxZoom: 20,
				subdomains: ["mt0", "mt1", "mt2", "mt3"],
			}),
		},
		terrain: {
			type: "google",
			layer: L.tileLayer("http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}", {
				maxZoom: 20,
				subdomains: ["mt0", "mt1", "mt2", "mt3"],
			}),
		},
		satellite: {
			type: "google",
			layer: L.tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
				maxZoom: 20,
				subdomains: ["mt0", "mt1", "mt2", "mt3"],
			}),
		},
		//
		// Open Street Map
		standard: {
			type: "OSM",
			layer: L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
				maxZoom: 19,
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			}),
		},
		bright: {
			type: "OSM",
			layer: L.tileLayer("https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png", {
				maxZoom: 20,
				attribution:
					'&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
			}),
		},
		dark: {
			type: "OSM",
			layer: L.tileLayer("https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png", {
				maxZoom: 20,
				attribution:
					'&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
			}),
		},
		worldImagery: {
			type: "OSM",
			layer: L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {
				attribution:
					"Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
			}),
			noLabel: true,
		},
		humanitary: {
			type: "OSM",
			layer: L.tileLayer("https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png", {
				maxZoom: 19,
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
			}),
		},
		smooth: {
			type: "OSM",
			layer: L.tileLayer("https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png", {
				maxZoom: 20,
				attribution:
					'&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
			}),
		},
		outdoors: {
			type: "OSM",
			layer: L.tileLayer("https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png", {
				maxZoom: 20,
				attribution:
					'&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
			}),
		},
		openTopoMap: {
			type: "OSM",
			layer: L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png", {
				maxZoom: 17,
				attribution:
					'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
			}),
		},
		cyclist: {
			type: "OSM",
			layer: L.tileLayer("https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png", {
				maxZoom: 20,
				attribution:
					'<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			}),
		},
		transport: {
			type: "OSM",
			layer: L.tileLayer("https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=" + apiThunderforest, {
				maxZoom: 20,
				attribution: "",
			}),
		},
		neighbourhood: {
			type: "OSM",
			layer: L.tileLayer("https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}.png?apikey=2514d9d400ef48b8996963c689736d76", {
				maxZoom: 20,
				attribution: "",
			}),
		},
		carto: {
			type: "OSM",
			layer: L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png", {
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
				subdomains: "abcd",
				maxZoom: 19,
			}),
		},
		watercolor: {
			type: "OSM",
			layer: L.tileLayer("https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.{ext}", {
				attribution:
					'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
				subdomains: "abcd",
				minZoom: 1,
				maxZoom: 16,
				ext: "jpg",
			}),
		},
		worldTopoMap: {
			type: "OSM",
			layer: L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}", {
				attribution:
					"Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community",
			}),
		},
		terrainLabels: {
			type: "OSM",
			layer: L.tileLayer("https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-labels/{z}/{x}/{y}{r}.{ext}", {
				attribution:
					'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
				subdomains: "abcd",
				minZoom: 0,
				maxZoom: 18,
				ext: "png",
			}),
			onlyLabel: true,
		},
		darkMatterOnlyLabels: {
			type: "OSM",
			layer: L.tileLayer("https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png", {
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
				subdomains: "abcd",
				maxZoom: 19,
			}),
			onlyLabel: true,
		},
		positronOnlyLabels: {
			type: "OSM",
			layer: L.tileLayer("https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png", {
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
				subdomains: "abcd",
				maxZoom: 18,
			}),
			onlyLabel: true,
		},
		cartoDVoyagerOnlyLabels: {
			type: "OSM",
			layer: L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png", {
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
				subdomains: "abcd",
				maxZoom: 18,
			}),
			onlyLabel: true,
		},
	};
};

export const replaceLayerName = (layer) => layer.replace("none", "standard").replace("normal", "streets");

export const getDefaultMapLayer = (/** @type {import("../XoneAttributesHandler").PropAttributes} */ attributes) =>
	attributes.viewMode === "mapview" ? "streets" : "standard";

export const loadLeaflet = async () => {
	if (window.L) return;

	// Import leaflet css
	const lCssElement = document.createElement("link");
	lCssElement.setAttribute("rel", "stylesheet");
	lCssElement.setAttribute("href", "modules/leaflet/leaflet.css");
	document.head.append(lCssElement);
	// Import leaflet js
	const lJsElement = document.createElement("script");
	lJsElement.setAttribute("src", "modules/leaflet/leaflet.js");
	document.head.append(lJsElement);
	// Import routing machine css
	const lrmCssElement = document.createElement("link");
	lrmCssElement.setAttribute("rel", "stylesheet");
	lrmCssElement.setAttribute("href", "modules/leaflet/leaflet-routing-machine.css");
	document.head.append(lrmCssElement);
	// Import leaflet routing machine js
	const lrmJsElement = document.createElement("script");
	lrmJsElement.setAttribute("src", "modules/leaflet/leaflet-routing-machine.js");
	document.head.append(lrmJsElement);
	while (!window.L) await new Promise((resolve) => setTimeout(() => resolve(), 1));
	// Import marker cluster css
	const mcCssElement = document.createElement("link");
	mcCssElement.setAttribute("rel", "stylesheet");
	mcCssElement.setAttribute("href", "modules/leaflet/MarkerCluster.css");
	document.head.append(mcCssElement);
	// Import marker cluster default css
	const mcdCssElement = document.createElement("link");
	mcdCssElement.setAttribute("rel", "stylesheet");
	mcdCssElement.setAttribute("href", "modules/leaflet/MarkerCluster.Default.css");
	document.head.append(mcdCssElement);
	// Import marker cluster Js
	const mcJsElement = document.createElement("script");
	mcJsElement.setAttribute("src", "modules/leaflet/leaflet.markercluster.js");
	document.head.append(mcJsElement);
	while (!window.L.markerClusterGroup) await new Promise((resolve) => setTimeout(() => resolve(), 1));

	// set imagePath
	L.Icon.Default.imagePath = "./assets/";
};

// const apiMapTiler = "93ot4VTOe9tDF0xzLJjr";
// basic: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
// bright: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/bright/256/{z}/{x}/{y}.png?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
// outdoor: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/outdoor/256/{z}/{x}/{y}.png?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
// hybridSatellite: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
// pastel: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/pastel/256/{z}/{x}/{y}.png?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
// streets: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}.png?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
// toner: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/toner/256/{z}/{x}/{y}.png?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
// topo: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/topo/256/{z}/{x}/{y}.png?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
// topographique: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/topographique/256/{z}/{x}/{y}.png?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
// voyager: {
//   layer: L.tileLayer(
//     "https://api.maptiler.com/maps/voyager/256/{z}/{x}/{y}.png?key=" +
//       apiMapTiler,
//     {
//       maxZoom: 19,
//       attribution:
//         '&copy; MapTiler &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   ),
// },
