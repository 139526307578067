import XoneFileManager from "./XoneFileManager";
import XonePDF from "./XonePDF";
import XoneAnimation from "./XoneAnimation";
import XoneClipboard from "./XoneClipboard";
import xoneSystemSettings from "./XoneSystemSettings";
import XoneDebugTools from "./XoneDebugTools";

/**
 * createObject
 * @param {string} obj
 */
window.createObject = (obj) => {
	if (obj.toLowerCase() === "xonepdf") return new XonePDF();
	if (obj.toLowerCase() === "filemanager") return new XoneFileManager();
	if (obj.toLowerCase() === "debugtools") return new XoneDebugTools();
};

/**
 * $http
 */
window.$http = {
	/**
	 * get
	 * @param {string} url
	 * @param {Object} options
	 * @param {Function} dataFunction
	 * @param {Function} [errorFunction]
	 * @return {Promise<void>}
	 */
	get: async function (url, options, dataFunction, errorFunction = (_, msg) => console.error(msg)) {
		const fetchOptions = { headers: options?.headers || {} };
		try {
			const response = await fetch(url, {
				method: "GET",
				...fetchOptions,
			});
			const data = await response.json();
			dataFunction(data);
		} catch (ex) {
			errorFunction(500, "Error executing $http.get", ex);
		}
	},

	/**
	 * post
	 * @param {string} url
	 * @param {Object} options
	 * @param {Function} dataFunction
	 * @param {Function} [errorFunction]
	 * @return {Promise<void>}
	 */
	post: async function (url, options, dataFunction, errorFunction = (_, msg) => console.error(msg)) {
		const fetchOptions = { headers: options?.headers || {}, body: {} };
		try {
			if (options?.data) fetchOptions.body = JSON.stringify(options.data);

			const response = await fetch(url, {
				method: "POST",
				...fetchOptions,
			});
			const data = await response.json();
			dataFunction(data);
		} catch (ex) {
			errorFunction(500, "Error executing $http.post", ex);
		}
	},
};

/**
 * Clipboard
 */
window.clipboard = XoneClipboard;

/**
 * XOneOCR
 */
window.XOneOCR = class XOneOCR {
	startScan() {
		console.log("OCR Start Scan");
	}
};

/**
 * fingerprintManager
 */
window.fingerprintManager = new (class FingetprintManager {
	isHardwareAvailable() {
		return false;
	}
	hasEnrolledFingerprints() {
		return false;
	}
})();

/**
 * replica
 */
window.replica = new (class Replica {
	getRecordsRX() {
		return 0;
	}
	getRecordsTX() {
		return 0;
	}
	getRecordsPend() {
		return 0;
	}
	getTotalRecordsRX() {
		return 0;
	}
	getTotalRecordsTX() {
		return 0;
	}
	getLog() {
		return "";
	}
	startReplica() {}
	start() {}
	stopReplica() {}
	stop() {}
})();

/**
 * Animation
 */
window.Animation = XoneAnimation;

/**
 * systemSettings
 */
window.systemSettings = xoneSystemSettings;

window.createBookmark = (title, url) => browser.bookmarks.create({ title, url }).then(() => console.log("Bookmark crated"));
