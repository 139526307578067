import Hashtable from "../../Collections/HashMap/Hashtable";
import IMessageHolder from "../../Interfaces/IMessageHolder";
import TextUtils from "../../Utils/TextUtils";

export default class XoneMessageHolder implements IMessageHolder {
    /**
     * Nombre o identificador del lenguaje.
     */
    private m_strLangName: string;

    m_lstMessages: Hashtable<string, string> ;

    /**
     * @param LangName Nombre o identificador de lenguaje.
     */
    constructor(LangName:string) {
        this.m_strLangName = LangName;
        this.m_lstMessages = new Hashtable<string, string>();
    }

    /**
     * Nombre o identificador del lenguaje
     */
    getLanguage():string {
        return this.m_strLangName;
    }

    /**
     * Auxiliar para obtener el mensaje de la clave que se pasa como par�metro.
     *
     * @param Key     Clave del mensaje que se pasa como par�metro.
     * @param Default Valor por defecto si no existe el mensaje.
     * @return Devuelve la cadena con el identificador indicado o cadena vac�a si no existe.
     */
    GetMessage(Key:string, sDefault:string) {
        if (!this.m_lstMessages.containsKey(Key)) {
            return sDefault;
        }
        return this.m_lstMessages.get(Key);
    }

    /**
     * Carga los datos de mensajes de un fichero de cadenas.
     * Los mensajes se adicionan a los que ya haya, por lo que se pueden adicionar varios ficheros
     * de mensajes en la misma aplicaci�n.
     *
     * @param Source Nombre del fichero que se quiere cargar.
     */
    LoadMessageFile(Source: any): boolean {
        // Cargar el fichero
        let strLine="";
        let isr: any = null;
        try {
            // isr = new InputStreamReader(Source, "ISO8859_1");
            // BufferedReader br = new BufferedReader(isr);
            // while (null != (strLine = br.readLine())) {
            //     // Procesar la l�nea
            //     if (!this.ProcessLine(strLine)) {// Error
            //         return false;
            //     }// Error
            // }
            // Completo
            return true;
        } catch (ex) {
            ex.printStackTrace();
            return false;
        } finally {
            this.closeSafely(isr);
        }
    }

    /**
     * Pos eso. Se limpia el diccionario de mensajes.
     */
    Clear(): void {
        this.m_lstMessages.clear();
    }

    /**
     * Procesa la l�nea en formato clave=valor y la incorpora al diccionario interno.
     *
     * @param Line L�nea que se quiere procesar.
     * @return TRUE si se reconoce el formato correctamente.
     */
    ProcessLine(Line: string): boolean {
        let x = Line.indexOf('=');
        if (x < 0) {
            return false;
        }
        // Obtener los dos pedazos
        let strKey="", strValue = "";
        strKey = Line.substring(0, x);
        if (x < Line.length) {
            strValue = Line.substring(x + 1, Line.length - 1);
        }
        strKey = strKey.trim();
        if (!TextUtils.isEmpty(strValue)) {// Solo si tiene valor
            this.m_lstMessages.put(strKey, strValue);
        }// Solo si tiene valor
        return true;
    }

    closeSafely(closeable: any): void {
        if (closeable == null) {
            return;
        }
        try {
            closeable.close();
        } catch (ex) {
            ex.printStackTrace();
        }
    }
}