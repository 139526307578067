import Hashtable from "../Collections/HashMap/Hashtable";
import TextUtils from "./TextUtils";

export default class ConnectionStringUtils {

    /**
     * Parsea una cadena de conexión y devuelve un HashMap con su set de clave/valor.
     *
     * @param sConnectionString Cadena de conexión en bruto.
     * @return HashMap con los valores parseados.
     */
    public static parseConnectionString(sConnectionString:string):Hashtable<string, string>  {
        const keyValuePair = new Hashtable<string, string>();
        sConnectionString.split(";").forEach(sParameter=> {
            let item=sParameter.split('=');
            item.length>1 && keyValuePair.put(item[0].trim().toLowerCase(),item.length>0?item[1].trim():"");
//             let separatorIndex = sParameter.indexOf('=');
//             if (separatorIndex != -1 && (sParameter.length > (separatorIndex + 1))) {
//                 let sKey = sParameter.substring(0, separatorIndex).toLowerCase();
//                 if (!TextUtils.isEmpty(sKey)) {
//                     sKey = sKey.trim();
//                 }
//                 let sValue = sParameter.substring(separatorIndex + 1);
//                 if (!TextUtils.isEmpty(sValue)) {
//                     sValue = sValue.trim();
//                 }
// //                if (keyValuePair.containsKey(sKey)) {
// //                    throw new RuntimeException("Duplicate key " + sKey + " in connection string " + sConnectionString);
// //                }
//                 keyValuePair.put(sKey, sValue);
            // }
        });
        return keyValuePair;
    }
}
