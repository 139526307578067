import Hashtable from "../Collections/HashMap/Hashtable";
import XmlNode from "../Xml/JSONImpl/XmlNode";
import TextUtils from "./TextUtils";
import { Utils } from "./Utils";

export default class XmlUtils {


    public static GetNodeAttr(node:XmlNode, AttrName:string, sDefault:string ="") {
        return this.getNodeAttr(node,AttrName,sDefault);
    }
    public static getNodeAttr(node:XmlNode, AttrName:string, sDefault:string ="") {
        if (node == null) {
            return sDefault;
        }
        const s = node.getAttrValue(AttrName);
        if (TextUtils.isEmpty(s)) {
            return sDefault;
        } else {
            return s;
        }
    }

    public static getValuesByConditions(map:Hashtable<String, String>, parent:XmlNode, defaultValue: string=""):void {
        if (parent == null) {
            map.put(Utils.MACRO_DEFAULT, defaultValue);
            return;
        }
        parent.getChildNodes().forEach(node => {
            this.getNodeAttr(node, "conditions", Utils.MACRO_DEFAULT).split(";").forEach(s=> map.put(s, node.getAttrValue(Utils.PROP_ATTR_NAME)));
        });;
        // for (let node in list) {
        //    const conditions = this.getNodeAttr(node, "conditions", Utils.MACRO_DEFAULT).split(";");
        //     for (l s : conditions) {
        //         map.put(s, GetNodeAttr(node, "name"));
        //     }
        // }
        if (!map.containsKey(Utils.MACRO_DEFAULT)) {
            map.put(Utils.MACRO_DEFAULT, defaultValue);
        }
    }
}