import StringUtils from "./StringUtils";

export default class StringBuilder {
    
    
    
    private m_data: string[]
    /**
     *
     */
    constructor(text:string="") {
        this.m_data=Array.from(text);
    }

    public append(value:any):StringBuilder {
        let text=StringUtils.SafeToString(value);
        this.m_data.push(text);
        return this;
    }

    public toString(): string {
        return this.m_data.join("");
    }

    public insert(index: number, item: string) {
        this.m_data.add(index,item);
    }

    public length():number {
        return this.toString().length;
    }

    public isEmpty(): boolean {
        return this.m_data.length===0;
    }

    public charAt(position: number):string {
        return this.toString().charAt(position);
    }
}