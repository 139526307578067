import TextUtils from "../Utils/TextUtils";
import StringUtils from "../Utils/StringUtils";
import Hashtable from "../Collections/HashMap/Hashtable";
import { Utils } from "../Utils/Utils";
import { XoneApplication } from "./XoneApplication";
import { XoneDataObject } from "./XoneDataObject";
import { XoneDataSource } from "./XoneDataSource";
import { Exception } from "../Exceptions/Exception";
import XoneGenericException from "../Exceptions/XoneGenericException";
import { XoneMessageKeys } from "../Exceptions/XoneMessageKeys";
import IMessageHolder from "../Interfaces/IMessageHolder";
import NumberUtils from "../Utils/NumberUtils";
import IXmlDocument from "../Interfaces/IXmlDocument";
import XoneConnectionData from "../Connection/XoneConnectionData";
import IFieldProperties from "../Interfaces/IField";
import MacrosEvaluator from "./Macros/MacrosEvaluator";
import { SqlParser } from "../Parsers/SQL/SqlParser";
import StringBuilder from "../Utils/StringBuilder";
import SqlType from "../Utils/SqlType";
import Calendar from "../Utils/Calendar";
import ObjUtils from "../Utils/ObjUtils";
import { Hash } from "crypto";
import XoneCompany from "./XoneCompany";
import XoneUser from "./XoneUser";
import Vector from "../Collections/Vector";
import XoneLookupObject from "./XoneLookupObject";
import XmlUtils from "../Utils/XmlUtils";
import XmlNodeList from "../Xml/JSONImpl/XmlNodeList";
import XmlNode from "../Xml/JSONImpl/XmlNode";
import IllegalArgumentException from "../Exceptions/IllegalArgumentException";
import { Console } from "console";
import UITransform from "../Transform/UITransform";
import XoneCssRule from "./CSS/XoneCssRule";
import { QueryTable } from "../Parsers/SQL/QueryTable";
import IResultSet from "../Interfaces/IResultSet";
import XoneBrowseData from "./XoneBrowseData";
import ScriptUtils from "../Utils/ScriptUtils";
import ScriptContext from "../XoneScripts/ScriptContext";

type ScriptParams = {
	ctx: any;
	slang: string;
	scripts: string[];
	funcNames: string[];
	Arguments: any[];
	argsName: string[];
	argsValue: any[];
};

export interface DataCollectionOptions {
	bisIndexed: boolean;
	bCheckOwner: boolean;
	bIsSpecial: boolean;
	bStringPk: boolean;
	bObjLoadAll: boolean;
	bVolatile: boolean;
	bDeferredLoad: boolean;
	bDebug: boolean;
	bUseRaw: boolean;
	bParseSQL: boolean;
	nPkType: number;
	bMultipleKey: boolean;
	strPrefix: string;
	strLookupMacroName: string;
	strAccessString: string;
	bSingleObject: boolean;
	strProgId: string;
	strClassName: string;
	nThreshold: number;
	nMaxRows: number;
	strObjectName: string;
	strUpdateObject: string;
	bHasXlatProps: boolean;
	bLoadLayouts: boolean;
	strParentCollection: string;
}

export class XoneDataCollection implements IFieldProperties {
	// Solo para identificar que una instancia es una colección
	public get isDataCollection(): boolean {
		return true;
	}

	public static PROP_TYPE_INTEGER = 0;
	public static PROP_TYPE_STRING = 1;
	public static PROP_TYPE_DOUBLE = 2;
	public static PROP_TYPE_DATE = 3;
	public static PROP_TYPE_RESULTSET = 0;

	private m_ownerApp: XoneApplication;
	private m_xmlNode: XmlNode;
	private m_version: number;
	private m_bLocked: boolean;
	private m_bIsSpecial: boolean;
	private m_bFull: boolean;
	private m_pOwnerObject: XoneDataObject;
	private m_strName: string;
	private m_messages: IMessageHolder;
	private m_options: DataCollectionOptions;
	private m_parent: XoneDataCollection;
	private m_lstFormulaProps: string[];
	private m_lstBitProps: string[];

	private m_macrosEvaluator: MacrosEvaluator;
	private m_strLinkFilter: string;
	private m_strFilter: string;
	private m_lstVariables: Hashtable<string, Object>;
	// private m_lstMacros: Hashtable<string, string>;
	private m_strPk: string;
	private m_nPkType: number;
	private m_nBrowseLength: number;
	private m_lstKeyFields: Vector<string>;
	private m_browserData: XoneBrowseData;
	private UNIQUE_ID: symbol;
	private m_bIsClearing: boolean;
	private m_strSort: string;
	// Voy a cachear el plaform node que se pide un monton de veces por gusto
	private m_cachePlatformNodes: Hashtable<String, XmlNode>;

	private m_strContentsName: string;
	private m_bUseObjectsInRestore: boolean;
	private m_bFollowRules: boolean;

	constructor(appData: XoneApplication, source: XmlNode, version: number) {
		this.UNIQUE_ID = Symbol("XoneDataCollection");
		this.m_ownerApp = appData;
		this.m_xmlNode = source; // new XoneDataSource(null,source);
		this.m_version = version;
		this.m_messages = appData.getMessageHolder();
		this.m_options = <any>{};
		this.m_strPk = "ID";
		this.m_nPkType = 0;
		this.m_parent = null;
		this.m_bLocked = false;
		this.m_macrosEvaluator = new MacrosEvaluator(this.m_ownerApp, this);
		// this.m_lstMacros = new Hashtable<string, string>();
		// Si la colección no tiene nombre nos explotamos...
		// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
		if (StringUtils.IsEmptyString((this.m_strName = this.m_xmlNode.getAttrValue("name"))))
			////throw new Exception("No se puede crear una colección sin nombre o con nombre vacío.");
			throw new Exception(this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_COLL_NONAME, "Cannot create a collection with an empty name."));
		// Crear la lista de claves múltiples por si las moscas
		this.m_lstKeyFields = new Vector<string>();
		// O12050702: Optimización al gestionas los campos que son de fórmula o bit.
		this.m_lstFormulaProps = new Array<string>();
		this.m_lstBitProps = new Array<string>();
		this.m_cachePlatformNodes = new Hashtable<String, XmlNode>();
		// Iniciar el browserdata
		this.m_browserData = new XoneBrowseData(this);
	}

	public getXmlNode(): XmlNode {
		return this.m_xmlNode;
	}

	public getVersion(): number {
		return this.m_version;
	}

	public get BrowseData(): XoneBrowseData {
		return this.m_browserData;
	}
	/**
	 * Devuelve el valor del atributo cuyo nombre se indica en la propiedad deseada.
	 * @param FieldName			Nombre de la propiedad o campo de la colección cuyo atributo se quiere extraer.
	 * @param AttrName			Nombre del atributo cuyo valor se quiere leer en el campo AttrName.
	 * @return					Devuelve el valor del atributo de la propiedad solicitada. NULL o cadena vacía si no existe dicho atributo.
	 * @throws Exception
	 */
	public FieldPropertyValue(FieldName: string, AttrName: string): string {
		if (!AttrName) return; //TAG 20201123 (Alejandro) parche temporal, he agregado esta clausula para evitar un error al llegar AttrName null
		if (TextUtils.isEmpty(FieldName) || TextUtils.isEmpty(AttrName)) {
			/*
			 * TODO 25/02/2019 Juan Carlos
			 * Antes no había check de esto y el código siempre acababa explotándose mucho después
			 * y sin una descripción clara. Mejor ponerlo aquí, comentarlo si resulta que null es
			 * un valor válido, que no creo.
			 */
			throw new IllegalArgumentException("Empty field name passed to XoneDataCollection.FieldPropertyValue(). Collection name: " + this.getName());
		}

		// Si no tiene nodo XML, no hacemos nada
		let strTmp = "";

		// F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
		// No es lo mismo cadena vacía que NULL
		if (this.m_xmlNode == null) return Utils.EMPTY_STRING;

		let xprop: XmlNode = null;
		// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
		// Armar la cadena a buscar para resolver el cacheo
		// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
		// Cambiamos esto para usar cache multinivel
		//////StringBuilder sb =new StringBuilder();
		//////sb.append("##prop|").append(FieldName).append("|").append(AttrName);
		// Ver si es una propiedad que no está definida
		// O11081101: Optimizar las estructuras de datos de las caches de atributos inexistentes.
		//////Set<String> ucache =m_owner.GetCollPropUndefinedValues(m_strName);
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		//////if (ucache !=null)
		//////	if (ucache.contains(sb.toString()))
		//////		return null;
		// Obtiene la cache para buscar los atributos.
		// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
		let cache = this.m_ownerApp.GetCollPropValueCache(this.m_strName, "prop", FieldName);
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
		if (cache != null) {
			// Tiene cache
			//synchronized (cache)
			{
				if (cache.containsKey(AttrName)) return cache.get(AttrName);
			}
		} // Tiene cache
		// De lo contrario buscar el nodo de la propiedad para sacar el atributo
		xprop = this.m_xmlNode.SelectSingleNode("prop", "name", FieldName);
		// M10090801:	Modificaciones para permitir que los <button> sean considerados propiedades.
		// Comprobar si no tenemos prop, al menos si hay button
		if (xprop == null) xprop = this.m_xmlNode.SelectSingleNode("button", "name", FieldName);

		// Si tenemos la propiedad, devolver su valor...
		if (xprop != null) {
			// Tiene la propiedad
			// test sergio*
			////m_propertyHashNamesValues.put(FieldName, xprop);
			// Obtener la plataforma
			if (!StringUtils.IsEmptyString((strTmp = this.getOwnerApp().getPlatform()))) {
				// Ver si la puede sacar de aquí
				let platNode = xprop.SelectSingleNode("platform", "name", strTmp);
				if (platNode != null)
					if (!StringUtils.IsEmptyString((strTmp = XmlUtils.getNodeAttr(platNode, AttrName)))) {
						// Este vale
						// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
						// Cachear el valor para ahorrarnos esto después.
						// K12050901: Permitir activar o desactivar las caches de valores de atributos.
						// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
						// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
						if (cache != null) {
							// Tiene cache
							//synchronized (cache)
							{
								cache.put(AttrName, strTmp);
							}
						} // Tiene cache
						// O13080801: Mecanismo de optimización para la gestión de controles visuales y atributos.
						return this.CheckIfEvaluated(FieldName, AttrName, strTmp);
					} // Este vale
			} // Ver si la puede sacar de aquí
			// De lo contrario lo sacamos del propio nodo...
			// M10090801:	Modificaciones para permitir que los <button> sean considerados propiedades.
			// Contemplar casos especiales (type en los botones, por ejemplo)
			let strAttrVal = XmlUtils.getNodeAttr(xprop, AttrName);
			if (!StringUtils.IsEmptyString(strAttrVal)) {
				// Este nos vale
				// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
				// Cachear el valor para ahorrarnos esto después.
				// K12050901: Permitir activar o desactivar las caches de valores de atributos.
				// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
				// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
				if (cache != null) {
					// Tiene cache
					//synchronized (cache)
					{
						cache.put(AttrName, strAttrVal);
					}
				} // Tiene cache
				// O13080801: Mecanismo de optimización para la gestión de controles visuales y atributos.
				return this.CheckIfEvaluated(FieldName, AttrName, strAttrVal);
			} // Este nos vale
			// Si es un botón, comprobar el caso especial
			if (AttrName.equals("type")) {
				// Ver si es un botón
				let strName = xprop.getName();
				if (strName.equals("button")) {
					// Botón
					// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
					// Cachear el valor.
					// K12050901: Permitir activar o desactivar las caches de valores de atributos.
					// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
					// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
					if (cache != null) {
						// Tiene cache
						//synchronized (cache)
						{
							cache.put(AttrName, "B");
						}
					} // Tiene cache
					return "B";
				} // Botón
			} // Ver si es un botón
			// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
			// Comprobar si hay hojas de estilo para hacer búsquedas...
			// TODO: Luis Esto es lo mas grave que es utilizar nuestro CSS
			if (this.getOwnerApp().hasStylesheets()) {
				// Tiene CSS
				// A12042502: Los selectores de CSS pueden estar tipificados.
				let strType = xprop.getAttrValue("type");
				let css: XoneCssRule = null;
				let strClass = xprop.getAttrValue("class");
				if (StringUtils.IsEmptyString(strClass)) strClass = this.m_xmlNode.getAttrValue("class");
				if (!StringUtils.IsEmptyString(strClass)) {
					// Buscar por clase
					// A12042502: Los selectores de CSS pueden estar tipificados.
					// ADD Tag: Luis 1604201801 Modificaco para permitir las clases en cascadas
					// Separadas por coma.
					// TODO: Permitir los atributos con !important se necesita doble pasada
					let clsArr = strClass.split(" ");
					clsArr.reverse();
					for (let i = 0; i < clsArr.length; i++) {
						let cls = clsArr[i].trim();
						if (TextUtils.isEmpty(cls)) continue;
						// Buscar primero por subtipo (i.e. prop.kaka:T)
						if (null == (css = this.m_ownerApp.FindStylesheetByClassName("prop." + cls + ":" + strType, AttrName))) {
							// No está por subtipo
							if (null == (css = this.m_ownerApp.FindStylesheetByClassName("prop." + cls, AttrName)))
								css = this.m_ownerApp.FindStylesheetByClassName("." + cls, AttrName);
						} // No está por subtipo
						if (css != null) break;
					}
				} // Buscar por clase
				else {
					// Buscar por el prop
					// A12042502: Los selectores de CSS pueden estar tipificados.
					// Buscar primero por subtipo (i.e. prop:B)
					if (null == (css = this.m_ownerApp.FindStylesheetByClassName("prop:" + strType, AttrName)))
						css = this.m_ownerApp.FindStylesheetByClassName("prop", AttrName);
				} // Buscar por el prop
				if (css != null) {
					// Buscar en el stylesheet
					strTmp = css.getRuleValue(AttrName);
					if (!StringUtils.IsEmptyString(strTmp)) {
						// Anda
						// K12050901: Permitir activar o desactivar las caches de valores de atributos.
						// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
						// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
						if (cache != null) {
							// Tiene cache
							//synchronized (cache)
							{
								cache.put(AttrName, strTmp);
							}
						} // Tiene cache
						// O13080801: Mecanismo de optimización para la gestión de controles visuales y atributos.
						return this.CheckIfEvaluated(FieldName, AttrName, strTmp);
					} // Anda
				} // Buscar en el stylesheet
			} // Tiene CSS
			// Vale, se acaban los casos especiales, así que NULL pallá
			// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
			// Pero antes guardamos el descriptor de esta propiedad para decir que es nulo siempre
			// K12050901: Permitir activar o desactivar las caches de valores de atributos.
			// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
			// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
			if (cache != null) {
				// Tiene cache
				//synchronized (cache)
				{
					cache.put(AttrName, Utils.EMPTY_STRING);
				}
			} // Tiene cache
			// F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
			// No es lo mismo cadena vacía que NULL
			return Utils.EMPTY_STRING;
		} // Tiene la propiedad
		// De lo contrario tenemos que ver si es una colección heredada, y llamar a la del padre
		if (!StringUtils.IsEmptyString(this.m_options.strParentCollection)) {
			// Supuestamente tiene padre
			if (this.getParentCollection() == null) {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new XoneGenericException(-17171, "CXoneDataCollection::FieldPropertyValue ha fallado en la colección '" + m_strName + "'. La colección padre ('" + m_strParentCollection + "') no se puede obtener.");
				let strMsg = this.m_messages.GetMessage(
					XoneMessageKeys.SYS_MSG_COLL_XPROPVALUEFAIL,
					"{0} failed for collection '{1}'. Cannot get parent collection '{2}'"
				);
				strMsg = strMsg.replace("{0}", "CXoneDataCollection::FieldPropertyValue");
				strMsg = strMsg.replace("{1}", this.m_strName);
				strMsg = strMsg.replace("{2}", this.m_options.strParentCollection);
				throw new XoneGenericException(-17171, strMsg);
			} // Error
			return this.getParentCollection().FieldPropertyValue(FieldName, AttrName);
		} // Supuestamente tiene padre
		// De lo contrario nanai...
		// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
		// Pero antes cachear que no tenemos valor
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
		if (cache != null) cache.put(AttrName, Utils.EMPTY_STRING);
		// F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
		// No es lo mismo cadena vacía que NULL
		return Utils.EMPTY_STRING;
	}

	public getName() {
		return this.m_strName;
	}

	// A10090601:	Incluir el mecanismo de réplica selectiva por colecciones a la maquinaria.
	// TRUE si la colección replica (solo cuando la réplica es selectiva por colecciones)
	public getReplicate(): boolean {
		return StringUtils.ParseBoolValue(this.CollPropertyValue("replicate"), false);
	}

	CheckIfEvaluated(FieldName: string, AttrName: string, sVal: string): string {
		try {
			if (!StringUtils.IsEmptyString(sVal)) {
				if (sVal.contains("##")) {
					// Es una macro o la contiene
					// M11060902: Mejoras en el sistema de evaluación de macros en el framework.
					if (sVal.equals(StringUtils.XONE_EMPTY_STRING)) return Utils.EMPTY_STRING;
					// O13080801: Mecanismo de optimización para la gestión de controles visuales y atributos.
					let key = FieldName + ":" + AttrName;
					this.m_ownerApp.addEvaluatedAttributesList(this.m_strName, key);
				}
			}
		} catch (e) {
			console.error(e);
		}
		return sVal;
	}

	//#region Transformaciones para el UI PWA
	public getLayout(visibility: number = 1, parent: XmlNode = null): any {
		return UITransform.getLayout(this, parent || this.getHierarchyXml(), visibility);
	}

	public async getLayoutAsync(visibility: number = 1, parent: XmlNode = null): Promise<any> {
		return await UITransform.getLayoutAsync(this, parent || this.getHierarchyXml(), visibility);
	}

	// Vamos a leer la herencia en formato de XML para arriba
	public getHierarchyXml(): XmlNode {
		if (this.getParentCollection() == null) return this.getProperties();
		return this.getProperties().merge(this.getParentCollection().getHierarchyXml());
	}

	QualifyField(FieldName: string, Sentence?: SqlParser): string {
		return this.BrowseData.QualifyField(FieldName, Sentence);
	}

	public getPropAttributes(Value: string | XmlNode, type: string = Utils.PROP_NAME): any {
		return UITransform.getPropAttributes(this, Value, type);
		// let node: XmlNode;
		// let PropName: string
		// if (typeof Value == 'string') {
		//     node=this.m_xmlNode.SelectSingleNode(type,Utils.PROP_ATTR_NAME,Value);
		//     PropName=Value;
		// } else {
		//     node= Value as XmlNode;
		//     PropName=node.getAttrValue(Utils.PROP_ATTR_NAME);
		//     type=node.getName();
		// }
		// if (ObjUtils.IsNothing(node))
		//     return {};
		// let attrs=node.getAttrs();
		// let result=<any>{ node: type};
		// for (const key in UITransform.UI_ATTRIBUTES[type]) {
		//     if (Object.prototype.hasOwnProperty.call(UITransform.UI_ATTRIBUTES[type], key)) {
		//         const element = UITransform.UI_ATTRIBUTES[type][key];
		//         const value=node.getName().equalsIgnoreCase(Utils.PROP_NAME)?this.FieldPropertyValue(PropName,element):this.NodePropertyValue(type,PropName,element);
		//         if (!TextUtils.isEmpty(value))
		//         {
		//             result[key]=UITransform.developValue(this,PropName,key,element);
		//         }
		//     }
		// }
		// return result;
	}
	//#endregion

	/**
	 * A14040101: Función sobrecargada de NodePropertyValue jerárquica.
	 * Sobrecarga de NodePropertyValue para poder sacar valores de jerarquías de nodos.
	 *
	 * @param BaseNode		Nodo usado como base para buscar el se pide.
	 * @param NodeName		Nombre del nodo xml cuyo atributo se quiere obtener.
	 * @param ItemName		Nombre del atributo name del nodo que se quiere buscar.
	 * @param AttrName		Nombre del atributo cuyo valor se quiere obtener.
	 * @return
	 * @throws Exception
	 */
	public NodePropertyValueBase(BaseNode: XmlNode, NodeName: string, ItemName: string, AttrName: string): string {
		let strTmp = "";

		// Si no tiene nodo XML, no hacemos nada
		if (this.m_xmlNode == null) return Utils.EMPTY_STRING;
		// Obtener la cache correspondiente para el nodo en cuestión.... dando patrás hasta que llegue a coll
		let sbCacheTag = new StringBuilder(NodeName);
		let parent = BaseNode;
		while (!parent.getName().equals(Utils.COLL_COLL)) {
			// Lo que tengamos lo arreglamos
			sbCacheTag.insert(0, "/");
			sbCacheTag.insert(0, parent.getName());
			parent = parent.getParentNode();
		} // Lo que tengamos lo arreglamos
		// Ahora buscamos la cache...
		let cache = this.m_ownerApp.GetCollPropValueCache(this.m_strName, sbCacheTag.toString(), ItemName);
		if (cache != null) {
			//synchronized (cache)
			if (cache.containsKey(AttrName)) return cache.get(AttrName);
		}
		// Ahora tendremos que buscar el nodo en cuestión dentro del que nos han mandado, no en la lista general
		let xprop: XmlNode = null;
		if (StringUtils.IsEmptyString(ItemName)) xprop = BaseNode.SelectSingleNode(NodeName);
		else xprop = BaseNode.SelectSingleNode(NodeName, Utils.PROP_ATTR_NAME, ItemName);
		// Si tenemos la propiedad, devolver su valor...
		if (xprop != null) {
			// Tiene la propiedad
			// Obtener la plataforma
			if (!StringUtils.IsEmptyString((strTmp = this.getOwnerApp().getPlatform()))) {
				// Ver si la puede sacar de aquí
				let platNode = xprop.SelectSingleNode("platform", Utils.PROP_ATTR_NAME, strTmp);
				if (platNode != null)
					if (!StringUtils.IsEmptyString((strTmp = platNode.getAttrValue(AttrName)))) {
						// Tiene valor
						// Cachear el valor.
						if (cache != null) {
							///synchronized (cache)
							cache.put(AttrName, strTmp);
						}
						return this.CheckIfEvaluated(ItemName, AttrName, strTmp); //strTmp;
					} // Tiene valor
			} // Ver si la puede sacar de aquí
			// De lo contrario lo sacamos del propio nodo...
			// Cachear o cachear...
			strTmp = xprop.getAttrValue(AttrName);
			if (!StringUtils.IsEmptyString(strTmp)) {
				// Tiene valor
				if (cache != null) {
					//synchronized (cache)
					cache.put(AttrName, strTmp);
				}
				// Cualquier otro valor de nodo, no hay verificación...
				return this.CheckIfEvaluated(ItemName, AttrName, strTmp); //strTmp;
			} // Tiene valor
			// Comprobar si hay hojas de estilo para hacer búsquedas...
			if (this.m_ownerApp.hasStylesheets()) {
				// Tiene CSS
				let css: XoneCssRule = null;
				let strClass = xprop.getAttrValue("class");
				if (StringUtils.IsEmptyString(strClass)) strClass = this.m_xmlNode.getAttrValue("class");
				if (!StringUtils.IsEmptyString(strClass)) {
					// Buscar por clase
					// ADD Tag: Luis 1604201801 Modificaco para permitir las clases en cascadas
					// Separadas por coma.
					// TODO: Permitir los atributos con !important se necesita doble pasada
					let clsArr = strClass.split(" ");
					clsArr.reverse();
					for (let i = 0; i < clsArr.length; i++) {
						let cls = clsArr[i].trim();
						if (TextUtils.isEmpty(cls)) continue;
						if (null == (css = this.m_ownerApp.FindStylesheetByClassName(NodeName + "." + cls, AttrName))) {
							css = this.m_ownerApp.FindStylesheetByClassName("." + cls, AttrName);
						}
						if (css != null) break;
					}
					//                    if (null == (css = m_owner.FindStylesheetByClassName(NodeName + "." + strClass, AttrName)))
					//                        css = m_owner.FindStylesheetByClassName("." + strClass, AttrName);
				} // Buscar por clase
				else css = this.m_ownerApp.FindStylesheetByClassName(NodeName, AttrName);
				if (css != null) {
					// Buscar en el stylesheet
					strTmp = css.getRuleValue(AttrName);
					if (!StringUtils.IsEmptyString(strTmp)) {
						// Anda
						if (cache != null) {
							//synchronized (cache)
							cache.put(AttrName, strTmp);
						}
						return this.CheckIfEvaluated(ItemName, AttrName, strTmp); //strTmp;
					} // Anda
				} // Buscar en el stylesheet
			} // Tiene CSS
			// Cachear idicando que no hay valor.
			// Guardamos en la misma cache una constante vacía
			if (cache != null) {
				//synchronized (cache)
				cache.put(AttrName, Utils.EMPTY_STRING);
			}
			// No es lo mismo cadena vacía que NULL
			return Utils.EMPTY_STRING;
		} // Tiene la propiedad
		// De lo contrario tenemos que ver si es una colección heredada, y llamar a la del padre
		if (!StringUtils.IsEmptyString(this.Options.strParentCollection)) {
			// Supuestamente tiene padre
			if (this.getParentCollection() == null)
				throw new XoneGenericException(
					-17171,
					"CXoneDataCollection::NodePropertyValue ha fallado en la colección '" +
						this.m_strName +
						"'. La colección padre ('" +
						this.Options.strParentCollection +
						"') no se puede obtener."
				);
			return this.getParentCollection().NodePropertyValueBase(BaseNode, NodeName, ItemName, AttrName);
		} // Supuestamente tiene padre
		// De lo contrario nanai...
		// Cachear para indicar que no hay valor
		if (cache != null) {
			//synchronized (cache)
			cache.put(AttrName, Utils.EMPTY_STRING);
		}
		// No es lo mismo cadena vacía que NULL
		return Utils.EMPTY_STRING;
	}

	// M10082302:	Crear una función para sacar cualquier atributo de cualquier nodo de la colección.
	// Propiedades de un nodo dado su nombre y su atributo
	public NodePropertyValue(NodeName: string, ItemName: string, AttrName: string): string {
		// Si no tiene nodo XML, no hacemos nada
		let strTmp = "";

		// F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
		// No es lo mismo cadena vacía que NULL
		if (this.m_xmlNode == null) return Utils.EMPTY_STRING;

		let xprop: XmlNode = null;
		// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
		// Armar la cadena a buscar para resolver el cacheo
		// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
		//////StringBuilder sb =new StringBuilder();
		//////sb.append("##").append(NodeName).append("|").append(ItemName).append("|").append(AttrName);
		// Ver si es una propiedad que no está definida
		// O11081101: Optimizar las estructuras de datos de las caches de atributos inexistentes.
		//////Set<String> ucache =m_owner.GetCollPropUndefinedValues(m_strName);
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		//////if (ucache !=null)
		//////	if (ucache.contains(sb.toString()))
		//////		return null;
		// Obtener la cache con los valores de los atributos de la colección.
		let cache = this.m_ownerApp.GetCollPropValueCache(this.m_strName, NodeName, ItemName);
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
		if (cache != null) {
			// Tiene cache
			//synchronized (cache)
			{
				if (cache.containsKey(AttrName)) return cache.get(AttrName);
			}
		} // Tiene cache
		// De lo contrario buscar el nodo de la propiedad para sacar el atributo
		xprop = NodeName.equals(this.m_xmlNode.getName()) ? this.m_xmlNode : this.m_xmlNode.SelectSingleNode(NodeName, Utils.PROP_ATTR_NAME, ItemName);

		// Si tenemos la propiedad, devolver su valor...
		if (xprop != null) {
			// Tiene la propiedad
			// Obtener la plataforma
			if (!StringUtils.IsEmptyString((strTmp = this.getOwnerApp().getPlatform()))) {
				// Ver si la puede sacar de aquí
				let platNode = xprop.SelectSingleNode(Utils.PLATFORM_NAME, Utils.PROP_ATTR_NAME, strTmp);
				if (platNode != null)
					if (!StringUtils.IsEmptyString((strTmp = XmlUtils.getNodeAttr(platNode, AttrName)))) {
						// Tiene valor
						// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
						// Cachear el valor.
						// K12050901: Permitir activar o desactivar las caches de valores de atributos.
						// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
						// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
						if (cache != null) {
							// Tiene cache
							//synchronized (cache)
							{
								cache.put(AttrName, strTmp);
							}
						} // Tiene cache
						return this.CheckIfEvaluated(ItemName, AttrName, strTmp); //strTmp;
					} // Tiene valor
			} // Ver si la puede sacar de aquí
			// De lo contrario lo sacamos del propio nodo...
			// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
			// Cachear o cachear...
			strTmp = xprop.getAttrValue(AttrName);
			if (!StringUtils.IsEmptyString(strTmp)) {
				// Tiene valor
				// K12050901: Permitir activar o desactivar las caches de valores de atributos.
				// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
				// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
				if (cache != null) {
					// Tiene cache
					//synchronized (cache)
					{
						cache.put(AttrName, strTmp);
					}
				} // Tiene cache
				return this.CheckIfEvaluated(ItemName, AttrName, strTmp); //strTmp;
			} // Tiene valor
			// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
			// Comprobar si hay hojas de estilo para hacer búsquedas...
			if (this.m_ownerApp.hasStylesheets()) {
				// Tiene CSS
				let css: XoneCssRule = null;
				let strClass = xprop.getAttrValue("class");
				if (StringUtils.IsEmptyString(strClass)) strClass = this.m_xmlNode.getAttrValue("class");
				if (!StringUtils.IsEmptyString(strClass)) {
					// Buscar por clase
					// F11072901: La búsqueda en NodePropertyValue no usa el tag apropiado.
					// ADD Tag: Luis 1604201801 Modificaco para permitir las clases en cascadas
					// Separadas por coma.
					// TODO: Permitir los atributos con !important se necesita doble pasada
					let clsArr = strClass.split(" ");
					clsArr.reverse();
					for (let i = 0; i < clsArr.length; i++) {
						let cls = clsArr[i].trim();
						if (TextUtils.isEmpty(cls)) continue;
						if (null == (css = this.m_ownerApp.FindStylesheetByClassName(NodeName + "." + cls, AttrName))) {
							css = this.m_ownerApp.FindStylesheetByClassName("." + cls, AttrName);
						}
						if (css != null) break;
					}
					//	    			if (null ==(css =m_owner.FindStylesheetByClassName (NodeName + "." + strClass, AttrName)))
					//	    				css =m_owner.FindStylesheetByClassName("." + strClass, AttrName);
				} // Buscar por clase
				else css = this.m_ownerApp.FindStylesheetByClassName(NodeName, AttrName);
				if (css != null) {
					// Buscar en el stylesheet
					strTmp = css.getRuleValue(AttrName);
					if (!StringUtils.IsEmptyString(strTmp)) {
						// Anda
						// K12050901: Permitir activar o desactivar las caches de valores de atributos.
						// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
						// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
						if (cache != null) {
							// Tiene cache
							//synchronized (cache)
							{
								cache.put(AttrName, strTmp);
							}
						} // Tiene cache
						return this.CheckIfEvaluated(ItemName, AttrName, strTmp); //strTmp;
					} // Anda
				} // Buscar en el stylesheet
			} // Tiene CSS
			// Cachear idicando que no hay valor.
			// K12050901: Permitir activar o desactivar las caches de valores de atributos.
			// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
			// Guardamos en la misma cache una constante vacía
			// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
			if (cache != null) {
				// Tiene cache
				//synchronized (cache)
				{
					cache.put(AttrName, Utils.EMPTY_STRING);
				}
			} // Tiene cache
			// F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
			// No es lo mismo cadena vacía que NULL
			return Utils.EMPTY_STRING;
		} // Tiene la propiedad
		// De lo contrario tenemos que ver si es una colección heredada, y llamar a la del padre
		if (!StringUtils.IsEmptyString(this.Options.strParentCollection)) {
			// Supuestamente tiene padre
			if (this.getParentCollection() == null) {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				let str = this.m_messages.GetMessage(
					XoneMessageKeys.SYS_MSG_COLL_XPROPVALUEFAIL,
					"{0} failed for collection '{1}'. Cannot get parent collection '{2}'."
				);
				str = str.replace("{0}", "CXoneDataCollection::NodePropertyValue");
				str = str.replace("{1}", this.m_strName);
				str = str.replace("{2}", this.Options.strParentCollection);
				throw new XoneGenericException(-17171, str);
			} // Error
			////throw new XoneGenericException(-17171, "CXoneDataCollection::FieldPropertyValue ha fallado en la colección '" + m_strName + "'. La colección padre ('" + m_strParentCollection + "') no se puede obtener.");
			return this.getParentCollection().NodePropertyValue(NodeName, ItemName, AttrName);
		} // Supuestamente tiene padre
		// De lo contrario nanai...
		// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
		// Cachear para indicar que no hay valor
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
		// Guardamos en la misma cache una constante vacía
		// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
		if (cache != null) {
			// Tiene cache
			//synchronized (cache)
			{
				cache.put(AttrName, Utils.EMPTY_STRING);
			}
		} // Tiene cache
		// F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
		// No es lo mismo cadena vacía que NULL
		return Utils.EMPTY_STRING;
	}

	public SetNodePropertyValue(NodeName: string, ItemName: string, AttrName: string, sValue: string): void {
		if (this.m_xmlNode == null) {
			return;
		}
		let cache = this.m_ownerApp.GetCollPropValueCache(this.m_strName, NodeName, ItemName);
		if (cache == null) {
			return;
		}
		cache.put(AttrName, sValue);
	}

	/// Devuelve el valor del atributo del grupo cuyo id se indica en la propiedad deseada.
	/// A09100502:   Adicionar un equivalente a FieldPropertyValue para los grupos
	/// <param name="GroupId">Id del grupo de la colección cuyo atributo se quiere extraer</param>
	/// <param name="AttrName">Nombre del atributo cuyo valor se quiere leer en el campo AttrName</param>
	public GroupPropertyValue(GroupId: string, AttrName: string): string {
		// Si no tiene nodo XML, no hacemos nada
		let strTmp = "";

		// F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
		// No es lo mismo cadena vacía que NULL
		if (this.m_xmlNode == null) return Utils.EMPTY_STRING;

		let xprop: XmlNode = null;
		// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
		// Armar la cadena a buscar para resolver el cacheo
		// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
		// Cambiar un poco esto como se hace.
		//////StringBuilder sb =new StringBuilder();
		//////sb.append("##group|").append(GroupId).append("|").append(AttrName);
		// Ver si es una propiedad que no está definida
		// O11081101: Optimizar las estructuras de datos de las caches de atributos inexistentes.
		//////Set<String> ucache =m_owner.GetCollPropUndefinedValues(m_strName);
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		//////if (ucache !=null)
		//////	if (ucache.contains(sb.toString()))
		//////		return null;
		// Obtener la cache con los atributos de la colección.
		let cache = this.m_ownerApp.GetCollPropValueCache(this.m_strName, "group", GroupId);
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
		if (cache != null) {
			// Tiene cache
			//synchronized (cache)
			{
				if (cache.containsKey(AttrName)) return cache.get(AttrName);
			}
		} // Tiene cache
		// De lo contrario buscar el nodo de la propiedad para sacar el atributo
		xprop = this.m_xmlNode.SelectSingleNode("group", "id", GroupId);
		// Si tenemos la propiedad, devolver su valor...
		if (xprop != null) {
			// Tiene la propiedad
			// Obtener la plataforma
			if (!StringUtils.IsEmptyString((strTmp = this.getOwnerApp().getPlatform()))) {
				// Ver si la puede sacar de aquí
				let platNode = xprop.SelectSingleNode("platform", "name", strTmp);
				if (platNode != null)
					if (!StringUtils.IsEmptyString((strTmp = XmlUtils.getNodeAttr(platNode, AttrName)))) {
						// Tiene valor
						// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
						// Cachear el valor.
						// K12050901: Permitir activar o desactivar las caches de valores de atributos.
						// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
						// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
						if (cache != null) {
							// Tiene cache
							//synchronized (cache)
							{
								cache.put(AttrName, strTmp);
							}
						} // Tiene cache
						return strTmp;
					} // Tiene valor
			} // Ver si la puede sacar de aquí
			// De lo contrario lo sacamos del propio nodo...
			// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
			// Cachear o cachear...
			strTmp = XmlUtils.getNodeAttr(xprop, AttrName);
			if (StringUtils.IsEmptyString(strTmp) && !StringUtils.IsEmptyString(this.Options.strParentCollection)) {
				// Supuestamente tiene padre
				if (this.getParentCollection() != null) {
					strTmp = this.getParentCollection().GroupPropertyValue(GroupId, AttrName);
				}
			} // Supuestamente tiene padre
			if (!StringUtils.IsEmptyString(strTmp)) {
				// Tiene valor
				// K12050901: Permitir activar o desactivar las caches de valores de atributos.
				// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
				// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
				if (cache != null) {
					// Tiene cache
					//synchronized (cache)
					{
						cache.put(AttrName, strTmp);
					}
				} // Tiene cache
				return strTmp;
			} // Tiene valor

			// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
			// Comprobar si hay hojas de estilo para hacer búsquedas...
			if (this.m_ownerApp.hasStylesheets()) {
				// Tiene CSS
				let css: XoneCssRule = null;
				let strClass = xprop.getAttrValue("class");
				if (StringUtils.IsEmptyString(strClass)) strClass = this.m_xmlNode.getAttrValue("class");
				if (!StringUtils.IsEmptyString(strClass)) {
					// Buscar por clase
					// ADD Tag: Luis 1604201801 Modificaco para permitir las clases en cascadas
					// Separadas por coma.
					// TODO: Permitir los atributos con !important se necesita doble pasada
					let clsArr = strClass.split(" ");
					clsArr.reverse();
					for (let i = 0; i < clsArr.length; i++) {
						let cls = clsArr[i].trim();
						if (TextUtils.isEmpty(cls)) continue;
						if (null == (css = this.m_ownerApp.FindStylesheetByClassName("group." + cls, AttrName))) {
							css = this.m_ownerApp.FindStylesheetByClassName("." + cls, AttrName);
						}
						if (css != null) break;
					}
					//	    			if (null ==(css =m_owner.FindStylesheetByClassName ("group." + strClass, AttrName)))
					//	    				css =m_owner.FindStylesheetByClassName("." + strClass, AttrName);
				} // Buscar por clase
				else css = this.m_ownerApp.FindStylesheetByClassName("group", AttrName);
				if (css != null) {
					// Buscar en el stylesheet
					strTmp = css.getRuleValue(AttrName);
					if (!StringUtils.IsEmptyString(strTmp)) {
						// Anda
						// K12050901: Permitir activar o desactivar las caches de valores de atributos.
						// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
						// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
						if (cache != null) {
							// Tiene cache
							//synchronized (cache)
							{
								cache.put(AttrName, strTmp);
							}
						} // Tiene cache
						return strTmp;
					} // Anda
				} // Buscar en el stylesheet
			} // Tiene CSS
			// Cachear idicando que no hay valor.
			// K12050901: Permitir activar o desactivar las caches de valores de atributos.
			// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
			// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
			if (cache != null) {
				// Tiene cache
				//synchronized (cache)
				{
					cache.put(AttrName, Utils.EMPTY_STRING);
				}
			} // Tiene cache
			// F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
			// No es lo mismo cadena vacía que NULL
			return Utils.EMPTY_STRING;
		} // Tiene la propiedad
		// De lo contrario tenemos que ver si es una colección heredada, y llamar a la del padre
		else if (!StringUtils.IsEmptyString(this.Options.strParentCollection)) {
			// Supuestamente tiene padre
			if (this.getParentCollection() == null) {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new XoneGenericException(-17171, "CXoneDataCollection::GroupPropertyValue ha fallado en la colección '" + m_strName + "'. La colección padre ('" + m_strParentCollection + "') no se puede obtener.");
				let str = this.m_messages.GetMessage(
					XoneMessageKeys.SYS_MSG_COLL_XPROPVALUEFAIL,
					"{0} failed for collection '{1}'. Cannot get parent collection '{2}'"
				);
				str = str.replace("{0}", "CXoneDataCollection::GroupPropertyValue");
				str = str.replace("{1}", this.m_strName);
				str = str.replace("{2}", this.Options.strParentCollection);
				throw new XoneGenericException(-17171, str);
			} // Error
			return this.getParentCollection().GroupPropertyValue(GroupId, AttrName);
		} // Supuestamente tiene padre
		// De lo contrario nanai...
		// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
		// Indicar que no hay valor y cachear...
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		// O11110801: Cache multinivel para las propiedades de nodos XML para acelerar la búsqueda.
		// F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
		if (cache != null) {
			// Tiene cache
			//synchronized (cache)
			{
				cache.put(AttrName, Utils.EMPTY_STRING);
			}
		} // Tiene cache
		// F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
		// No es lo mismo cadena vacía que NULL
		return Utils.EMPTY_STRING;
	}

	public CollPropertyValue(AttrName: string) {
		let str = "";
		// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
		// Para los CSS
		// O11081101: Optimizar las estructuras de datos de las caches de atributos inexistentes.
		const ncache = this.m_ownerApp.GetCollPropUndefinedValues(this.m_strName);
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		if (ncache != null) if (ncache.contains(AttrName)) return str; // Cadena vacía.
		const cache = this.m_ownerApp.GetCollPropValueCache(this.m_strName);
		// K12050901: Permitir activar o desactivar las caches de valores de atributos.
		if (cache != null) if (cache.containsKey(AttrName)) return cache.get(AttrName);
		// A11120701: Mecanismo para exponer el DBMS de una conexión.
		// Si es un tipo reservado atenderlo
		if (AttrName.equals("##DBMS##")) return this.BrowseData.getConnection().getDbmsTag();
		// Primero nos hacemos con el platform
		const strPlatform = this.m_ownerApp.getPlatform();
		if (!StringUtils.IsEmptyString(strPlatform)) {
			// Ver si hay nodo platform en la colección
			let nd = null;
			if (this.m_cachePlatformNodes.containsKey(strPlatform)) nd = this.m_cachePlatformNodes.get(strPlatform);
			else {
				this.m_cachePlatformNodes.put(strPlatform, (nd = this.m_xmlNode.SelectSingleNode("platform", "name", strPlatform)));
			}
			if (nd != null) str = nd.getAttrValue(AttrName);
		} // Ver si hay nodo platform en la colección
		if (StringUtils.IsEmptyString(str)) str = XmlUtils.getNodeAttr(this.m_xmlNode, AttrName);
		// Cachear el valor que sea para no tener que montar esta después...
		if (StringUtils.IsEmptyString(str)) {
			// Ver si hay CSS
			// A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
			// Comprobar si hay hojas de estilo para hacer búsquedas...
			if (this.m_ownerApp.hasStylesheets()) {
				// Tiene CSS
				let css: XoneCssRule = null;
				let strClass = this.m_xmlNode.getAttrValue("class");
				if (!StringUtils.IsEmptyString(strClass)) {
					// Buscar por clase
					if (null == (css = this.m_ownerApp.FindStylesheetByClassName("coll." + strClass, AttrName)))
						css = this.m_ownerApp.FindStylesheetByClassName("." + strClass, AttrName);
				} // Buscar por clase
				else css = this.m_ownerApp.FindStylesheetByClassName("coll", AttrName);
				if (css != null) {
					// Buscar en el stylesheet
					let strTmp = css.getRuleValue(AttrName);
					if (!StringUtils.IsEmptyString(strTmp)) {
						// Anda
						// K12050901: Permitir activar o desactivar las caches de valores de atributos.
						if (cache != null) cache.put(AttrName, strTmp);
						return strTmp;
					} // Anda
				} // Buscar en el stylesheet
			} // Tiene CSS
			try {
				if (!StringUtils.IsEmptyString(this.Options.strParentCollection)) {
					// Supuestamente tiene padre
					if (this.getParentCollection() == null) {
						// Error
						// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
						//String str = m_messages.GetMessage(XoneMessageKeys.SYS_MSG_COLL_XPROPVALUEFAIL, "{0} failed for collection '{1}'. Cannot get parent collection '{2}'.");
						//str = str.replace("{0}", "CXoneDataCollection::NodePropertyValue");
						//str = str.replace("{1}", m_strName);
						//str = str.replace("{2}", m_strParentCollection);
						//throw new XoneGenericException(-17171, str);
					} // Error
					else {
						////throw new XoneGenericException(-17171, "CXoneDataCollection::FieldPropertyValue ha fallado en la colección '" + m_strName + "'. La colección padre ('" + m_strParentCollection + "') no se puede obtener.");
						return this.getParentCollection().CollPropertyValue(AttrName);
					}
				}
			} catch (ee) {
				console.error(ee);
			}
			// Finalmente no tenemos nada...
			// K12050901: Permitir activar o desactivar las caches de valores de atributos.
			// if (ncache !=null)
			// 	ncache.add(AttrName);
			str = "";
		} // Propiedad que no está
		else {
			// Está
			// K12050901: Permitir activar o desactivar las caches de valores de atributos.
			// if (cache !=null)
			// 	cache.put(AttrName, str);
		} // Está
		return str;
	}

	public async getFirstParentCollection(): Promise<XoneDataCollection> {
		// Directamente, si ya está cargada, la cargamos aquí...
		if (this.m_parent != null) return this.m_parent;
		// Si no tiene colección padre, NULL
		if (StringUtils.IsEmptyString(this.Options.strParentCollection)) return null;
		// De lo contrario trataremos de buscar el propietario...
		if (this.m_strName.equals(this.Options.strParentCollection))
			throw new XoneGenericException(
				-2322,
				"CXoneDataCollection::ParentCollection has failed for collection " + this.m_strName + ". Cannot define a parent collection as its own."
			);
		// Cargar la colección
		return await this.m_ownerApp.getCollection(this.Options.strParentCollection);
	}

	public getParentCollection(): XoneDataCollection {
		// Directamente, si ya está cargada, la cargamos aquí...
		//     if (this.m_parent != null) return this.m_parent;
		//      // Si no tiene colección padre, NULL
		//      if (StringUtils.IsEmptyString(this.Options.strParentCollection))
		//      return null;
		//  // De lo contrario trataremos de buscar el propietario...
		//     if (this.m_strName.equals(this.Options.strParentCollection))
		//         throw new XoneGenericException(-2322, "CXoneDataCollection::ParentCollection has failed for collection " + this.m_strName + ". Cannot define a parent collection as its own.");
		//     // Cargar la colección
		//     this.m_parent = await this.m_ownerApp.getCollection(this.Options.strParentCollection);
		return this.m_parent;
	}

	ExtractConnections(): boolean {
		let xl = this.m_xmlNode.SelectNodes("connection") as XmlNodeList;
		// Buscar cada conexión
		for (let i = 0; i < xl.count(); i++) {
			// Cargar los datos de cada conexión
			let xn = xl.get(i);
			// F12051401: Cuando se extraen las conexiones de una colección se pueden repetir.
			// Las conexiones solo las creamos si hace falta, claro está
			const strName = xn.getAttrValue("name");
			if (!StringUtils.IsEmptyString(strName)) {
				// Solo las creamos si tienen nombre
				if (this.m_ownerApp.getConnection(strName) == null) {
					// La creamos
					if (!this.m_ownerApp.LoadConnection(xn)) return false;
				} // La creamos
			} // Solo las creamos si tienen nombre
		} // Cargar los datos de cada conexión
		return true;
	}

	public GetNodeList(NodeName: string, AttrName?: string, AttrValue?: string, Exist?: boolean): XmlNodeList {
		return this.getNodeList(NodeName, AttrName, AttrValue, Exist);
	}

	public getNodeList(NodeName: string, AttrName?: string, AttrValue?: string, Exist?: boolean): XmlNodeList {
		// SI no hay nodo... nanai...
		if (this.m_xmlNode == null) return null;
		return this.m_xmlNode.SelectNodes(NodeName, AttrName, AttrValue, Exist);
	}

	/**
	 * @param NodeName		Nombre del nodo que se quiere buscar.
	 * @param AttrName		Nombre del atributo que se quiere comparar.
	 * @param AttrValue		Valor que tiene que tener el atributo para reconocer un nodo como válido.
	 * @return				Devuelve un nodo XML dentro del nodo de esta colección con un atributo cuyo valor se solicita.
	 */
	public GetNode(NodeName: string, AttrName?: string, AttrValue?: string): XmlNode {
		return this.getNode(NodeName, AttrName, AttrValue);
	}

	public getNode(NodeName: string, AttrName?: string, AttrValue?: string): XmlNode {
		// No tiene nodo, no hacemos nada
		if (this.m_xmlNode == null) return null;
		// A15091003
		let node = this.m_xmlNode.SelectSingleNode(NodeName, AttrName, AttrValue);
		if (node == null) {
			// De lo contrario tenemos que ver si es una colección heredada, y llamar a la del padre
			if (!StringUtils.IsEmptyString(this.m_options.strParentCollection)) {
				// Supuestamente tiene padre
				try {
					let parentCollection = this.getParentCollection();
					if (parentCollection != null) {
						// Error
						node = parentCollection.getNode(NodeName, AttrName, AttrValue);
					} // Error
				} catch (e) {
					console.error(e);
				}
			} // Supuestamente tiene padre
		}
		return node;
	}

	public getNodeAttr(NodeName: string, AttrName: string): string {
		// No tiene nodo, no hacemos nada
		if (this.m_xmlNode == null) return null;
		// A15091003
		let node = this.getNode(NodeName);
		if (node == null) {
			// De lo contrario tenemos que ver si es una colección heredada, y llamar a la del padre
			if (!StringUtils.IsEmptyString(this.m_options.strParentCollection)) {
				// Supuestamente tiene padre
				try {
					let parentCollection = this.getParentCollection();
					if (parentCollection != null) {
						// Error
						node = parentCollection.getNode(NodeName);
					} // Error
				} catch (e) {
					console.error(e);
				}
			} // Supuestamente tiene padre
		}
		if (node == null) return null;
		let attrValue = node.getAttrValue(AttrName);
		if (attrValue == null && !StringUtils.IsEmptyString(this.m_options.strParentCollection)) {
			// Supuestamente tiene padre
			try {
				let parentCollection = this.getParentCollection();
				if (parentCollection != null) {
					// Error
					attrValue = parentCollection.getNodeAttr(NodeName, AttrName);
				} // Error
			} catch (e) {
				console.error(e);
			}
		} // Supuestamente tiene padre
		return attrValue;
	}

	async Load(): Promise<boolean> {
		// Si no hay nodo XML se trata de una colección intermedia que no lleva plantilla
		if (this.m_xmlNode === null) {
			return true;
		}

		// // Si la colección no tiene nombre nos explotamos...
		// // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
		// if (
		//     StringUtils.IsEmptyString(
		//         (this.m_strName = this.m_xmlNode.getAttrValue("name"))
		//     )
		// )
		//     ////throw new Exception("No se puede crear una colección sin nombre o con nombre vacío.");
		//     throw new Exception(
		//         this.m_messages.GetMessage(
		//             XoneMessageKeys.SYS_MSG_COLL_NONAME,
		//             "Cannot create a collection with an empty name."
		//         )
		//     );
		// Obtener la declaración de conexiones que haya en la colección
		let str: string;
		if (!this.ExtractConnections()) {
			Utils.DebugLog(Utils.TAG_FRAMEWORK, "DataCollection.Load(): Cannot extract connections from collection " + this.m_xmlNode);
			return false;
		}
		// Leer otras cosas del nodo XML para tenerlas ya en variables antes de usarlas...
		// Booleanas
		this.m_options.bLoadLayouts = StringUtils.ParseBoolValue(this.CollPropertyValue("load-layouts"), true);
		// K11092602: Modificaciones para trabajar con conexiones online puras.
		// Obtener el valor de atributo que indica si la colección es indexada...
		this.m_options.bisIndexed = StringUtils.ParseBoolValue((str = this.CollPropertyValue("indexed")), false);
		this.m_options.bCheckOwner = StringUtils.ParseBoolValue((str = this.CollPropertyValue("check-owner")), false);
		this.m_options.bIsSpecial = StringUtils.ParseBoolValue((str = this.CollPropertyValue("special")), false);
		this.m_options.bStringPk = StringUtils.ParseBoolValue((str = this.CollPropertyValue("stringkey")), false);
		this.m_options.bObjLoadAll = StringUtils.ParseBoolValue((str = this.CollPropertyValue("obj-loadall")), true);
		this.m_options.bVolatile = StringUtils.ParseBoolValue(this.CollPropertyValue("volatile"), false);
		// En esta plataforma tendremos carga diferida por defecto
		this.m_options.bDeferredLoad = StringUtils.ParseBoolValue(this.CollPropertyValue("deferred-load"), true);
		this.m_options.bDebug = StringUtils.ParseBoolValue(this.CollPropertyValue("sql-debug"), false);
		// En esta plataforma tendremos por defecto que no se soportan subqueries.
		this.m_options.bUseRaw = StringUtils.ParseBoolValue((str = this.CollPropertyValue("userawsql")), true);
		if (StringUtils.IsEmptyString(str)) {
			// Si UseRaw no está definido en la colección tenemos que sacarlo del DBMS
			if (this.BrowseData.getConnection() != null) {
				// Sacar el dato de los subqueries de allí
				this.m_options.bUseRaw = !this.BrowseData.getConnection().getSubqueries();
			} // Sacar el dato de los subqueries de allí
		} // Si UseRaw no está definido en la colección tenemos que sacarlo del DBMS
		// TAG 3010201501 Permitir usar el atributo sql sin parsearlo. Ojo si se sustituyen las macros
		this.m_options.bParseSQL = StringUtils.ParseBoolValue(this.CollPropertyValue("sql-parser"), true);
		// Cadenillas
		this.m_strPk = "ID";
		if (!StringUtils.IsEmptyString((str = this.CollPropertyValue("idfieldname")))) {
			// Guardar el nombre del campo clave
			this.m_strPk = str;
			// K11010501:	Modificaciones para la versión 1.5 de Android.
			this.m_options.nPkType = StringUtils.ParseBoolValue(this.CollPropertyValue("stringkey"), false) ? 1 : 0;
			// Comprobar si se trata de una clave múltiple
			if (this.m_strPk.contains(",")) {
				// Indicar que tenemos varias claves y separarlas
				this.m_options.bMultipleKey = true;
			} // Indicar que tenemos varias claves y separarlas
		} // Guardar el nombre del campo clave
		if (!StringUtils.IsEmptyString((str = this.CollPropertyValue("object-prefix")))) this.m_options.strPrefix = str;
		// Sacar los filtros
		this.m_strLinkFilter = this.CollPropertyValue("filter");
		this.m_strSort = this.CollPropertyValue("sort");
		this.m_options.strLookupMacroName = this.CollPropertyValue("lookup-macro");
		// SQL de la colección
		this.m_options.strAccessString = this.CollPropertyValue("sql");
		if (!StringUtils.IsEmptyString(this.m_options.strAccessString)) this.m_options.bSingleObject = this.m_options.strAccessString.indexOf(" ") == -1;
		// ProgId
		this.m_options.strProgId = this.CollPropertyValue("progid");
		this.m_options.strClassName = this.CollPropertyValue("classname");
		this.m_options.strParentCollection = this.CollPropertyValue("inherits");
		// *
		//  * TODO 02/10/2018
		//  * Si una colección se define a sí misma como padre, ignorarlo.
		//  *
		if (!TextUtils.isEmpty(this.m_options.strParentCollection) && this.m_options.strParentCollection.equals(this.m_xmlNode.getName())) {
			this.m_options.strParentCollection = Utils.EMPTY_STRING;
		}
		// Si no tiene colección padre, NULL
		if (!StringUtils.IsEmptyString(this.m_options.strParentCollection)) {
			// De lo contrario trataremos de buscar el propietario...
			if (!StringUtils.areEquals(this.m_strName, this.m_options.strParentCollection)) {
				// throw new XoneGenericException(
				//     -2322,
				//     "CXoneDataCollection::ParentCollection has failed for collection " +
				//     this.m_strName +
				//     ". Cannot define a parent collection as its own."
				// );
				// Cargar la colección
				this.m_parent = await this.getFirstParentCollection();
				// this.m_parent = await this.m_ownerApp.getCollection(
				//     this.m_options.strParentCollection
				// );
			}
		}
		// threshold
		if (!StringUtils.IsEmptyString((str = this.CollPropertyValue("threshold")))) this.m_options.nThreshold = NumberUtils.SafeToInt(str);
		if (!StringUtils.IsEmptyString((str = this.CollPropertyValue("maxrows")))) this.m_options.nMaxRows = NumberUtils.SafeToInt(str);
		this.m_options.strObjectName = this.CollPropertyValue("objname");
		// F11112404: La colección que no tenga objectname debería usar un valor por defecto.
		if (StringUtils.IsEmptyString(this.m_options.strObjectName)) {
			// Puede ser un error
			if (!this.m_options.bIsSpecial) {
				// Error
				this.m_options.strObjectName = this.m_xmlNode.getName();
			} // Error
		} // Puede ser un error
		this.m_options.strUpdateObject = this.CollPropertyValue("updateobj");
		// De otra naturaleza...
		// Comprobar si tiene propiedades xlat
		if (this.getParentCollection() != null) this.m_options.bHasXlatProps = false; // this.getParentCollection().getHasXlatProperties ();
		if (!this.m_options.bHasXlatProps) {
			// Leerlo de las propiedades locales
			const list = this.getNodeList("prop", "xlat", null, true);
			if (list.count() > 0) this.m_options.bHasXlatProps = true;
		} // Leerlo de las propiedades locales
		// O12050702: Optimización al gestionas los campos que son de fórmula o bit.
		// Guarda las propiedades que son fórmulas o bits...
		let lst = this.m_xmlNode.SelectNodes("prop", "formula", Utils.EMPTY_STRING, true);
		for (let i = 0; i < lst.count(); i++) {
			// Copiar
			let node = lst.get(i);
			this.m_lstFormulaProps.push(node.getAttrValue("name"));
		} // Copiar
		lst = this.m_xmlNode.SelectNodes("prop", "bit", Utils.EMPTY_STRING, true);
		for (let i = 0; i < lst.count(); i++) {
			// Copiar
			let node = lst.get(i);
			this.m_lstBitProps.push(node.getAttrValue("name"));
		} // Copiar

		// TODO ADD TAG 29/03/2016 Juan Carlos Implementacion de nodos include-layout
		//LoadIncludeLayoutNodes();

		// A12122701: Sistema para gestionar la estructura lógica de vistas en la maquinaria.
		// Lanzar una tarea en segundo plano para armar el layout de la colección...
		// TODO ADD TAG
		// Estas cosas solo hay que hacerlas si hace falta y está autorizado
		// if (LoadLayouts())
		// {// Lo soporta
		//     if (m_layout == null)
		//     {// Allá va eso
		//         // Lo creamos por aquello de que no sea nulo...
		//         m_layout = new XoneViewLayoutV2();
		//         // O13061301: Carajal de optimizaciones de todo tipo en maquinaria y demás.
		//         // Mover esto pacá para hacerlo en segundo plano...
		//         // F13022104: El acceso a las fórmulas de visibilidad de campos debe ser concurrente.
		//         // Recolecta las propiedades en una lista que podamos usar para PropertyCount y demás.
		//         // Evita que se llame esta función en entornos concurrentes que puedan traer explotes.
		//         CollectCollProperties();
		//         // Nos creamos el layout de todas las propiedades... que sean visibles, claro
		//         m_layout.build(this, -1);
		//         // A13022107: Mecanismo para gestionar el ColorView de manera abstracta desde la maquinaria.
		//         // Poner el colorview...
		//         m_layout.setColorViewField (m_strColorViewField);
		//         // A13030601: Gestión de campos especiales en el modelo de Views de la maquinaria.
		//         // Campos de inicio y fin de rango de fechas.
		//         m_layout.DateFromField = m_strDateFromField;
		//         m_layout.DateToField = m_strDateToField;
		//         // Adicionar los campos que son subject...
		//         for (int i =0;i <m_lstProperties.count();i++)
		//         {// Si es subject adicionamos
		//             XmlNode n =m_lstProperties.get(i);
		//             if (StringUtils.ParseBoolValue(n.getAttrValue("subject"), false))
		//                 m_layout.AddSubjectField(n.getAttrValue(Utils.PROP_ATTR_NAME));
		//         }// Si es subject adicionamos
		//     }// Allá va eso
		// }// Lo soporta
		// else
		// {// No lo soporta
		//     // Recolecta las propiedades en una lista que podamos usar para PropertyCount y demás.
		//     // Evita que se llame esta función en entornos concurrentes que puedan traer explotes.
		// 	if (!m_bPropsCollected)
		// 		CollectCollProperties();
		// }// No lo soporta
		// TAG 2710201502 Cuando se terminade cargar la coleccion
		// cargar su sincludes, ahora en el scope principal mas adelante veremos
		// LoadIncludeFiles();
		return true;
	}

	public get BitProps() {
		return this.m_lstBitProps;
	}

	public get FormulaProps() {
		return this.m_lstFormulaProps;
	}

	getIsDebugging(): boolean {
		return this.Options.bDebug;
	}

	//#region Obtener objetos de la coleccion

	public getLocalObject(FieldName: string | number | Object, FieldValue: Object = null) {
		return this.BrowseData.GetLocalObject(FieldName, FieldValue);
	}

	public async get(FieldName: string | number | Object, FieldValue: Object = null): Promise<XoneDataObject> {
		return this.getObject(FieldName, FieldValue);
	}

	public async getObject(
		FieldName: string | number | Object,
		FieldValue?: Object,
		UseFilters: boolean = true,
		SearchDb: boolean = true
	): Promise<XoneDataObject> {
		return this.BrowseData.GetObject(FieldName, FieldValue, UseFilters, SearchDb);
	}

	public async findObject(SearchCriteria: string): Promise<XoneDataObject> {
		return await this.BrowseData.findObject(SearchCriteria);
	}
	/**
	 * Genera un ROWID a partir de los datos de réplica y tablas de esta colección
	 * @return			Devuelve un nuevo ROWID para la tabla a que pertenece esta colección.
	 */
	public GenerateRowId(): string {
		return this.getConnection().GenerateRowId(this.getFixedObjectName());
	}

	/**
	 * TRUE si el valor que se pasa como parámetro es un ROWID único (no existe en la tabla afectada ningún registro con este ROWID)
	 * @param RowId				Valor de ROWID que se quiere comprobar en la tabla de esta colección.
	 * @return					Devuelve TRUE si el ROWID no existe en la tabla (se puede usar para un nuevo registro.)
	 * @throws Exception
	 */
	public IsUniqueRowId(RowId: string): boolean {
		// A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
		// El trabajo debería hacerlo la conexión y no nosotros...
		return this.getConnection().isUniqueRowID(this.getFixedObjectName(), RowId);
	}

	/**
	 * @return			Devuelve el nombre del campo ROWID usado por esta colección.
	 */
	public getRowIdFieldName(): string {
		return this.getConnection().getRowIdFieldName();
	}

	/**
	 * @return			Devuelve el nombre del objeto en la fuente de datos ya con el prefijo sustituido y demás.
	 */
	public getFixedObjectName(): string {
		return this.getFixedUpdateObjectName();
	}

	public getFixedUpdateObjectName(): string {
		return this.getConnection().FixObjectName(this.getObjectName());
	}

	public MapField(FieldName: string, DatabaseField: boolean = false): string {
		return this.BrowseData.MapField(FieldName, DatabaseField);
	}

	public async GetResultSet(FieldName: string, FieldValue: any): Promise<IResultSet> {
		return await this.BrowseData.GetResultSet(FieldName, FieldValue);
	}

	public DevelopObjectValue(Value: any, ForceNulls: boolean = true): string {
		return this.BrowseData.DevelopObjectValue(Value, ForceNulls);
	}
	public getObjectName(): string {
		return this.Options.strObjectName;
	}

	/**
	 * Adiciona un objeto a la colección
	 * @param Item			Objeto de datos que se va a adicionar a la colección.
	 * @param Index			Indice en el cual se quiere insertar el objeto. Si es mayor que la cantidad de elementos lo inserta al final.
	 * @return				Devuelve TRUE si se ha insertado el objeto correctamente.
	 * @throws Exception
	 */
	public addItem(Item: XoneDataObject, Index: number = -1): boolean {
		return this.BrowseData.AddItem(Item, Index);
	}

	//#endregion

	/**
	 * TRUE si el campo cuyo nombre se indica es un campo enlazado (tiene mapcol)
	 * @param FieldName			Nombre del campo que se quiere comprobar.
	 * @return					Devuelve TRUE si el campo es enlazado de verdad.
	 */
	public IsLinkedField(FieldName: string): boolean {
		try {
			// Ver si tiene mapcol.
			if (StringUtils.IsEmptyString(this.FieldPropertyValue(FieldName, "mapcol"))) return false;
			// Ver si tiene mapfld
			if (StringUtils.IsEmptyString(this.FieldPropertyValue(FieldName, "mapfld"))) return false;
			// OK
			return true;
		} catch (e) {
			// Las excepciones nos cagamos en ellas
			return false;
		}
	}

	public getIdFieldName(): string {
		return this.m_strPk;
	}

	/**
	 * K11010501:	Modificaciones para la versión 1.5 de Android.
	 * @return
	 */
	public getIdFieldType(): number {
		return this.m_nPkType;
	}

	public getAccessString(): string {
		return this.m_options.strAccessString;
		// Si no tiene ningún caracter de macro, no tenemos que sustituir nada...
		// if (!EvalMacros || !str.contains("##")) return str;
		// return await this.m_macrosEvaluator.EvaluateAllMacros(str);
	}

	// public setAccessString(value: string) { this.m_options.strAccessString = value; }

	public get accessString(): string {
		return this.m_options.strAccessString;
	}

	getCollPropertyValue(propiedad: string) {
		return this.CollPropertyValue(propiedad);
	}

	/**
	 * @return			Devuelve TRUE si el campo clave principal de la colección es de tipo texto.
	 */
	public getStringKey(): boolean {
		return this.m_options.bStringPk;
	}

	public getLookupMacroName() {
		return this.m_options.strLookupMacroName;
	}

	public async EvaluateAllMacros(Sentence: string, EvalLookupMacro: boolean = false): Promise<string> {
		return await this.m_macrosEvaluator.EvaluateAllMacros(Sentence, EvalLookupMacro);
	}

	/**
	 * @param sVariableName Nombre de la variable cuyo valor se quiere leer.
	 * @return Devuelve la variable cuyo nombre se pasa como parámetro. Si la variable no existe devuelve NULL.
	 */

	private SafeGetVariableList(): Hashtable<string, Object> {
		if (this.m_lstVariables != null) return this.m_lstVariables;
		return (this.m_lstVariables = new Hashtable<string, Object>());
	}

	public getVariables(sVariableName: string): Object {
		return this.getVariable(sVariableName);
	}

	public getVariable(sVariableName: string): Object {
		if (!this.SafeGetVariableList().containsKey(sVariableName)) {
			return null;
		}
		return this.SafeGetVariableList().get(sVariableName);
	}

	/**
	 * Asigna valor a la variable cuyo nombre se pasa como parámetro.
	 * K11011201:	Modificaciones en el tratamiento de las variables.
	 * Poner esto público
	 *
	 * @param sVariableName Nombre de la variable que se quiere asignar.
	 * @param value         Valor que se quiere asignar a la variable.
	 */
	public setVariables(sVariableName: string, value: Object): void {
		this.setVariable(sVariableName, value);
	}

	/**
	 * Asigna valor a la variable cuyo nombre se pasa como parámetro.
	 * K11011201:	Modificaciones en el tratamiento de las variables.
	 * Poner esto público
	 *
	 * @param sVariableName Nombre de la variable que se quiere asignar.
	 * @param value         Valor que se quiere asignar a la variable.
	 */

	public setVariable(sVariableName: string, value: Object): void {
		// F12042505: Si se pasa NULL a SetVariables hay moña con las listas de valores.
		if (value == null) {
			if (this.SafeGetVariableList().containsKey(sVariableName)) {
				this.SafeGetVariableList().delete(sVariableName);
			}
		} else {
			this.SafeGetVariableList().put(sVariableName, value);
		}
	}

	/**
	 *
	 * @param AccessString
	 */
	public ParseMacros(AccessString: string): void {
		// if (!this.m_lstMacros.containsKey(MacroName)) return null;
		// return this.m_lstMacros.get(MacroName);
		this.m_macrosEvaluator.ParseMacros(AccessString);
	}

	/**
	 * @return				Devuelve el valor de la macro cuyo nombre se pasa como parámetro.
	 * @param MacroName		Nombre de la macro cuyo valor se quiere conocer
	 */
	public getMacro(MacroName: string): string {
		// if (!this.m_lstMacros.containsKey(MacroName)) return null;
		// return this.m_lstMacros.get(MacroName);
		return this.m_macrosEvaluator.getMacro(MacroName);
	}

	/**
	 * @return				Devuelve el valor de la macro cuyo nombre se pasa como parámetro.
	 * @param MacroName		Nombre de la macro cuyo valor se quiere conocer
	 */
	public getMacros(): object {
		// if (!this.m_lstMacros.containsKey(MacroName)) return null;
		// return this.m_lstMacros.get(MacroName);
		// return this.m_macrosEvaluator.getMacros();

		// Amiyares 13/08/2021, agrego resolución de macros
        const macros = this.m_macrosEvaluator.getMacros();
        Object.entries(macros).forEach(([key, value]) => macros[key] = this.BrowseData.PrepareSqlString(value));
        return macros;
	}

	/**
	 * Asigna valor a la macro cuyo nombre se pasa como parámetro.
	 * @param MacroName		Nombre de la macro que se quiere asignar.
	 * @param MacroValue	Valor que se quiere asignar a la macro. Si se pasa NULL se sustituirá por cadena vacía.
	 */
	public setMacro(MacroName: string, MacroValue: string) {
		// let value = MacroValue;
		// if (value == null) value = "";
		this.m_macrosEvaluator.setMacro(MacroName, MacroValue); //.m_lstMacros.put(MacroName, value);
		// F10051901:   Cuando se modifican macros hay que descartar el SQL parseado.
		// Invalidar el sql parseado
		// if (this.accessString != null)
		//     if (this.accessString.contains(MacroName))
		//         this.BrowseData.clearParsedAccessString();
	}

	public get strPk(): string {
		return this.m_strPk;
	}
	public set strPk(value: string) {
		this.m_strPk = value;
	}

	public GetMessage(sKey: string, sDefault: string): string {
		return this.m_messages.GetMessage(sKey, sDefault);
	}

	public get Options(): DataCollectionOptions {
		return this.m_options;
	}

	public async createObject(initialize: object = null, addToColl: boolean = false): Promise<XoneDataObject> {
		let obj = await this.CreateObject();
		if (addToColl) {
			this.addItem(obj);
		}
		if (typeof initialize == "object") {
			for (let key in initialize) {
				// TAG: 19032019 Luis para cuando se inicializa por un native object poder convertir bien
				let value = initialize[key];
				// if (value instanceof ConsString) {
				//     value = TypeConverter.toJava((ConsString) value);
				// } else if (value instanceof NativeDate) {
				//     value = TypeConverter.toJava((NativeDate) value);
				// }
				obj.put(key.toString(), value);
			}
		}
		return obj;
	}

	/**
	 * Crea un objeto de datos usando el ClassName o ProgId de la colección.
	 * @param CreateNew		TRUE si se quiere crear un objeto nuevo. FALSE para crear un objeto que se cargará de base de datos.
	 * @return				Devuelve el objeto recién creado. NULL si no se puede crear el objeto o no se conoce la clase.
	 * @throws XoneGenericException
	 */
	public async CreateObject(CreateNew: boolean = true): Promise<XoneDataObject> {
		let item: XoneDataObject = null;
		//
		// Primero tenemos que ver si tenemos declarado un nombre de clase
		let strClassName: string = Utils.EMPTY_STRING;
		if (StringUtils.IsEmptyString((strClassName = this.Options.strClassName)))
			strClassName = this.Options.strClassName = this.m_ownerApp.GetClassName(this.Options.strProgId);
		else strClassName = this.m_ownerApp.CheckClassName(this.Options.strClassName);
		// A12122701: Sistema para gestionar la estructura lógica de vistas en la maquinaria.
		// Si el layout no está construido del todo entonces tendremos que esperar
		// TODO ADD TAG
		// if (m_layout !=null)
		// 	m_layout.waitUntilReady();
		// F10100704:	Protección contra ProgIds vacíos en las colecciones.
		// Si el progid está vacío será el de toda la vida
		// Aquí habrá que hacer algo para aquellas clases que sean diferentes... por el momento creamos
		// solamente el equivalente del BasicDataObj
		// Esto hay que cacharrearlo su poco
		// TODO Ver si esto se puede hacer con alguna forma de carga por nombre o algo así.
		if (StringUtils.IsEmptyString(strClassName)) item = new XoneDataObject(this);
		else if (strClassName.equalsIgnoreCase("xonedataobject")) item = new XoneDataObject(this);
		else if (strClassName.equalsIgnoreCase("xoneuser")) item = new XoneUser(this);
		else if (strClassName.equalsIgnoreCase("xonecompany")) item = new XoneCompany(this);
		else item = new XoneDataObject(this);
		await item.OnCreate(CreateNew); // Está creando el objeto nuevo...
		return item;
	}

	public getOwnerApp(): XoneApplication {
		return this.m_ownerApp;
	}

	public getMultipleKey(): boolean {
		return this.Options.bMultipleKey;
	}

	/**
	 * @return			Devuelve una lista de claves cuando la colección tiene claves múltiples
	 */
	public getKeyFields(): Vector<string> {
		return this.m_lstKeyFields;
	}

	/**
	 * Devuelve el tipo de la propiedad o campo cuyo nombre se pasa como parámetro.
	 * @param FieldName			Nombre del campo o propiedad cuyo tipo se quiere conocer.
	 * @return					Devuelve el tipo declarado en la propiedad o "absent" si no está definida.
	 * @throws Exception
	 */
	public PropType(FieldName: string): string {
		// Primero buscarlo en esta colección...
		let strType = this.FieldPropertyValue(FieldName, "type");
		// K11010501:	Modificaciones para la versión 1.5 de Android.
		// Si es la  clave primaria será texto o número
		if (FieldName.equals(this.m_strPk)) return this.Options.bStringPk ? "T" : "N";
		// De lo contrario asumimos que es texto
		if (StringUtils.IsEmptyString(strType)) strType = "T";
		return strType;
	}

	/**
	 * Devuelve la visibilidad de una propiedad teniendo en cuenta plataformas y demás.
	 * @param FieldName			Nombre del campo o propiedad cuya visibilidad se quiere conocer.
	 * @return					Devuelve el valor de "visible" para este campo, o "1" si no está definido.
	 * @throws Exception
	 */
	public PropVisibility(FieldName: string): string {
		let str = this.FieldPropertyValue(FieldName, "visible");
		// A09100501:   Permitir que las propiedades tengan un tipo button.
		// En los button la visibilidad venía como true o false, así que tenemos que permitirlo para mantener
		// la compatibilidad...
		// Si no hay plataforma, o el atributo no está definido, buscarlo en el original
		if (StringUtils.IsEmptyString(str)) str = "1";
		else {
			// Caso especial true/false
			if (str.equals("true")) str = "1";
			else if (str.equals("false")) str = "0";
		} // Caso especial true/false
		return str;
	}

	isIndexed() {
		return false;
	}
	getProperties() {
		return this.m_xmlNode;
	}

	/**
	 * Esta función devuelve la bandera que indica si hay que usar objetos en la restauración de mapeos
	 * cuando se trata de colecciones complejas en las que hay varios niveles de objetos en la restauración.
	 * @return		Devuelve el valor de la bandera (TRUE por defecto, se cambia durante la ejecución si es necesario)
	 */
	public getUseObjectsInRestore(): boolean {
		return this.m_bUseObjectsInRestore;
	}

	/**
	 * Asigna valor a la bandera que indica que en vez de lanzar una SQL y devolver una fila de base de datos hay
	 * que cargar el objeto y utilizarlo tal cual porque en las restauraciones se usan campos que están a varios niveles
	 * de anidamiento.
	 * @param value		TRUE para usar objetos, FALSE para utilizar una fila en base de datos (más rápido y eficiente)
	 */
	public setUseObjectsInRestore(value: boolean) {
		this.m_bUseObjectsInRestore = value;
	}

	PropertyTitle(FieldName: string): string {
		let strProp = "",
			str = "";
		let node: XmlNode = null,
			platNode: XmlNode = null;

		if (!StringUtils.IsEmptyString((strProp = this.FieldPropertyValue(FieldName, "title")))) return strProp; // Este mismo y pa la pinga
		// M10090801:	Modificaciones para permitir que los <button> sean considerados propiedades.
		// El atributo caption también vale (en el caso de los botones, sentiende)
		if (!StringUtils.IsEmptyString((strProp = this.FieldPropertyValue(FieldName, "caption")))) return strProp;
		//
		// Buscarlo sin más...
		let coll: XoneDataCollection = this;
		do {
			if (null != (node = coll.getNode("prop", "name", FieldName))) break;
			// M10090801:	Modificaciones para permitir que los <button> sean considerados propiedades.
			// Comprobar si se trata de un botoncejo antiguo
			if (null != (node = coll.getNode("button", "name", FieldName))) break;
			coll = coll.getParentCollection();
		} while (coll != null);
		if (node == null) return FieldName;
		// Cambiar el chequeo pues el puntero puede ser nulo. No se soportan
		// cadenas vacías.
		if (!StringUtils.IsEmptyString((str = this.getOwnerApp().getPlatform()))) {
			// Buscar el nodo
			platNode = node.SelectSingleNode("platform", "name", str);
		} // Buscar el nodo
		str = "";
		// M10090801:	Modificaciones para permitir que los <button> sean considerados propiedades.
		// En caso de los botones, vale caption también
		if (platNode != null) {
			// Comprobar title y caption
			if (StringUtils.IsEmptyString((str = XmlUtils.getNodeAttr(platNode, "title")))) str = platNode.getAttrValue("caption");
		} // Comprobar title y caption
		if (StringUtils.IsEmptyString(str)) {
			// No está en el nodo de plataforma
			str = XmlUtils.getNodeAttr(node, "title");
			if (StringUtils.IsEmptyString(str)) {
				// Si no tiene title, ver si tiene texto
				// M10090801:	Modificaciones para permitir que los <button> sean considerados propiedades.
				// Comprobar también caption, que para los botones vale lo mismo
				str = node.getAttrValue("caption");
				if (StringUtils.IsEmptyString(str)) str = node.getText();
				if (StringUtils.IsEmptyString(str)) {
					// Si es un botón, no devolver el nombre
					// F10100708:	Los botones que no tengan texto deberían devolver título vacío.
					// Cambiar este mecanismo porque puede explotarse si no hay Type.
					let bButton = false;
					if (node.getName().equals("button")) bButton = true;
					else {
						// Obtener el tipo
						let strType = node.getAttrValue("type");
						if (!StringUtils.IsEmptyString(strType)) {
							// Ver si es un botón
							if (strType.equals("B")) bButton = true;
						} // Ver si es un botón
					} // Obtener el tipo
					if (!bButton) str = FieldName;
				} // Si es un botón, no devolver el nombre
			} // Si no tiene title, ver si tiene texto
		} // No está en el nodo de plataforma

		return StringUtils.SafeToString(str);
	}

	IsFormulaProperty(FieldName: string): boolean {
		if (this.m_lstFormulaProps.length == 0) return false;
		return this.m_lstFormulaProps.contains(FieldName) ? true : false;
	}

	IsBitProperty(FieldName: string): boolean {
		if (this.m_lstBitProps.length == 0) return false;
		return this.m_lstBitProps.contains(FieldName) ? true : false;
	}

	getDeferredLoad(): boolean {
		return this.Options.bDeferredLoad;
	}

	GetCurrentItemValue(FieldName: string): any {
		throw new Error("Method not implemented.");
	}

	public FieldExists(FieldName: string): boolean {
		// Buscar si el campo existe en la lista del nodo XML
		if (null != this.m_xmlNode.SelectSingleNode("prop", "name", FieldName)) return true;
		// M10090801:	Modificaciones para permitir que los <button> sean considerados propiedades.
		// Lo mismo con los botones
		if (null != this.m_xmlNode.SelectSingleNode("button", "name", FieldName)) return true;
		// F10052502:	FieldExists no debe comprobar existencia del campo clave.
		if (this.m_strPk.equals(FieldName)) return true;
		// Buscar si heredamos de alguna, en ese caso buscar en el parent
		if (!StringUtils.IsEmptyString(this.Options.strParentCollection)) {
			// Comprobar
			if (this.getParentCollection() == null) {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new XoneGenericException(-17778, "CXoneDataCollection::FieldExists ha fallado. La colección '" + m_strName + "' hereda de '" + m_strParentCollection + "' y no se encuentra la colección padre.");
				let sb = this.m_messages.GetMessage(
					XoneMessageKeys.SYS_MSG_COLL_FIELDEXISTSFAIL,
					"CXoneDataCollection::FieldExists failed. Collection '{0}' inherits '{1}' and it cannot be found."
				);
				sb = sb.replace("{0}", this.m_strName);
				sb = sb.replace("{1}", this.Options.strParentCollection);
				throw new XoneGenericException(-17778, sb);
			} // Error
			return this.getParentCollection().FieldExists(FieldName);
		} // Comprobar
		// De lo contrario.. nanai...
		return false;
	}

	getSpecial() {
		return this.isSpecial;
	}

	public get isSpecial(): boolean {
		return this.m_options.bIsSpecial;
	}

	public getObjectIndex(Item: XoneDataObject): number {
		return this.BrowseData.ObjectIndex(Item);
	}

	/**
	 * K11010501:	Modificaciones para la version 1.5 de Android.
	 *
	 * TODO 24/01/2019 Juan Carlos
	 * Esta función no está teniendo en cuenta los types que inventamos posteriormente que no
	 * siguen la regla de la primera letra indicado el tipo de dato en base de datos. Corrijo el
	 * switch y pongo constantes públicas, que el resto del código debería empezar a usar. Dejo la
	 * función antigua comentada abajo por si acaso.
	 *
	 * PROP_TYPE_INTEGER = Integer (Type N)
	 * PROP_TYPE_STRING = String (Type T, IMG, PH, DR)
	 * PROP_TYPE_DOUBLE = Double (Type N2, N3, N4, etc...)
	 * PROP_TYPE_DATE = Fecha (Type D, DT, TT)
	 * PROP_TYPE_RESULTSET = ResultSet (Type Z) Su valor es 0 como integer, no sé porqué esto está
	 * así pero seguramente sea malo.
	 *
	 * @param sTypeName
	 * @return
	 */

	public getTypeFromXml(sTypeName: string): number {
		if (TextUtils.isEmpty(sTypeName)) {
			return XoneDataCollection.PROP_TYPE_STRING;
		}
		switch (sTypeName) {
			case "T":
			case "TN":
			case "DR":
			case "IMG":
			case "PH":
			case "TT":
				return XoneDataCollection.PROP_TYPE_STRING;
			case "N":
			case "NC":
				return XoneDataCollection.PROP_TYPE_INTEGER;
			case "D":
			case "DT":
				return XoneDataCollection.PROP_TYPE_DATE;
			case "Z":
				// A11092602: Permitir el uso de resultsets jerárquicos en forma de árbol para cargas múltiples.
				return XoneDataCollection.PROP_TYPE_RESULTSET;
			default:
				let sTypeFirstLetter = sTypeName.charAt(0);
				if (sTypeFirstLetter == "N") {
					return XoneDataCollection.PROP_TYPE_DOUBLE;
				}
				return XoneDataCollection.PROP_TYPE_STRING;
		}
	}

	/**
	 *  Bloquea la colección para que no se pueda limpiar ni recargar
	 */
	public lock(): void {
		this.m_bLocked = true;
	}

	/**
	 *  Desbloquea la colección para que se puede vaciar y/o llenar
	 */
	public unlock(): void {
		this.m_bLocked = false;
	}

	public isLock(): boolean {
		return this.m_bLocked;
	}

	/**
	 * Limpia la colección de los objetos que hay en memoria. Si la colección está bloqueada no hace nada.
	 * @return			TRUE si la operación es correcta.
	 */
	public clear(): boolean {
		// Si está bloqueada, no hacemos nada...
		if (this.m_bLocked) return true;
		return this.ClearCollection();
	}

	/**
	 * Efectúa la limpieza real de la colección aunque esta está bloqueada
	 * @return		Devuelve TRUE si la colección se limpia correctamente.
	 */
	private ClearCollection(): boolean {
		if (this.m_bIsClearing || this.m_bLocked) return true; // Ya está dentro...
		try {
			this.m_bIsClearing = true;
			// Ahora limpiar las colecciones porsi...
			this.BrowseData.Clear();
			this.m_bFull = this.m_bIsClearing = false;
			// Si es especial esto es como un EB
			if (this.m_bIsSpecial) this.m_nBrowseLength = -1;
		} catch (e) {
			// Ignorar las excepciones a menos que sea debug
			if (this.Options.bDebug) {
				// Imprimir
				//this.m_ownerApp.WriteConsoleString(e.getMessage ());
				console.log(e.message);
			} // Imprimir
		}
		return true;
	}

	/**
	 * @return			Devuelve la cadena para el ordenamiento de los datos.
	 */
	public getSort(): string {
		return this.m_strSort;
	}

	/**
	 * Asigna valor a la cadena de ordenamiento de los datos de la colección.
	 * @param value		Nuevo valor de la cadena de ordenamiento de los datos de la colección.
	 */
	public setSort(value: string): void {
		this.m_strSort = value;
	}

	public setLinkFilter(str: string): void {
		this.m_strLinkFilter = str;
	}

	/**
	 * @return			Devuelve el filtro interno, leído del atributo filter del mappings y usado para enlazar contents
	 */
	public getLinkFilter() {
		return this.m_strLinkFilter;
	}

	/**
	 * @return			Devuelve el filtro externo usado por los frameworks para limitar las búsquedas
	 */
	public getFilter() {
		return this.m_strFilter;
	}
	/**
	 * Asigna valor al filtro de usuario de la colección.
	 * @param value		Nuevo valor para el filtro de la colección.
	 */
	public setFilter(value: string): void {
		this.m_strFilter = value;
	}

	/**
	 * @return			Devuelve el Objeto propietario de la colección cuando se usa como contents
	 */
	public getOwnerObject(): XoneDataObject {
		return this.m_pOwnerObject;
	}
	/**
	 * Permite asignar el objeto propietario de la colección en tiempo de ejecución.
	 * @param value		Nuevo objeto propietario de la colección (i.e. de la cual es contents)
	 */
	public setOwnerObject(value: XoneDataObject): void {
		this.m_pOwnerObject = value;
	}

	/**
	 * Crea un clon de esta colección (no copia el contenido)
	 * @return				Devuelve la colección creada con los datos de esta colección también clonados
	 * @throws Exception
	 */
	public createClone(): XoneDataCollection {
		return this.CreateClone();
	}

	public CreateClone(): XoneDataCollection {
		let newColl: XoneDataCollection = null;
		// A12122701: Sistema para gestionar la estructura lógica de vistas en la maquinaria.
		// Para clonar nos creamos la nueva colección a partir de esta...
		newColl = new XoneDataCollection(this.getOwnerApp(), this.getProperties(), this.getVersion());
		// // M10051901:  Los scripts deben almacenarse parseados en la maquinaria.
		// // El evento no nos hace falta, así que nos lo cargamos y tal y tal
		// m_propsCollected = null;
		// // Copiamos el layout...
		// //m_layout = Origin.getViewLayout();
		// // O13061301: Carajal de optimizaciones de todo tipo en maquinaria y demás.
		// // Referenciar las propiedades... a fin de cuentas serán las mismas... digo yo...
		// // Con la lista de tipos... más de lo mismo... También copiamos otras cositas.
		// m_lstProperties = this.getPropertyList();
		// m_lstPropTypes = this.getTypeList();
		// m_lstPropTitles = this.getTitleList();
		// m_bPropsCollected = true;   // Ya nos lo ha hecho otro, claro...
		// this.m_parent = this.getParentCollection();
		// // A13071201: Control de macros a nivel de colección de contents.
		// // Si nos han indicado el nombre del contents, lo guardamos aquí
		// this.m_strContentsName = ContentsName;
		/////////////
		// F13022104: El acceso a las fórmulas de visibilidad de campos debe ser concurrente.
		// Copiar las fórmulas
		// if (Origin.HasFormulas())
		// {// Tiene fórmulas
		//     m_formulas = new Hashtable<String, IFormulaParser>();
		//     Enumeration<String> enm =Origin.getFormulas().keys ();
		//     while (enm.hasMoreElements())
		//     {// Copiar
		//     	String key =enm.nextElement();
		//     	IFormulaParser ipf =Origin.getFormulas().get(key);
		//     	if (ipf instanceof FormulaParser)
		//     		m_formulas.put (key, (FormulaParser)ipf);
		//     }// Copiar
		// }// Tiene fórmulas
		// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
		////throw new XoneGenericException(-11123, "XoneDataCollection::CreateClone ha fallado. No se puede crear una nueva colección.");
		////throw new XoneGenericException(-11123, m_messages.GetMessage (XoneMessageKeys.SYS_MSG_COLL_CREATECLONEFAIL, "CXoneDataCollection::CreateClone failed. Cannot create new collection."));
		// Cargar las cosas
		if (!newColl.Load()) return null;
		// En el constructor se copian las cosas que normalmente habría que poner a mano..
		// Estas cosas podrían haber cambiado, así que las asignamos aquí otra vez...
		newColl.setSort(this.m_strSort);
		newColl.setFilter(this.m_strFilter);
		this.CopyMacros(newColl);
		this.CopyVariables(newColl);
		return newColl;
	}

	/**
	 * Copia todas las variables de esta colección hacia la que se pasa como parámetro
	 * @param DestColl		Colección hacia la que se quieren copiar las variables de esta colección.
	 */
	private CopyVariables(DestColl: XoneDataCollection): void {
		this.SafeGetVariableList()
			.entrySet()
			.forEach((item) => DestColl.setVariables(item[0], item[1]));
		// this.m_lstVariables.keys();
		// while (e.hasMoreElements())
		// {// Recorrer la lista de variables y pasarla a la otra colección
		// 	String k =(String) e.nextElement();
		// 	Object v =this.m_lstVariables.get(k);
		//     DestColl.setVariables(k, v);
		// }// Recorrer la lista de variables y pasarla a la otra colección
	}

	/**
	 * Copia todas las macros de esta colección hacia la que se pase como parámetro.
	 * @param DestColl			Colección hacia la que se quieren copiar las macros de esta.
	 */
	public CopyMacros(DestColl: XoneDataCollection): void {
		this.m_macrosEvaluator.CopyMacros(DestColl);
		// this.m_lstMacros
		//     .entrySet()
		//     .forEach((item) => DestColl.setMacro(item[0], item[1]));
		// Enumeration<String> e =this.m_lstMacros.keys();
		// while (e.hasMoreElements())
		// {// Recorrer la lista de variables y pasarla a la otra colección
		// 	String k =e.nextElement();
		// 	String v =(String) this.m_lstMacros.get(k);
		//     DestColl.setMacro(k, v);
		// }// Recorrer la lista de variables y pasarla a la otra colección
	}

	public async loadAll(CountRecords: boolean = false, options?: any): Promise<XoneDataCollection> {
		return this.BrowseData.loadAllAsync(CountRecords, options);
	}

	public async loadAllAsync(CountRecords: boolean = false, options?: any): Promise<XoneDataCollection> {
		return this.BrowseData.loadAllAsync(CountRecords, options);
	}

	public async startBrowse(CountRecords: boolean = false, options?: any): Promise<XoneDataCollection> {
		return this.BrowseData.startBrowse(CountRecords, options);
	}

	public async moveNext(): Promise<boolean> {
		return this.BrowseData.MoveNext();
	}

	public endBrowse(): boolean {
		return this.BrowseData.EndBrowse();
	}

	public get currentItem(): XoneDataObject {
		return this.getCurrentItem();
	}

	public getCurrentItem(): XoneDataObject {
		return this.BrowseData.getCurrentItem();
	}

	public getConnection(): XoneConnectionData {
		return this.BrowseData.getConnection();
	}

	public isFull(): boolean {
		return this.getFull();
	}

	/**
	 * @return		Devuelve TRUE si la colección se ha cargado completamente con LoadAll.
	 */
	public getFull(): boolean {
		return this.m_bFull;
	}

	/**
	 * A11092602: Permitir el uso de resultsets jerárquicos en forma de árbol para cargas múltiples.
	 * Hacer que la colección está llena a la fuerza.
	 * @param isFull
	 */
	public setFull(isFull: boolean): void {
		this.m_bFull = isFull;
	}

	public getCount(): number {
		return this.BrowseData.getCount();
	}

	public count(): number {
		return this.getCount();
	}

	public get length() {
		return this.getCount();
	}

	PrepareSqlString(Sentence: string, ChkGroup: boolean = false, Prefiltered: boolean = false): string {
		return this.BrowseData.PrepareSqlString(Sentence, ChkGroup, Prefiltered);
	}

	// addItem(param0) {

	// }

	// browseDeleteAll() {

	// }

	// browseDeleteWithNoRules() {

	// }

	public browseLength(): number {
		return this.BrowseData.browseLength();
	}

	/**
	 * K13080701: La ejecución de scripts con argumentos a nivel de colección falta en algunos casos.
	 * Esta acción podría llevar argumentos...
	 */
	public async ExecuteCollAction(ActionNode: string, ActionName: string, ...Arguments): Promise<any> {
		return await this.executeCollAction(ActionNode, ActionName, ...Arguments);
	}
	public async executeCollAction(ActionNode: string, ActionName: string, ...Arguments): Promise<any> {
		let node: XmlNode = null;
		let str = "";

		if (StringUtils.IsEmptyString(ActionNode)) return null;
		// Primero tenemos que buscar el nodo correspondiente al prefijo
		let prefix = this.GetNode(ActionNode);
		if (prefix == null) {
			// No existe el nodo
			if (this.Options.bDebug) console.log("Colección '" + this.getName() + "' ExecuteCollAction no encuentra el nodo '" + ActionNode + "'");
			// Esto en sí no es un fallo de ejecución
			return null;
		} // No existe el nodo

		if (!StringUtils.IsEmptyString(ActionName)) node = prefix.SelectSingleNode("action", "name", ActionName);
		else node = prefix.SelectSingleNode("action");

		if (null != node) {
			// Comprobar qué tipo de acción es
			str = XmlUtils.GetNodeAttr(node, "type");
			// F11051805: Permitir que los nodos coll-action no tengan tipo.
			// Por defecto...
			if (StringUtils.IsEmptyString(str)) str = "runscript";
			// K13080701: La ejecución de scripts con argumentos a nivel de colección falta en algunos casos.
			// Pasar los argumentos que nos han mandado (puede ser nulo)
			if (str.equals("runscript")) return await this.doRunScript(node, Arguments);
		} // Comprobar qué tipo de acción es
		else {
			// Si está en debug, dejar traza
			if (this.Options.bDebug) {
				// Mostrar mensaje
				if (StringUtils.IsEmptyString((str = ActionName))) str = "";
				console.log("Colección '" + this.getName() + "' ExecuteCollAction no encuentra el nodo action[name='" + str + "']");
			} // Mostrar mensaje
		} // Si está en debug, dejar traza
		// No hacer nada de nada...
		return null;
	}

	public prepareContext(scope: XoneDataCollection | XoneDataObject, ActionNode: XmlNode | string, ...Arguments): ScriptParams {
		let scripts: XmlNodeList;
		let strScript = "",
			strLang = "",
			str = "";
		let ctx = new ScriptContext(scope);

		// K10090801:	Carga de includes locales en los nodos de acciones.
		// Adicionar scripts locales de los nodos <action>
		// TODO: Luis los includes se manejaran de forma distintas
		// let includes:XmlNodeList = ActionNode.SelectNodes("include");
		// for (let i =0;i <includes.count();i++)
		// {// Cargar todos los includes
		// 	let include =includes.get(i);
		// 	let strFilename =include.getAttrValue("file");

		// 	// 27/02/2017 Los include dentro de un action no hacían caso al language
		//     let strIncludeLanguage = StringUtils.SafeToString(include.getAttrValue("language"), this.getOwnerApp().getDefaultScriptLanguage());

		// 	// Que se pueda definir desde fuera el charset correcto. ISO-8859-1 permite tildes y eñes.
		// 	let strCharsetEncoding = include.getAttrValue("encoding");

		// 	// TODO Permitir que la compilación de scripts se ejecute en background mientras temporalmente ejecutamos en modo intérprete
		//     let bDelayCompilation = StringUtils.ParseBoolValue(include.getAttrValue("delay-compilation"), false);
		//     let bCompile = StringUtils.ParseBoolValue(include.getAttrValue("compile"), true);

		//     if (!StringUtils.IsEmptyString(strFilename))
		// 	{// Cargar el fichero
		//         if(strIncludeLanguage.compareToIgnoreCase(Utils.JAVASCRIPT_LANGUAGE) == 0) {
		//             this.getOwnerApp().LoadJavascriptIncludeFile(strFilename, strCharsetEncoding, bDelayCompilation, bCompile, ctx);
		//         } else {
		//             VbsScript script = this.getOwnerApp().LoadIncludeFile(strFilename, null, "vbscript", strCharsetEncoding);
		//             if (script == null) {// Error
		//                 // TODO PROGRAMAR ESTO
		//                 // Indicar el error
		//                 // Y retornar con error...
		//                 throw new XoneScriptFailedException(-1, "Script execution failed in LoadIncludeFile(), file name " + strFilename);
		//             }// Error
		//             // Incluirlo en el contexto
		//             ctx.getLocalIncludes().addElement(script);
		//         }
		// 	}// Cargar el fichero
		// }// Cargar todos los includes
		let strFunctionName = "";
		let aScripts = [];
		let aFunctionsName = [];
		let argsName = [];
		let argsValue = [];
		if (ActionNode instanceof XmlNode) {
			// A12081701: Mecanismo para pasar parámetros a un nodo de acción.
			// Sacar los nodos de parámetros si es que los necesitamos
			if (Arguments && Array.isArray(Arguments) && Arguments.length > 0) {
				// Sacar nodos
				// K13080701: La ejecución de scripts con argumentos a nivel de colección falta en algunos casos.
				// Sacar los argumentos a nivel de contexto para poder usarlo también a nivel de colección

				ctx.ExtractArguments(ActionNode, ...Arguments);
			} // Sacar nodos
			else {
				Arguments = null;
			}
			scripts = ActionNode.SelectNodes("script");

			// Si hay scripts precompilados, tratemos de usarlos
			// De lo contrario usar el mecanismo de toda la vida
			for (let i = 0; i < scripts.count(); i++) {
				// Recorre los scripts y los ejecuta
				let script = scripts.get(i);
				let xs = script;
				str = XmlUtils.getNodeAttr(script, "name");
				if (!StringUtils.IsEmptyString(str)) {
					// Buscar el script en otra parte
					//////strScript = string.Format("mp:script[@name='{0}']", str);
					if (null == (xs = this.getNode("script", "name", str))) continue;
					strScript = null;
				} // Buscar el script en otra parte
				// Obtener el lenguaje
				strLang = XmlUtils.getNodeAttr(xs, "language");
				if (StringUtils.IsEmptyString(strLang)) strLang = this.getOwnerApp().getDefaultLanguage();
				// Ejecuta el script que sea
				strScript = xs.getText();
				let index: number = 0;
				if (ctx.GetParams() && !ctx.GetParams().isEmpty()) {
					ctx
						.GetParams()
						.entrySet()
						.forEach((e) => {
							// Amiyares 18/06/2021: modificaciones en el intérprete de scripts
							strScript = "\t" + `let ${e[0]}=XArguments[${index}];` + "\n" + strScript;
							argsName.push(e[0]);
							if (e[1].toString().startsWith("'") && e[1].toString().endsWith("'")) e[1] = e[1].toString().slice(1, e[1].toString().length - 1);
							argsValue.push(e[1]);
							index++;
						});
				}

				// A10051901:   Componentes de depuración y modificación de maquinaria XOne para depurar.
				// Lo cierto es que este código no se va a llamar, pero por lo que sea...
				strFunctionName = ScriptUtils.GenerateFunctionName(ActionNode);
				aScripts.push(strScript);
				aFunctionsName.push(strFunctionName);
				//var sct=require("__"+this.getOwnerCollection().getName()+"__");
				//return this.getOwnerApp().RunScript(strFunctionName, strLang, strScript, ctx, Arguments);
			}
		} else {
			strScript = ActionNode.replace("javascript:", "");
			aScripts.push(strScript);
			aFunctionsName.push(strFunctionName);
			//return this.getOwnerApp().RunScript(strFunctionName, strLang, strScript, ctx, Arguments);
		} // Recorre los scripts y los ejecuta
		// OK
		return {
			ctx: ctx,
			scripts: aScripts,
			funcNames: aFunctionsName,
			slang: strLang,
			Arguments: Arguments,
			argsName: argsName,
			argsValue: argsValue,
		};
	}

	/**
	 * Ejecuta un nodo runscript pasando el script y el contexto a la aplicación base.
	 * @param ActionNode		Nodo script a ejecutar.
	 * @return					Devuelve TRUE si se ejecutan correctamente todos los scripts.
	 * @throws Exception
	 */
	public async doRunScript(ActionNode: XmlNode | string, ...Arguments): Promise<any> {
		return await this.DoRunScriptAsync(ActionNode, ...Arguments);
	}

	// protected DoRunScript(ActionNode: XmlNode | string, ...Arguments): any {
	//     let data = this.prepareContext(ActionNode, ...Arguments);
	//     let n = data.scripts.length;
	//     for (let i = 0; i < n; i++) {
	//         this.getOwnerApp().RunScript(data.funcNames[i], data.slang, data.scripts[i], data.ctx, data.Arguments);
	//     }
	//     // OK
	//     return true;
	// }

	protected async DoRunScriptAsync(ActionNode: XmlNode | string, ...Arguments): Promise<any> {
		let data = this.prepareContext(this, ActionNode, ...Arguments);
		let n = data.scripts.length;
		let promises: Promise<any>[] = new Array<Promise<any>>();
		for (let i = 0; i < n; i++) {
			// Amiyares 18/06/2021: modificaciones en el intérprete de scripts
			await this.getOwnerApp().RunScriptAsync(
				data.funcNames[i],
				data.slang,
				data.scripts[i],
				data.ctx,
				data.Arguments,
				data.argsName,
				data.argsValue
			);
		}
		// OK
		return true;
	}

	public async getItem(...FunctionParams): Promise<XoneDataObject> {
		return await this.WrapGetItem(FunctionParams);
	}

	private async WrapGetItem(...FunctionParams): Promise<XoneDataObject> {
		let obj: XoneDataObject = null;
		// En función de la cantidad de parámetros
		//CheckNullParameters("Item", FunctionParams);
		switch (FunctionParams.length) {
			case 1:
				// Puede ser entero o cadenilla
				if (typeof FunctionParams[0] === "string") {
					// Cadena
					obj = await this.get(FunctionParams[0].toString());
					break;
				} // Cadena
				// TODO ADD TAG 17042014: Luis si es un objeto de datos
				// if (FunctionParams[0] instanceof IRuntimeObject)
				// {// Cadena
				//     obj = GetObject((IRuntimeObject)FunctionParams[0]);
				//     break;
				// }// Cadena
				// De lo contrario será un número
				let n = NumberUtils.SafeToInt(FunctionParams[0]);
				obj = await this.get(n);
				break;
			case 2:
				let strFieldName = FunctionParams[0].toString();
				obj = await this.get(strFieldName, FunctionParams[1]);
				break;
			default: {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new XoneGenericException(-91111, "XoneDataCollection::GetItem ha fallado. Número incorrecto de parámetros.");
				let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_VBS_BADPARAMCOUNT, "VBS Runtime Error. Incorrect number of parameters for '{0}'");
				sb = sb.replace("{0}", "Item");
				throw new XoneGenericException(-91111, sb);
			} // Error
		}
		// De lo contrario lo encarsulamos
		return obj;
	}

	public async deleteItem(key: number | string | XoneDataObject) {
		if (key instanceof XoneDataObject) key = key.getObjectIndex();
		let obj = await this.get(key);
		if (obj) {
			// Lo ha encontrado
			obj.setFollowRules(this.m_bFollowRules);
			// O12050301: Si la colección es especial no hay que borrar el objeto de base de datos.
			// Si la colección es especial no tenemos nada que borrar
			if (!this.m_options.bIsSpecial) {
				// Solo si no es especial
				if (!obj.deleteObject())
					// Elimina el objeto de la base de datos
					return false;
			} // Solo si no es especial
			// Ahora borra el objeto de la lista
			this.removeItem(key);
			// Ejecuta las acciones posteriores a la eliminación
			// del objeto
			// F10090804:	DeleteItem (const char*) invoca las acciones de borrado de más.
			// Esto ya lo hace DeleteObject... además, RemoveItem ya lo ha borrado así que esto está mal
			////if (!obj.ExecuteDeleteActions(true))    // Error
			////    throw new XoneGenericException(-8001, "CXoneDataCollection::DeleteItem ha fallado. Ha fallado la ejecución de acciones después de eliminar el objeto.");
		} // Lo ha encontrado
	}

	/**
	 * Elimina un objeto de la colección dado su índice numérico
	 * @param Index				Indice del objeto en la colección (comenzando por cero)
	 * @return					Devuelve TRUE si la operación es correcta.
	 * @throws Exception
	 */
	public removeItem(Index: number | string | XoneDataObject): boolean {
		this.BrowseData.removeItem(Index);
		this.m_bFull = false;
		return true;
	}

	public async loadFromJson(...FunctionParams): Promise<XoneDataCollection> {
		const FunctionName = "loadFromJson";
		Utils.CheckNullParameters(FunctionName, FunctionParams);
		Utils.CheckIncorrectParamRange(FunctionName, FunctionParams, 1, 2);
		let jsonArray = FunctionParams[0];
		let jsParams = FunctionParams.length > 1 ? FunctionParams[1] : null;
		// TAG 2020031801: Luis Añadida la posibilidad de modo estricto o no.
		let bStrictMode = jsParams && StringUtils.ParseBoolValue(jsParams["strictMode"], false);
		if (typeof jsonArray === "string") {
			jsonArray = JSON.parse(jsonArray);
		}
		// if (value instanceof NativeArray) {
		//     return loadFromJsonInternal((NativeArray) value, bStrictMode);
		// } else if (value instanceof JSONArray) {
		//     jsonArray = (JSONArray) value;
		// } else {
		//     String sJsonObject = StringUtils.SafeToString(value);
		//     jsonArray = new JSONArray(sJsonObject);
		// }
		this.ClearCollection();
		await this.loadFromJsonArray(this, jsonArray, bStrictMode);
		return this;
	}

	//     public async loadFromJson(value: any): Promise<XoneDataCollection> {
	//         const sFunctionName = "loadFromJson";
	//         if (value == null) {
	//             throw new IllegalArgumentException(sFunctionName + "(): Empty value");
	//         }
	//         return await this.loadFromJsonInternal(value, false);
	//     }

	// public XoneDataCollection loadFromJson(NativeArray value, NativeObject jsParams) throws Exception {
	//     String sFunctionName = "loadFromJson";
	//     if (value == null) {
	//         throw new IllegalArgumentException(sFunctionName + "(): Empty value");
	//     }
	//     // TAG 2020031801: Luis Añadida la posibilidad de modo estricto o no.
	//     boolean bStrictMode = RhinoUtils.SafeGetBoolean(jsParams, "strictMode", false);
	//     return loadFromJsonInternal(value, bStrictMode);
	// }

	// public XoneDataCollection loadFromJsonInternal(NativeArray jsArray, boolean bStrictMode) throws Exception {
	//     JSONArray jsonArray = RhinoUtils.toJsonArray(jsArray);
	//     ClearCollection();
	//     loadFromJsonArray(this, jsonArray, bStrictMode);
	//     return this;
	// }

	private async loadFromJsonArray(collection: XoneDataCollection, jsonArray: any, bStrictMode: boolean): Promise<void> {
		for (let i = 0; i < jsonArray.length; i++) {
			let itemDataObject = new XoneDataObject(collection);
			//let item = jsonArray[i];
			itemDataObject.loadFromJson(jsonArray[i]);
			// for (let sPropertyName in item) {
			//     let propertyValue = item[sPropertyName];
			//     if (bStrictMode) {
			//         let sPropertyType = collection.FieldPropertyValue(sPropertyName, Utils.PROP_ATTR_TYPE);
			//         if (TextUtils.isEmpty(sPropertyType)) {
			//             continue;
			//         }
			//     }
			//     if (Array.isArray(propertyValue)) {
			//         let contentCollection = await itemDataObject.getContents(sPropertyName);
			//         if (!contentCollection) {
			//             throw new Exception("loadFromJson(): Content collection " + sPropertyName + " not found");
			//         }
			//         this.loadFromJsonArray(contentCollection, propertyValue, bStrictMode);
			//         continue;
			//     }
			//     /*
			//      * 15/07/2020
			//      * No insertar este null extraño de este parser de JSON. Dejar el null de java para
			//      * que las maquinarias de script y cualquier otra cosa tenga mejor oportunidad de
			//      * defenderse
			//      */
			//     if (propertyValue == null) {
			//         propertyValue = null;
			//     }
			//     itemDataObject[sPropertyName] = propertyValue;
			// }
			// Iterator < String > iterator = item.keys();
			// while (iterator.hasNext()) {
			//     String sPropertyName = iterator.next();
			//     Object propertyValue = item.get(sPropertyName);
			//     if (bStrictMode) {
			//         String sPropertyType = collection.FieldPropertyValue(sPropertyName, Utils.PROP_ATTR_TYPE);
			//         if (TextUtils.isEmpty(sPropertyType)) {
			//             continue;
			//         }
			//     }
			//     if (propertyValue instanceof JSONArray) {
			//         XoneDataCollection contentCollection = itemDataObject.Contents(sPropertyName);
			//         if (contentCollection == null) {
			//             throw new Exception("loadFromJson(): Content collection " + sPropertyName + " not found");
			//         }
			//         loadFromJsonArray(contentCollection, (JSONArray) propertyValue, bStrictMode);
			//         continue;
			//     }
			//     /*
			//      * 15/07/2020
			//      * No insertar este null extraño de este parser de JSON. Dejar el null de java para
			//      * que las maquinarias de script y cualquier otra cosa tenga mejor oportunidad de
			//      * defenderse
			//      */
			//     if (propertyValue == JSONObject.NULL) {
			//         propertyValue = null;
			//     }
			//     itemDataObject.put(sPropertyName, propertyValue);
			// }
			collection.addItem(itemDataObject);
		}
	}

	// clear() {

	// }

	// count() {

	// }

	// createObject() {

	// }

	// currentItem() {

	// }

	// deleteAll() {

	// }

	// endBrowse() {

	// }

	// findObject(param0) {

	// }

	// full() {

	// }

	// getFilter(param0) {

	// }

	// getLinkFilter(param0) {

	// }

	// group() {

	// }

	// isBrowsing() {

	// }

	// isLocked() {

	// }

	// Item() {

	// }

	// lock() {

	// }

	// macro(param0) {

	// }

	// macroCount() {

	// }

	// moveFirst() {

	// }

	// moveLast() {

	// }

	// moveNext() {

	// }

	// movePrevious() {

	// }

	// name() {

	// }

	// ownerApp() {

	// }

	// ownerObject() {

	// }

	// propertyCount() {

	// }

	// propertyName() {

	// }

	// propType() {

	// }

	// removeItem() {

	// }

	// saveAll() {

	// }

	// setFilter(param0) {

	// }

	// setLinkFilter(param0) {

	// }

	// sort(param0) {

	// }

	// startBrowse(booleano) {

	// }

	// stringKey(param0) {

	// }

	// unlock() {

	// }

	// loadFromJson(param0) {

	// }

	// getName() {

	// }

	// getOwnerApp() {

	// }

	// isFull() {

	// }

	// getOwnerObject() {

	// }

	// getCurrentItem() {

	// }

	// getMultipleKey() {

	// }

	// getCount() {

	// }

	// getSort() {

	// }

	// setSort(param0) {

	// }

	// doSort() {

	// }

	// createSearchIndex() {

	// }

	// createPersistData() {

	// }

	// doSearch() {

	// }

	// getVariables(variable) {

	// }

	// setVariables(param0) {

	// }

	// getMacro(macro) {

	// }

	// setMacro(macro, value) {

	// }

	// getGroupCount() {

	// }

	// getMacroCount() {

	// }

	// getDevelopedFilter() {

	// }

	// getDevelopedLinkFilter() {

	// }

	// getDevelopedAccessString() {

	// }

	// getObjectIndex() {

	// }

	// executeSqlString(param0) {

	// }

	// getObject() {

	// }

	// getPropertyTitle(prop) {

	// }

	// getPropertyGroup(grupo) {

	// }

	// getPropType() {

	// }

	// getPropVisibility() {

	// }

	// reload() {

	// }

	// getXmlNode(param0) {

	// }

	// getGroup(param0) {

	// }

	// getDataConnector(param0) {

	// }
}
