<template>
	<div :id="`${attributes.name.replace('@', '')}${rowInfo.id}${breadcrumbId}`" :class="['xone-contents-row', isSlideView && 'xone-slidesnap']">
		<template v-if="isRowLoaded && xoneDataObject && control">
			<!-- contents row -->
			<template v-if="rowInfo.visible || attributes.editInRow">
				<Container
					v-show="rowInfo.visible"
					:xoneDataObject="xoneDataObject"
					:control="control"
					:containerHeight="controlHeight"
					:containerWidth="controlWidth"
					:visibilityBit="4"
					@click="onContentsRowClick"
					:style="{
						backgroundColor,
						cursor: !attributes.editInRow && !attributes.locked && 'pointer',
						animation: 'fadeIn 0.3s',
					}"
					@onDataObjectChange="onDataObjectChange"
				></Container>
			</template>
			<!-- expanview -->
			<template v-if="isExpanView && expanControl">
				<Container
					v-show="rowInfo.isExpanded && rowInfo.visible"
					class="xone-expanded-row"
					:xoneDataObject="xoneDataObject"
					:control="expanControl"
					:containerHeight="controlHeight"
					:containerWidth="controlWidth"
					:visibilityBit="4"
					:style="{
						backgroundColor,
						cursor: !attributes.editInRow && !attributes.locked && 'pointer',
						poniterEvents: !attributes.editInRow && !attributes.locked && 'all',
					}"
					@onDataObjectChange="onDataObjectChange"
				></Container>
			</template>
		</template>
		<div
			v-if="!isRowLoaded || !control || !xoneDataObject || !rowInfo.visible"
			:style="{
				width: rowInfo.width && rowInfo.width,
				height: rowInfo.height && rowInfo.height,
			}"
		></div>
	</div>
</template>

<script>
import { computed, inject, onMounted, provide, ref, Ref, PropType, ComputedRef, watch } from "vue";

import AppDataHandler from "../../../composables/AppDataHandler";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { xoneAttributesHandler, PropAttributes } from "../../../composables/XoneAttributesHandler";
import XmlNode from "../../../composables/appData/Xml/JSONImpl/XmlNode";
import { RowInfo } from "../../../composables/ContentsLoaderHandler";

export default {
	name: "ContentsRow",

	props: {
		/** @type {PropType<RowInfo>} */ rowInfo: { type: Object, required: true },
		controlHeight: { type: Number, default: 0 },
		/** @type {PropType<PropAttributes>} */ attributes: { type: Object, required: true },
		isDisableEdit: { type: Boolean, required: true },
		controlWidth: { type: Number, default: 0 },
		isSlideView: { type: Boolean, default: false },
		isExpanView: { type: Boolean, default: false },
		rowsLength: { type: Number, default: 0 },
	},

	setup(props) {
		const isRowLoaded = ref(false);

		const isReactivePending = ref(true);

		/**
		 * Contents
		 * @type {Ref<XoneDataCollection>}
		 */
		const contents = inject("contents");

		/**
		 * XoneDataObject
		 * @type {Ref<XoneDataObject>}
		 */
		const xoneDataObject = ref(null);

		/**
		 * Layout
		 * @type {Ref<Object>}
		 */
		const layout = ref(null);
		// const layout = ref(inject("dataCollectionLayout"));

		/**
		 * Control attributes and childs layout
		 * @type {ComputedRef<Array>}
		 */
		const control = computed(() =>
			layout.value?.controls?.find((e) => {
				const containerAttributes = xoneAttributesHandler.getContainerAttributes(e.attributes);
				return containerAttributes.node === "group" && !containerAttributes.fixed && !containerAttributes.drawerOrientation;
			})
		);

		/**
		 * selectedItem
		 * @type {Ref<XoneDataObject>}
		 */
		const selectedItem = inject("selectedItem");

		let isOnContentsRowClickExecuting = false;
		/**
		 * On Click contents row
		 */
		const onContentsRowClick = async () => {
			// is locked or is Edit in Row
			if (props.attributes.locked || props.isDisableEdit) return;
			// if (props.attributes.editInRow || props.attributes.locked || props.isDisableEdit) return;

			if (isOnContentsRowClickExecuting) return;
			isOnContentsRowClickExecuting = true;
			//
			// execute selecteditem node
			try {
				if (await xoneDataObject.value.ExecuteNode("selecteditem")) {
					isOnContentsRowClickExecuting = false;
					selectedItem.value = xoneDataObject.value;
					if (!props.isExpanView) return;
				}
			} catch {}
			//
			// expanview
			if (props.isExpanView) {
				isOnContentsRowClickExecuting = false;
				return onExpanItemSelected(props.rowInfo);
			}
			//
			// open edit view
			AppDataHandler.pushXoneDataObject(xoneDataObject.value);
			isOnContentsRowClickExecuting = false;
		};

		/**
		 * Load contents row async
		 */
		const doLoadAsync = async (isFirstLoad = true) => {
			isRowLoaded.value = false;
			isReactivePending.value = true;
			// Agrego esta protección para cubrirme de scripts mal optimizados que hacen constantes loadall y refresh de los controles, hacía que en algún momento el datacollection no devolviese objeto
			for (let i = 0; i < 10; i++) {
				xoneDataObject.value = await contents.value.get(props.rowInfo.recordId || props.rowInfo.id);
				if (!xoneDataObject.value) {
					await new Promise((resolve) => setTimeout(() => resolve(), 25));
					continue;
				}
				break;
			}
			if (!xoneDataObject.value) return;
			props.rowInfo.setRecordId(xoneDataObject.value.ID);

			// IsFirstLoad -> increment loadedRowsLength
			if (isFirstLoad) loadedRowsLength.value++;

			// Si se hace un push del objeto tengo que realizar un clone para que dicho objeto no pierda reactividad, es el problema de injectar la dependencia cuando el objeto no se crea en el bloque del setup
			if (!props.attributes.editInRow) xoneDataObject.value.setCloneOnPush(true);

			isRowLoaded.value = true;

			await getLayoutAsync();

			// // Si es edit-inrow tengo que hacer el objeto reactivo en el Container, espero a que lo sea para ejecutar los nodos en onDataObjectChange
			// if (props.attributes.editInRow) return;

			// // Execute nodes
			// xoneDataObject.value.ExecuteNode("load").catch(console.error);
		};

		const getLayoutAsync = async () => {
			if (!xoneDataObject.value) return;
			// expanview Layout
			if (props.isExpanView) {
				expanLayout.value = await xoneDataObject.value.getLayoutAsync(1);
			}
			// Layout
			if (props.rowInfo.isFirstLoad) layout.value = xoneDataObject.value.getLayout(4);
			else layout.value = await xoneDataObject.value.getLayoutAsync(4);
			xoneDataObject.value.setLayout(layout.value);
		};

		/**
		 * Viewport size conditions sm / md / lg
		 * @type {Ref<string>}
		 */
		const sizeConditions = inject("sizeConditions");

		/** @type {boolean} */
		let relayout = false;

		watch(
			() => sizeConditions.value,
			() => {
				if (props.rowInfo.visible) {
					getLayoutAsync();
					relayout = false;
				} else relayout = true;
			}
		);

		/**
		 * breadcrumbId
		 * @type {string}
		 */
		const breadcrumbId = inject("breadcrumbId");

		//
		// Load / Refresh Item
		const loadedRowsLength = inject("loadedRowsLength");
		onMounted(() => doLoadAsync().then(() => props.rowInfo.setRefresh(() => doLoadAsync(false))));

		// Provide contents info
		provide("objectInfo", {
			isContents: true,
			isExpandedView: true,
			editInRow: props.attributes.editInRow,
			autosave: props.attributes.autosave,
			recordIndex: props.rowInfo.id,
			rowInfo: props.rowInfo,
			isReactivePending,
		});

		let colorView;
		let cellEvenColor;
		let cellOddColor;
		let cellSelectedBgColor;

		/**
		 * m_xmlNode
		 * @type {{m_xmlNode:XmlNode}}
		 */
		const { m_xmlNode } = contents.value;

		cellEvenColor = m_xmlNode.getAttrValue("cell-even-color");
		cellOddColor = m_xmlNode.getAttrValue("cell-odd-color");
		cellSelectedBgColor = m_xmlNode.getAttrValue("cell-selected-bgcolor");

		m_xmlNode.SelectNodes("prop").forEach((/** @type {XmlNode} */ e) => {
			if (e.getAttrValue("colorview") === "true") colorView = e.getAttrValue("name");
			if (e.getAttrValue("cell-selected-bgcolor") === "true") colorView = e.getAttrValue("name");
		});

		// Row background color
		const backgroundColor = computed(() => {
			if (colorView && xoneDataObject.value) return xoneDataObject.value[colorView];
			else if (selectedItem.value && xoneDataObject.value === selectedItem.value && cellSelectedBgColor) return cellSelectedBgColor;
			else return Number(props.rowInfo.id) % 2 === 0 ? cellEvenColor : cellOddColor;
		});

		//
		// Expanview
		const onExpanItemSelected = inject("onExpanItemSelected");

		/**
		 * Layout
		 * @type {Ref<Object>}
		 */
		const expanLayout = ref(null);

		/**
		 * Control attributes and childs layout
		 * @type {ComputedRef<Array>}
		 */
		const expanControl = computed(() => {
			if (!props.isExpanView) return null;
			return expanLayout.value?.controls?.find((e) => {
				const containerAttributes = xoneAttributesHandler.getContainerAttributes(e.attributes);
				return containerAttributes.node === "group" && !containerAttributes.fixed && !containerAttributes.drawerOrientation;
			});
		});

		// ExecuteNode onexpand / oncollapse
		watch(
			() => props.rowInfo.isExpanded,
			(newValue) => {
				if (!xoneDataObject.value) return;
				if (newValue) {
					// xoneDataObject.value.setLayout(expanLayout.value); creo que esto no hace falta
					xoneDataObject.value.ExecuteNode("onexpand").catch(console.error);
				} else {
					// xoneDataObject.value.setLayout(layout.value); creo que esto no hace falta
					xoneDataObject.value?.ExecuteNode("oncollapse").catch(console.error);
				}
			}
		);

		return {
			isRowLoaded,
			control,
			xoneDataObject,
			onContentsRowClick,
			breadcrumbId,
			backgroundColor,
			expanControl,
			onDataObjectChange: (obj) => {
				if (!isReactivePending.value) return;

				isReactivePending.value = false;

				// Assign dataObject
				xoneDataObject.value = obj;
				// Set new XoneDataObject to contents XoneDataCollection -> XoneBrowseData
				contents.value.m_browserData.m_lstOrderedList[props.rowInfo.id] = obj;
				// Execute node load
				obj.ExecuteNode("load").catch(console.error);
			},
		};
	},
};
</script>

<style>
.xone-contents-row {
	align-items: center;
	flex-shrink: 0;
	flex-grow: 0;
	position: relative;
	animation: fadeIn 0.5s;
}

.xone-slidesnap {
	scroll-snap-align: center;
}
</style>
