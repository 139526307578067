export default class XoneConnDataFlags 
{
    public static  PREFIX_MODIFIED = 0x00000001;
    public static  CONNSTRING_MODIFIED = 0x00000002;
    public static  ROWIDFIELD_MODIFIED = 0x00000004;
    public static  SQLFIELD_MODIFIED = 0x00000008;
    public static  ROWIDLEN_MODIFIED = 0x00000010;
    public static  OPERIDLEN_MODIFIED = 0x00000020;
    public static  DATEMASK_MODIFIED = 0x00000040;
    public static  TABLEQUOTE_MODIFIED = 0x00000080;
    public static  FIELDQUOTE_MODIFIED = 0x00000100;
    public static  SUBQUERIES_MODIFIED = 0x00000200;
}