import TextUtils from "../../Utils/TextUtils";

export default class QueryField {
    /**
     * Nombre del campo en la tabla de la base de datos
     */
    private m_strField: string;
    /**
     * Alias del campo según se pone en la sentencia SELECT
     */
    private m_strAlias: string;

    /**
     * Construye una estructura de representación de un campo y su alias en la sentencia SQL
     *
     * @param FieldName Nombre del campo en base de datos.
     * @param AliasName Alias del campo en la sentencia.
     */
    constructor( FieldName: string,  AliasName: string) {
        this.m_strField = FieldName;
        this.m_strAlias = AliasName;
    }

    /**
     * Nombre del campo en la tabla en base de datos
     *
     * @return Devuelve el nombre real del campo.
     */
    public getName(): string {
        return this.m_strField;
    }


    public getResolvedName(): string {
        return TextUtils.isEmpty(this.m_strAlias)?this.m_strField:this.m_strAlias;
    }

    /**
     * Asigna valor nuevo al nombre del campo
     *
     * @param value Nuevo valor para el nombre del campo.
     */
    public setName(value: string): void {
        this.m_strField = value;
    }


    /**
     * Alias del campo en la sentencia SELECT
     *
     * @return Devuelve el alias del campo en la sentencia SELECT.
     */
    public getAlias(): string {
        return this.m_strAlias;
    }

    /**
     * Asigna valor al alias del campo en la sentencia SELECT.
     *
     * @param value Nuevo valor del alias del campo en la sentencia.
     */
    public setAlias(value: string):void {
        this.m_strAlias = value;
    }
}
