import XmlNode from "../Xml/JSONImpl/XmlNode";
import StringBuilder from "./StringBuilder";
import StringUtils from "./StringUtils";
import { Utils } from "./Utils";

export default class ScriptUtils {
    static GenerateFunctionName(xmlNode: XmlNode) {
        let strName = new StringBuilder();
        let strNodeName="";
        /*
         * Armar el nombre de la función de la forma más inteligente posible.
         * Comprobar primero si este está solo en la colección del padre
         */
        let parentList = xmlNode.getParentNode().SelectNodes(xmlNode.getName());
        if (parentList.count() == 1) {
            // Ver si tiene nombre
            // F11051002: El nombre de función para nodos debería tener en cuenta el nombre.
            strNodeName = xmlNode.getAttrValue(Utils.PROP_ATTR_NAME);
            strName.append(xmlNode.getName());
            if (!StringUtils.IsEmptyString(strNodeName)) {
                strName.append("[@");
                strName.append(Utils.PROP_ATTR_NAME);
                strName.append("='");
                strName.append(strNodeName);
                strName.append("']");
            }
        } else {
            // Varios
            // F11051002: El nombre de función para nodos debería tener en cuenta el nombre.
            // Estoooo, si el nodo tiene name, es el que nos vale...
            strName.append(xmlNode.getName());
            // F13080702: La generación de nombres de función de nodo puede repetirse.
            // Quitamos el if que había aquí...
            for (let i = 0; i < parentList.count(); i++) {
                let node = parentList.get(i);
                if (node===xmlNode) {
                    // Este es
                    if (strName.length() > 0) {
                        strName.append("[");
                    }
                    strName.append(StringUtils.SafeToString(i + 1));
                    strName.append("]");
                    break;
                }
            }
        }
        if (xmlNode.hasChildNodes() && xmlNode.getChildren() != null && xmlNode.getChildren().length > 0 && xmlNode.SelectSingleNode("script") != null) {
            strName.append("/script");
        }
        if (strName.length() == 0) {
            strName.append(xmlNode.getName());
        }
        let parent = xmlNode.getParentNode();
        while (true) {
            // Recorrer el árbol hacia atrás
            let strTmp = parent.getName();
            strNodeName = parent.getAttrValue("name");
            if (!StringUtils.IsEmptyString(strNodeName)) {
                // Concatenar
                strTmp += "[@";
                strName.append(Utils.PROP_ATTR_NAME);
                strName.append("='");
                strTmp += strNodeName;
                strName.append("']");
            }
            //strTmp = strTmp.replace("-", "_");
            strName.insert(0, "/");
            strName.insert(0, strTmp); // + "." + strName;
            parent = parent.getParentNode();
            if (parent.getName().equals("coll")) {
                // El nombre de la colección también
                strName.insert(0, "|//");
                strName.insert(0, parent.getAttrValue("name"));
                break;
            } else {
                strName.insert(0, "/");
            }
        }
        // Indicar que es un nombre de módulo y no de fichero
        strName.insert(0, "@");
        return strName.toString();
    }

    static GenerateFunctionNameDebug(xmlNode: XmlNode) {
        let strName = new StringBuilder();
        let strNodeName="";
        /*
         * Armar el nombre de la función de la forma más inteligente posible.
         * Comprobar primero si este está solo en la colección del padre
         */
        let parentList = xmlNode.getParentNode().SelectNodes(xmlNode.getName());
        if (parentList.count() == 1) {
            // Ver si tiene nombre
            // F11051002: El nombre de función para nodos debería tener en cuenta el nombre.
            strNodeName = xmlNode.getAttrValue(Utils.PROP_ATTR_NAME);
            strName.append(xmlNode.getName());
            if (!StringUtils.IsEmptyString(strNodeName)) {
                strName.append("[@");
                strName.append(Utils.PROP_ATTR_NAME);
                strName.append("='");
                strName.append(strNodeName);
                strName.append("']");
            }
        } else {
            // Varios
            // F11051002: El nombre de función para nodos debería tener en cuenta el nombre.
            // Estoooo, si el nodo tiene name, es el que nos vale...
            strName.append(xmlNode.getName());
            // F13080702: La generación de nombres de función de nodo puede repetirse.
            // Quitamos el if que había aquí...
            for (let i = 0; i < parentList.count(); i++) {
                let node = parentList.get(i);
                if (node===xmlNode) {
                    // Este es
                    if (strName.length() > 0) {
                        strName.append("[");
                    }
                    strName.append(StringUtils.SafeToString(i + 1));
                    strName.append("]");
                    break;
                }
            }
        }
        if (xmlNode.hasChildNodes() && xmlNode.getChildren() != null && xmlNode.getChildren().length > 0 && xmlNode.SelectSingleNode("script") != null) {
            strName.append("/script");
        }
        if (strName.length() == 0) {
            strName.append(xmlNode.getName());
        }
        let parent = xmlNode.getParentNode();
        while (true) {
            // Recorrer el árbol hacia atrás
            let strTmp = parent.getName();
            strNodeName = parent.getAttrValue("name");
            if (!StringUtils.IsEmptyString(strNodeName)) {
                // Concatenar
                strTmp += "[@";
                strName.append(Utils.PROP_ATTR_NAME);
                strName.append("='");
                strTmp += strNodeName;
                strName.append("']");
            }
            //strTmp = strTmp.replace("-", "_");
            strName.insert(0, "/");
            strName.insert(0, strTmp); // + "." + strName;
            parent = parent.getParentNode();
            if (parent.getName().equals("coll")) {
                // El nombre de la colección también
                strName.insert(0, "|//");
                strName.insert(0, parent.getAttrValue("name"));
                break;
            } else {
                strName.insert(0, "/");
            }
        }
        // Indicar que es un nombre de módulo y no de fichero
        strName.insert(0, "@");
        return strName.toString();
    }

}