import { createHash } from "crypto";
import TextUtils from "./TextUtils";
import { Utils } from "./Utils";
import Base64 from "./Base64";

export default class HashUtils {

    public static createHash(algorithm: string, encode: string, value: string): string {
        if (TextUtils.isEmpty(value)) {
            return Utils.EMPTY_STRING;
        }
        if (TextUtils.isEmpty(algorithm)) {
            return Base64.encodeBytes(value);
        }
        if (TextUtils.isEmpty(encode) || "BASE64".compareToIgnoreCase(encode)) {
            return createHash(algorithm).update(value).digest("base64");
        }
        if ("hex".equals(encode)) {
            return createHash(algorithm).update(value).digest("hex").toLowerCase();
        }
        if ("HEX".equals(encode)) {
            return createHash(algorithm).update(value).digest("hex").toUpperCase();
        }
        return createHash(algorithm).update(value).digest("base64");
    }

    public static encodeHash(buff: Buffer, encode: string) {
        if (buff == null || buff.length == 0) {
            return Utils.EMPTY_STRING;
        }
        if (TextUtils.isEmpty(encode) || "BASE64".compareToIgnoreCase(encode)) {
            return buff.toString('base64');
        }
        if ("hex".equals(encode)) {
            return buff.toString('hex').toLowerCase();
        }
        if ("HEX".equals(encode)) {
            return buff.toString('hex').toUpperCase();
        }
        return buff.toString('base64');
    }
}