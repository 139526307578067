<template>
	<teleport to="body">
		<div class="xone-linked-container" @click="!isMouseOnInput && onHide()">
			<div
				ref="divElement"
				:style="{
					'--linked-search-max-height': `calc(${containerHeight}px - 50%)`,
					'--linked-search-max-width': `calc(${maxWidthPosition})`,
					'--linked-search-min-width': linkedSearchPosition.width + 'px',
					'--linked-search-table-min-width': linkedSearchPosition.width - (elementHasScroll ? 7 : 0) + 'px',
					'--linked-search-min-height': '25px',
					'--linked-search-right-position': rightPosition,
					'--linked-search-top-position': rows.length === 0 ? linkedSearchPosition.top + 'px' : topPosition,
					overflow: rows.length === 0 ? 'hidden' : null,
				}"
			>
				<!-- <p>{{ attributes.title ? attributes.title : "" }}</p> -->
				<!--v-if="attributes.showInlineKeyboard"-->

				<input
					:style="{
						'--image-search-placeholder': `url(${appPath}/assets/search-placeholder.png)`,
					}"
					@input="({ target }) => (filterModel = target.value)"
					:value="filterModel"
					@focus="onFocus"
					@blur="onBlur"
					@click="onFilterSelected"
					@mouseover="isMouseOnInput = true"
					@mouseleave="isMouseOnInput = false"
				/>
				<table v-show="rows.length !== 0">
					<tbody>
						<template class="xone-linked-row" v-for="(rowItem, iRows) in filteredRows" :key="`rowItem${iRows}`">
							<tr>
								<td
									v-for="(column, iColumns) in rowItem"
									:key="`columnItem${iColumns}`"
									@click="onSelectedItem(column)"
									:style="{ textAlign: column.textAlign }"
								>
									{{ column.value }}
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
	</teleport>
</template>

<script>
import { inject, Ref, PropType, ref, ComputedRef, onMounted, nextTick, watch, onUnmounted, computed } from "vue";

import { xoneAttributesHandler, PropAttributes } from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../composables/appData/core/XoneDataCollection";
import AppDataHandler from "../../composables/AppDataHandler";
import { getAppPath } from "../../composables/helperFunctions/ImageHelper";

export default {
	name: "LinkedSearchInline",
	emits: ["onHide", "onSelectedItem"],
	props: {
		/** xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 */
		xoneDataObject: { type: Object, required: true },
		/** attributes
     @type {PropType<PropAttributes>} 
     */
		attributes: { type: Object, required: true },

		linkedSearchPosition: { type: Object, required: true },
	},
	setup(props, context) {
		const filterModel = ref("");

		const isMouseOnInput = ref(false);
		/**
		 * Window Size
		 * @type {{containerWidth: Ref<number>|ComputedRef<number>, containerHeight: Ref<number>|ComputedRef<number>}}
		 */
		const { containerWidth, containerHeight } = inject("containerSize");

		// Close modal
		const onHide = () => context.emit("onHide");

		/**
		 * @type {Ref<boolean>}
		 */
		const isInputFocused = inject("isInputFocused");

		// onFocus
		const onFocus = () => (isInputFocused.value = true);

		// onBlur
		const onBlur = () => (isInputFocused.value = false);

		// On filter selected
		const onFilterSelected = (e) => {
			isMouseOnInput.value = true;
			e.preventDefault();
			setTimeout(() => (isMouseOnInput.value = false), 500);
		};

		// Select an item
		const onSelectedItem = (item) => context.emit("onSelectedItem", item);
		//
		// Map attributes
		const mapColValues = props.xoneDataObject.getFieldPropertyValue(props.attributes.linkedTo, "mapcol-values");
		const mapCol = props.xoneDataObject.getFieldPropertyValue(props.attributes.linkedTo, "mapcol");
		let mapFld = props.xoneDataObject.getFieldPropertyValue(props.attributes.linkedTo, "mapfld");
		const mapFilter = props.xoneDataObject.getFieldPropertyValue(props.attributes.linkedTo, "filter");
		const mapLinkFilter = props.xoneDataObject.getFieldPropertyValue(props.attributes.linkedTo, "linkfilter");

		// calculate rows to display on grid
		const rows = ref([]);

		// filter rows
		const filteredRows = computed(() => {
			if (!filterModel.value) return rows.value;

			return rows.value.filter((e) => {
				let res = false;
				e.forEach((element) => {
					if (res) return;
					res = element.value
						.toString()
						.toLowerCase()
						.includes(filterModel.value.toLowerCase());
				});

				return res;
			});
		});

		onMounted(async () => {
			try {
				await nextTick();
				setPosition();
				//
				// mapcol-values

				if (mapColValues) {
					setTimeout(async () => {
						await nextTick();
						setPosition();
					});
					const data = mapColValues.split(",");
					return (rows.value = data.map((e) => [{ mapValue: data.indexOf(e), value: e }]));
				}

				//
				// mapcol && mapfld

				/**
				 * Create XoneDataObject
				 * @type {XoneDataCollection}
				 */
				const xoneDataCollection = await AppDataHandler.getAppData().getCollection(mapCol);

				// Layout
				const props = [];

				/**
				 * @type {function(object):PropAttributes[]}
				 */
				const getControlProps = (control) => {
					control.controls?.forEach((e) => {
						const propAttributes = xoneAttributesHandler.getPropAttributes(e.attributes);
						if (propAttributes.node === "group" || propAttributes.node === "frame") getControlProps(e);
						if (propAttributes.node !== "prop") return;
						if (propAttributes.type.startsWith("T") || propAttributes.type.startsWith("N")) props.push(propAttributes);
					});
				};

				getControlProps(xoneDataCollection.getLayout(8));

				// Apply linkfilter & filter
				if (mapLinkFilter) xoneDataCollection.setLinkFilter(mapLinkFilter);
				xoneDataCollection.setFilter(mapFilter);

				// Data
				await xoneDataCollection.startBrowse();

				rows.value = [];
				while (xoneDataCollection.getCurrentItem()) {
					const obj = xoneDataCollection.getCurrentItem();
					rows.value.push(
						props.map((/** @type {PropAttributes} */ e) => {
							return {
								mapValue: obj[mapFld],
								value: obj[e.name],
								textAlign: e.textAlign,
							};
						})
					);

					await xoneDataCollection.moveNext();
				}
				xoneDataCollection.clear();
				await nextTick();
				setPosition();
			} catch (ex) {
				console.error(ex);
			}
		});

		//
		// Manage dialog position
		/**
		 * @type {Ref<HTMLElement>}
		 */
		const divElement = ref();

		const rightPosition = ref((window.innerWidth - containerWidth.value) / 2 + containerWidth.value - props.linkedSearchPosition.left);
		const topPosition = ref(props.linkedSearchPosition.top + "px");
		const maxWidthPosition = ref(props.linkedSearchPosition.width + "px");
		const heightPosition = ref(props.linkedSearchPosition.height + "px");
		const elementHasScroll = ref(false);

		watch(
			() => filterModel.value,
			async () => {
				await nextTick();
				setPosition();
			}
		);

		const setPosition = async () => {
			if (!divElement.value) return;
			await nextTick();
			// Right
			let right = (window.innerWidth - containerWidth.value) / 2 + containerWidth.value - props.linkedSearchPosition.left;

			if (window.innerWidth - divElement.value.scrollWidth > right) rightPosition.value = `${right}px`;
			else if (window.client > divElement.value.scrollWidth) rightPosition.value = `${window.innerWidth - divElement.value.scrollWidth}px`;
			else {
				right = 15;
				rightPosition.value = "15px";
			}
			// Top
			if (divElement.value.offsetHeight <= containerHeight.value - props.linkedSearchPosition.top)
				topPosition.value = `${props.linkedSearchPosition.top - 15}px`;
			else topPosition.value = `calc(100vh - 5% - ${divElement.value.offsetHeight - 15}px)`;
			if (rows.value.length == 0) return;
			// Height
			heightPosition.value = divElement.value.offsetHeight + "px";
			// Width
			maxWidthPosition.value = `${window.innerWidth - right - 15}px`;
			elementHasScroll.value = divElement.value.scrollHeight > divElement.value.clientHeight;
		};

		onMounted(() => window.addEventListener("resize", setPosition));
		onUnmounted(() => window.removeEventListener("resize", setPosition));
		watch(
			() => containerWidth.value,
			() => onHide()
		);
		watch(
			() => containerHeight.value,
			() => onHide()
		);

		return {
			filterModel,
			isMouseOnInput,
			containerWidth,
			containerHeight,
			elementHasScroll,
			onHide,
			onFocus,
			onBlur,
			onFilterSelected,
			onSelectedItem,
			rows,
			filteredRows,
			divElement,
			rightPosition,
			topPosition,
			heightPosition,
			maxWidthPosition,
			appPath: getAppPath(),
		};
	},
};
</script>

<style scoped>
.xone-linked-container {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: flex-end;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.15);
	animation: fadeIn 0.5s;
	overflow: hidden;
	z-index: 9999;
}

.xone-linked-container > div {
	position: absolute;
	display: flex;
	flex-direction: column;
	border: 1px gray solid;
	background-color: white;
	border-radius: 3px;
	overflow-y: auto;
	overflow-x: auto;
	flex-shrink: 1;
	max-width: var(--linked-search-max-width);
	max-height: var(--linked-search-max-height);
	min-width: var(--linked-search-min-width);
	min-height: var(--linked-search-min-height);
	right: var(--linked-search-right-position);
	top: var(--linked-search-top-position);
	animation: fadeIn 0.3s;
	transition: all 0.3s;
}

/* p {
  margin: 10px;
  font-weight: bold;
  font-size: 1rem;
} */

table {
	/* flex-shrink: 1; */
	/* align-self: flex-end; */
	table-layout: fixed;
	border-collapse: collapse;
	max-width: var(--linked-search-max-width);
	max-height: var(--linked-search-max-height);
	min-width: var(--linked-search-table-min-width);
	min-height: var(--linked-search-min-height);
	animation: fadeIn 0.3s;
	/* width: fit-content; */
}

td {
	padding: 5px 10px 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	width: fit-content;
	white-space: nowrap;
}

tr {
	white-space: nowrap;
}

tr:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.14);
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 2;
	width: fit-content;
	text-align: left;
}

.xone-search-header:hover {
	background: white;
}

input {
	position: sticky;
	top: 0;
	max-width: var(--linked-search-max-width);
	min-width: var(--linked-search-table-min-width);
	max-height: var(--linked-search-max-height);
	padding: 5px;
	border: none;
	background-color: white;
	background-image: var(--image-search-placeholder);
	background-repeat: no-repeat;
	background-position: left;
	padding-left: 30px;
	background-size: 32px 32px;
}
</style>
