export const UI_ATTRIBUTES = {
	coll: {
		cellEvenColor: "cell-even-color",
		cellOddColor: "cell-odd-color",
		cellSelectedBgColor: "cell-selected-bgcolor",
		groupSwipe: "group-swipe",
		loadAll: "loadall",
		name: "name",
		noTab: "notab",
		tabBgColor: "tab-bgcolor",
		tabBorderCornerRadius: "tab-border-corner-radius",
		tabBPadding: "tab-bpadding",
		tabFontSize: "tab-fontsize",
		tabForecolor: "tab-forecolor",
		tabLPadding: "tab-lpadding",
		tabRPadding: "tab-rpadding",
		tabSelectedBgcolor: { value: ["tab-selected-bgcolor", "tab-bgcolor-selected"] },
		tabSelectedFontsize: { value: ["tab-selected-fontsize", "tab-fontsize-selected"] },
		tabSelectedForecolor: { value: ["tab-selected-forecolor", "tab-forecolor-selected"] },
		tabTPadding: "tab-tpadding",
	},
	group: {
		align: "align",
		bgColor: "bgcolor",
		bMargin: "bmargin",
		borderCornerRadius: "border-corner-radius",
		bPadding: "bpadding",
		disableVisible: "disablevisible",
		drawerOrientation: "drawer-orientation",
		elevation: "elevation",
		fixed: "fixed",
		foreColor: "forecolor",
		height: "height",
		id: "id",
		image: { value: ["imgbk", "img", "image", "path"] },
		keepAspectRatio: "keep-aspect-ratio",
		lMargin: "lmargin",
		lPadding: "lpadding",
		name: "name",
		onFocus: "onfocus",
		orientation: "orientation",
		rMargin: "rmargin",
		rPadding: "rpadding",
		tMargin: "tmargin",
		tPadding: "tpadding",
		width: "width",
	},
	frame: {
		align: "align",
		animationIn: "animation-in",
		animationOut: "animation-out",
		bgColor: "bgcolor",
		bMargin: "bmargin",
		borderColor: "border-color",
		borderCornerRadius: "border-corner-radius",
		borderWidth: "border-width",
		bPadding: "bpadding",
		disableVisible: "disablevisible",
		elevation: "elevation",
		floating: "floating",
		foreColor: "forecolor",
		framebox: "framebox",
		height: "height",
		href: "href",
		image: { value: ["imgbk", "img", "image", "path"] },
		keepAspectRatio: "keep-aspect-ratio",
		left: "left",
		lMargin: "lmargin",
		lPadding: "lpadding",
		mapCol: "mapcol",
		mapFld: "mapfld",
		minHeight: "min-height",
		minWidth: "min-width",
		name: "name",
		newLine: "newline",
		onClick: "onclick",
		onScroll: "onscroll",
		rMargin: "rmargin",
		rPadding: "rpadding",
		scroll: "scroll",
		scrollOrientation: "scroll-orientation",
		tMargin: "tmargin",
		top: "top",
		tPadding: "tpadding",
		viewMode: "viewmode",
		width: "width",
		wrap: "wrap",
	},
	prop: {
		align: "align",
		allowedExtensions: "allowed-extensions",
		autosave: "autosave",
		autoslideDelay: "autoslide-delay",
		bgColor: "bgcolor",
		bit: "bit",
		bMargin: "bmargin",
		borderColor: "border-color",
		borderCornerRadius: "border-corner-radius",
		borderWidth: "border-width",
		bPadding: "bpadding",
		breadcrumbFontSize: { value: ["breadcrumb-fontsize", "breadcrumbs-fontsize", "breadcumb-fontsize"] },
		breadcrumbForeColor: { value: ["breadcrumb-forecolor", "breadcrumbs-forecolor", "breadcumb-forecolor"] },
		breadcrumbSelectedFontSize: { value: ["breadcrumb-selected-fontsize", "breadcrumbs-selected-fontsize", "breadcumb-selected-fontsize"] },
		breadcrumbSelectedForeColor: {
			value: ["breadcrumb-selected-forecolor", "breadcrumbs-selected-forecolor", "breadcumb-selected-forecolor"],
		},
		buttonOption: "button-option",
		calendarViewMode: "calendar-viewmode",
		cellBgColor: "cell-bgcolor",
		cellBorderColor: "cell-border-color",
		cellEvenColor: "cell-even-color",
		cellForeColor: "cell-forecolor",
		cellOddColor: "cell-odd-color",
		cellOtherMonthBgColor: "cell-other-month-bgcolor",
		cellSelectedBgColor: "cell-selected-bgcolor",
		cellSelectedBorderColor: "cell-selected-border-color",
		cellSelectedForeColor: "cell-selected-forecolor",
		checkType: "check-type",
		clusterMarkers: "cluster-markers",
		contents: "contents",
		disableEdit: "disableedit",
		disableVisible: "disablevisible",
		editInRow: "edit-inrow",
		elevation: "elevation",
		fieldSize: "fieldsize",
		fixedText: "fixed-text",
		floating: "floating",
		floatingTooltip: "floating-tooltip",
		fluidLoad: "fluid-load",
		fontBold: "fontbold",
		fontSize: "fontsize",
		foreColor: "forecolor",
		galleryColumns: "gallery-columns",
		height: "height",
		href: "href",
		image: { value: ["img", "image", "path", "imgbk"] },
		imgChecked: "img-checked",
		imgCheckedDisabled: "img-checked-disabled",
		imgHeight: "img-height",
		imgUnchecked: "img-unchecked",
		imgUncheckedDisabled: "img-unchecked-disabled",
		imgWidth: "img-width",
		keepAspectRatio: "keep-aspect-ratio",
		labelBox: "labelbox",
		labelwidth: "labelwidth",
		left: "left",
		lines: "lines",
		linkedField: "linkedfield",
		linkedTo: "linkedto",
		lMargin: "lmargin",
		locked: "locked",
		lPadding: "lpadding",
		mask: "mask",
		method: "method",
		minHeight: "min-height",
		minWidth: "min-width",
		name: "name",
		newLine: "newline",
		onClick: "onclick",
		onEditorAction: "oneditoraction",
		onFocusChanged: "onfocuschanged",
		orientation: "orientation",
		onScroll: "onscroll",
		onTextChanged: "ontextchanged",
		paginationSize: "pagination-size",
		postOnchange: "postonchange",
		radioGroup: "radio-group",
		readOnly: "readonly",
		rMargin: "rmargin",
		rowsPerPage: "rows-per-page",
		rPadding: "rpadding",
		scaleType: "scale-type",
		showInline: "showinline",
		showInlineKeyboard: "showinline-keyboard",
		showInMarker: "show-in-marker",
		showPois: "show-pois",
		size: "size",
		textAlign: "text-align",
		textBgColor: "text-bgcolor",
		textBgColorDisabled: "text-bgcolor-disabled",
		textBorder: "text-border",
		textBorderBottom: "text-border-bottom",
		textBorderLeft: "text-border-left",
		textBorderRight: "text-border-right",
		textBorderTop: "text-border-top",
		textOverflow: "text-overflow",
		textFontSize: "text-fontsize",
		textForeColor: { value: ["text-forecolor", "textfore-color"] },
		textForeColorDisabled: "text-forecolor-disabled",
		title: { value: ["title", "caption"] },
		tMargin: "tmargin",
		tooltip: "tooltip",
		tooltipColor: { value: ["expanded-hint-color", "floating-tooltip-color"] },
		tooltipForecolor: "tooltip-forecolor",
		top: "top",
		tPadding: "tpadding",
		type: "type",
		viewMode: "viewmode",
		visible: "visible",
		weekdaysLongname: "weekdays-longname",
		width: "width",
		wrap: "wrap",
	},
};
export const COLOR_ATTRIBUTES = [
	"borderColor",
	"bgColor",
	"cellBgColor",
	"cellBorderColor",
	"cellForeColor",
	"cellOtherMonthBgColor",
	"cellSelectedBgColor",
	"cellSelectedBorderColor",
	"cellSelectedForeColor",
	"foreColor",
	"textBgColor",
	"textBgColorDisabled",
	"textForeColor",
	"textForeColorDisabled",
	"breadcrumbForeColor",
	"breadcrumbSelectedForeColor",
];
export const FAST_ATTR_RESOLVER = (() => {
	const obj = {};
	const fetchFastAttrResolver = ([normalized, original]) => {
		if (typeof normalized === "object" && normalized.value) normalized = normalized.value[0];
		if (typeof original === "object" && original.value) return original.value.forEach((e) => (obj[e] = normalized));
		obj[original] = normalized;
	};
	Object.entries(UI_ATTRIBUTES.coll).forEach(fetchFastAttrResolver);
	Object.entries(UI_ATTRIBUTES.frame).forEach(fetchFastAttrResolver);
	Object.entries(UI_ATTRIBUTES.prop).forEach(fetchFastAttrResolver);
	return obj;
})();
