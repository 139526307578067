import TextUtils from "./TextUtils";
import { Utils } from "./Utils";

export default class StringUtils {
    public static XONE_EMPTY_STRING = "##XONE_EMPTY_STRING##";
    public static XONE_METHOD_DEFAULT = "##xoneMethodDefault##";
    static ParseDateString(arg0: any, str: string): any {
        throw new Error("Method not implemented.");
    }
    static removeChars(text: string, ch: string): string {
        if (text.startsWith(ch))
            text=text.substr(1);
        if (text.length>1 && text.endsWith(ch))
            text=text.substr(0,text.length-1);
        return text;
    }
    
    static Replace(strSQL: string, arg1: string, str: string): string {
        return strSQL.replace(arg1,str);
    }
    
    static ParseBoolValue(value: string | number | boolean | null | undefined, bDefault:boolean=false): boolean {
        if (value==null)
            return bDefault;
        if (typeof value === 'string') {
            return value.length==0?bDefault:value.equalsIgnoreCase('true') || value.equals('1') || !!+value;  // here we parse to number first
        }
      
        return !!value;
    }

    static IsEmptyString(text: string) {
        return TextUtils.isEmpty(text);
    }

    static areEquals(text1: string, text2: string): boolean {
        return text1===text2;
    }

    /**
     * Dado un valor comprueba si es nulo para devolver cadena vacía. Si no, devuelve su conversión
     * a cadena.
     *
     * @param Source   Valor que se quiere convertir.
     * @param sDefault
     * @return La cadena convertida o la por defecto.
     */
    public static SafeToString(Source: any, sDefault: string=""): string {
        try {
            // Si es nulo, cadena vacía directamente
            if (Source == null) {
                return sDefault;
            }
            if (typeof Source === 'string')
                return Source;
            return Source.toString();
            // /*
            //  * 20/05/2016
            //  * Si ya es CharSequence (String, StringBuffer, StringBuilder), invocamos su toString().
            //  * En el caso de pasarle un String, que es bastante comun, este retorna su propia
            //  * instancia y es rapido y no hace falta hacerle un check fuera de esta funcion.
            //  */
            // if (Source instanceof CharSequence) {
            //     if (TextUtils.isEmpty((CharSequence) Source)) {
            //         return sDefault;
            //     }
            //     return Source.toString();
            // }
            // // De lo contrario, simplemente el ToString de lo que sea
            // if (Source instanceof Calendar) {
            //     SimpleDateFormat dateformat = new SimpleDateFormat("yyyy-MM-dd HH:mm:ss");
            //     return dateformat.format(((Calendar) Source).getTime().getTime());
            // }
            // if (Source instanceof Date) {
            //     SimpleDateFormat dateformat = new SimpleDateFormat("yyyy-MM-dd HH:mm:ss");
            //     return dateformat.format(((Date) Source).getTime());
            // }
            // /*
            //  * TODO 24/05/2018 Juan Carlos
            //  * Añado este check de doubles para que trate de eliminar la notación científica al
            //  * convertirlo a string para por ejemplo el cstr() de vbscript
            //  *
            //  * Posiblemente también sea necesario hacerlo con floats.
            //  */
            // if (Source instanceof Double) {
            //     return doubleFormatter.format((double) Source);
            // }
            // if (Source instanceof Object[]) {
            //     StringBuilder bld = new StringBuilder();
            //     try {
            //         Object[] arr = (Object[]) Source;
            //         for (Object value : arr) {
            //             if (bld.length() > 0) {
            //                 bld.append("\r\n");
            //             }
            //             bld.append(value);
            //         }
            //     } catch (Exception e) {
            //         e.printStackTrace();
            //     }
            //     return bld.toString();
            // }
            // String value = String.valueOf(Source);
            // if (TextUtils.isEmpty(value)) {
            //     return sDefault;
            // }
            // return value;
        } catch (e) {
            return sDefault;
        }
    }
}