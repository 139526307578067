import TextUtils from "../../Utils/TextUtils";

export enum eJoinType {
    eLeftJoin,
    eRightJoin,
    eInnerJoin
};

export class QueryTable {
    /**
     * TRUE si la tabla usa prefijo
     */
    private m_bUsePrefix:boolean;
    /**
     * Condición JOIN para unirse con la tabla que le sigue en una sentencia
     */
    private  m_strJoinCondition:string;
    /**
     * Alias de la tabla en la sentencia SQL si es que lo tiene
     */
    private m_strTableAlias:string;
    /**
     * Nombre de la tabla en la base de datos.
     */
    private m_strTableName:string;
    /**
     * Tabla que le sigue en un JOIN
     */
    private m_secondTable:QueryTable;
    /**
     * M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
     * TRUE si hay que traducir las uniones en el SQL regenerado.
     */
    private  m_bTranslateJoins:boolean;

    private m_bUseParrentisisAtJoins = false;
    /**
     * K11090501: El parser de SQL no almacena el tipo de JOIN. Debería.
     * Para saber qué join es
     */
    private m_joinType:eJoinType;

    /**
     * Construye una tabla de una sentencia SELECT
     *
     * @param Name  Nombre de la tabla en la base de datos.
     * @param Alias Alias de la tabla en la unión JOIN del SQL.
     */
    constructor(Name:string, Alias:string) {
        // Almacenar estos datos que son los básicos
        this.m_strTableName = Name;
        this.m_strTableAlias = Alias;
        // Por defecto usamos prefijo
        this.m_bUsePrefix = true;
        // K11090501: El parser de SQL no almacena el tipo de JOIN. Debería.
        this.m_joinType = eJoinType.eLeftJoin;
    }

    /**
     * Devuelve la siguiente tabla en una cadena de JOINs.
     *
     * @return
     */
    public  getSecondTable():QueryTable {
        return this.m_secondTable;
    }

    /**
     * Asigna valor a la siguiente tabla en la cadena de JOINs.
     *
     * @param value Tabla que sigue a esta en la cadena de JOINS de la sentencia SQL.
     */
    public setSecondTable( value:QueryTable):void {
        this.m_secondTable = value;
    }

    /**
     * M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
     * Expone la bandera que indica si hay que traducir las uniones o no.
     */
    public  getTranslateJoins():boolean {
        return this.m_bTranslateJoins;
    }

    /**
     * M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
     * Asigna valor a la bandera que indica si hay que traducir las uniones o no. Si hay tabla
     * secundaria le propaga el valor que se le haya indicado.
     */
    public setTranslateJoins(value:boolean): void {
        this.m_bTranslateJoins = value;
        if (this.m_secondTable != null) {
            this.m_secondTable.setTranslateJoins(value);
        }
    }

    /**
     * @return Devuelve el nombre real de la tabla.
     */
    public getActualName():string {
        if (!this.m_bUsePrefix) {
            return this.m_strTableName;
        }
        return "##PREF##" + this.m_strTableName;
    }

    /**
     * Devuelve o asigna el alias a la tabla
     *
     * @return Esta función devuelve el alias de la tabla en la sentencia SQL a que pertenece.
     */
    public getAlias():string {
        return this.m_strTableAlias;
    }

    /**
     * Asigna valor al alias de la tabla en la sentencia SQL a que pertenece.
     *
     * @param value Nuevo valor para el alias de la tabla en la sentencia.
     */
    public setAlias(value:string):void {
        this.m_strTableAlias = value;
    }

    /**
     * @return Devuelve la condición de unión entre esta tabla y la que le sigue.
     */
    public getJoinCondition():string {
        return this.m_strJoinCondition;
    }

    /**
     * K11090501: El parser de SQL no almacena el tipo de JOIN. Debería.
     * Exponer el tipo de join
     *
     * @return
     */
    public getJoinType():eJoinType {
        return this.m_joinType;
    }

    /**
     * Asigna valor a la condición de unión entre esta tabla y la que le sigue en la sentencia.
     * <p>
     * K11090501: El parser de SQL no almacena el tipo de JOIN. Debería.
     * Pasar el tipo de join también
     *
     * @param value Nuevo valor para la condición de unión.
     */
    public setJoinCondition(value:string, Type:eJoinType):void {
        this.m_strJoinCondition = value;
        // K11090501: El parser de SQL no almacena el tipo de JOIN. Debería.
        this.m_joinType = Type;
    }

    /**
     * @return Devuelve una cadena de unión con esta tabla y las que le siguen en la cadena de JOINs.
     */
    public  getTableName():string {
        let str:string, strTmp:string;
        // Si no tiene segunda tabla, solamente es esta
        if (this.m_secondTable == null) {// No hay unión
            str = String.format("%s%s", this.m_bUsePrefix ? "##PREF##" : "", this.m_strTableName);
            return str;
        }// No hay unión
        // M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
        // Ahora tenemos que ver si hay traducción de uniones, en cuyo caso habrá que devolver otra cosa
        if (this.m_bTranslateJoins) {
            return this.TranslatedJoinTableName();
        }


        // m_bUseParrentisisAtJoins
        let openParent = "(";
        let closeParent = ")";

        if (!this.m_bUseParrentisisAtJoins) {
            openParent = "";
            closeParent = "";
        }

        //
        // Si tiene segunda tabla, devolver la unión de ambas
        str = openParent + this.m_secondTable.getTableName();
        if (TextUtils.isEmpty(this.m_secondTable.getJoinCondition())) {// Incluir el alias
            strTmp = " " + this.m_secondTable.getAlias();
            str += strTmp;
        }// Incluir el alias
        //
        // Adicionar las uniones y esas cosillas
        // K11090501: El parser de SQL no almacena el tipo de JOIN. Debería.
        let strJoin = "LEFT OUTER JOIN";
        switch (this.m_joinType) {
            case eJoinType.eRightJoin:
                strJoin = "RIGHT JOIN";
                break;
            case eJoinType.eInnerJoin:
                strJoin = "INNER JOIN";
                break;
        }
        strTmp = String.format(" %s %s%s %s ON %s" + closeParent, strJoin, this.m_bUsePrefix ? "##PREF##" : "", this.m_strTableName, this.m_strTableAlias, this.m_strJoinCondition);
        str += strTmp;
        return str;
    }

    /**
     * M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
     * Devuelve una cadena con los nombres de tablas pero traduciendo las uniones a formato filtrado.
     */
    public TranslatedJoinTableName():string {
        let strOut = this.m_bUsePrefix ? "##PREF##" : "";
        strOut += this.m_strTableName;
        // Ahora tendremos que poner el alias si lo lleva
        if (!TextUtils.isEmpty(this.m_strTableAlias)) {
            strOut += (" " + this.m_strTableAlias);
        }
        // Y finalmente si hay siguiente tabla habrá que concatenarla
        if (this.m_secondTable != null) {
            strOut += ("," + this.m_secondTable.TranslatedJoinTableName());
        }
        return strOut;
    }

    /**
     * Asigna valor al nombre (real) de la tabla en la sentencia SQL a la que pertenece.
     *
     * @param value Nuevo valor para el nombre de la tabla.
     */
    public setTableName(value:string):void {
        this.m_strTableName = value;
    }

    /**
     * @return Devuelve la bandera que indica si la tabla usa prefijo o no.
     */
    public getUsePrefix():boolean {
        return this.m_bUsePrefix;
    }

    /**
     * Asigna valor a la bandera que indica que hay que usar prefijo al recomponer el SQL.
     *
     * @param value TRUE si se quiere incluir el prefijo al recomponer el SQL.
     */
    public setUsePrefix(value:boolean):void {
        this.m_bUsePrefix = value;
    }

    /**
     * M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
     * Devuelve las condiciones para insertar en el WHERE generado como consecuencia de la
     * traducción de las uniones. Si ya había WHERE se adicionarán al que había, si no lo hay
     * se generará uno nuevo.
     */
    public getTranslatedJoinConditions():string {
        let strOut = "";
        // Obtener la condición de la primera tabla
        if (!TextUtils.isEmpty(this.m_strJoinCondition)) {
            strOut += ("(" + this.m_strJoinCondition + ")");
        }
        if (this.m_secondTable != null) {// Pedir la condición de esa tabla
            let strTmp = this.m_secondTable.getTranslatedJoinConditions();
            if (!TextUtils.isEmpty(strTmp)) {
                strOut += (" AND " + strTmp);
            }
        }// Pedir la condición de esa tabla
        return strOut;
    }
}