import { XoneDataCollection } from "../../core/XoneDataCollection";

export default class Hashtable<K, V> implements IterableIterator<[K, V]> {
    
    
    private _map = new Map<K,V>();
    private _pointer = 0;

    public get length() {
        return  this._map.size;
    }

    public next(): IteratorResult<[K, V]> {
        if (this._pointer < this._map.size) {
            return {
                done: false,
                value: this._map[this._pointer++]
            }
        } else {
            return {
                done: true,
                value: null
            }
        }
    }

    public  toJSON():any {
        let obj = Object.create(null);
        this._map.forEach((value,key) => {
            if (typeof value =='string' && value.startsWith("'") && value.endsWith("'"))
                obj[key]=value.substr(1,value.length-2);
            else
                obj[key]=value;
        });
        return obj;
    }
    

    public values(): Array<V> {
       return Array.from(this._map.values());
    }

    public keys(): Array<K> {
        return Array.from(this._map.keys());
     }

     
    public entrySet(): Array<[K,V]> {
        return Array.from(this._map.entries());
     }

    public get entries() {
        return this._map;
    }

    public isEmpty(): boolean {
        return this._map.size===0;
    }
    

    public containsKey(key: K): boolean {
        return this._map.has(key);
    }

    public get(key: K) {
        return this._map.get(key);
    }

    public delete(key: K): boolean {
        return this._map.delete(key);
    }

    public put(key: K, value: V) {
        if (value===null && this._map.has(key))
            this._map.delete(key);
        else
            this._map.set(key, value);
    }

    public clear(): void {
        this._map.clear();
    }

    public toObject(): any {
        const obj = {};
        this._map.forEach((value, key) => (obj[key as unknown as string] = value));
        return obj;
    }

    [Symbol.iterator](): IterableIterator<[K, V]> {
        return this._map.entries();
    }
} 